import { Box, IconButton, useTheme } from "@mui/material";
// import { useContext, useEffect } from "react";
import React, { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../Redux/Slice/LoginSlice";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddAlertRoundedIcon from "@mui/icons-material/AddAlertRounded";
import { fetchProfile } from "../../../Redux/Slice/ProfileSlice";
import EditIcon from "@mui/icons-material/Edit";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
const HrTopbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Updated usage
  const [openModal, setOpenModal] = React.useState(false);
  const { profileData, profilestatus } = useSelector((state) => state.profile);
  useEffect(() => {
    dispatch(fetchProfile());
  }, []);
  console.log(profilestatus);
  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("token");
    // navigate("/"); // Updated usage
    window.location.href = "/";
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const checkValidity = (id) => {
    navigate(`/hr/document/${id}`);
  }
  return (

    <Box display="flex" justifyContent="space-between" p={1}>
      <ToastContainer position="bottom-right" autoClose={3000} />
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Notification</DialogTitle>
        <DialogContent>
          {profilestatus === "success" && (
            <Card>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableBody>
                    {profileData.document.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.type}</TableCell>
                        <IconButton
                          style={{ color: "#2d388e" }}
                          onClick={() => checkValidity(row.user_id)}
                        >
                          <EditIcon />

                        </IconButton>
                      </TableRow>
                    ))}
                  </TableBody> */}
                </Table>
              </TableContainer>
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Box display="flex">
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton> */}
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        <IconButton>
          {
            profilestatus === "success" && (
              profileData.count
            )
          }
          <AddAlertRoundedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleLogout}>
          <ExitToAppOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default HrTopbar;
