import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
export const successmsg = (msg) => {
    if (msg) {
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, // Time in milliseconds. Set to 0 to disable auto-close
            hideProgressBar: false, // Set to true to hide the progress bar
            closeOnClick: true, // Close the toast on click
            pauseOnHover: true, // Pause auto-close on hover
            draggable: true, // Allow to drag the toast
        });
    }
    
}