import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./Slice/LoginSlice";
import authMiddleware from "./authMiddleware";
import UserReducer from "./Slice/User/UserListSlice";
import UserDeleteReducer from "./Slice/User/UserDeleteSlice";
import CompanyListSlice from "./Slice/Company/CompanyListSlice";
import DepartmentListSlice from "./Slice/Department/DepartmentListSlice";
import AddEmployeeSlice from "./Slice/Employee/AddEmployeeSlice";
import addLocation from "./Slice/Loaction/AddLocationSlice";
import addDepartment from "./Slice/Department/AddDepartmentSlice";
import updateUser from "./Slice/User/UpdateUserSlice";
import EmployeeList from "./Slice/Employee/EmployeeListSlice";
import DesignationListSlice from "./Slice/Designation/DesignationListSlice";
import UpdateEmployee from "./Slice/Employee/UpdateEmployeeSlice";
import fetchLocation from "./Slice/Loaction/LoactionListSlice";
import fetchRole from "./Slice/Role/RoleListSlice";
import DeleteCompany from "./Slice/Company/DeleteCompanySlice";
import DeleteDepartment from "./Slice/Department/DeleteDepartmentSlice";
import addCompany from "./Slice/Company/AddCompanySlice";
import DeleteLocation from "./Slice/Loaction/DeleteLocationSlice";
import AttandanceSlice from "./Slice/Attandance/AttandanceSlice";
import AddAttandance from "./Slice/EmployeeSection/AddAttandance";
import EmployeeSalary from "./Slice/EmployeeSection/EmployeeSalary";
import AdvanceSalaryListSlice from "./Slice/Salary/AdvanceSalaryListSlice";
import HrSalarySection from "./Slice/HrSection/HrSalarySection";
import HrAllowanceSlice from "./Slice/HrSection/HrAllowanceSlice";
import ProfileSlice from "./Slice/ProfileSlice";
import SearchNameSlice from "./Slice/HrSection/SearchNameSlice";
import AccountAdvanceSalaryrequestSlice from "./Slice/AccountSection/AccountAdvanceSalaryrequestSlice";
import HrAttandanceSlice from "./Slice/HrSection/HrAttandanceSlice";
import DeductionSlice from "./Slice/HrSection/DeductionSlice";
import EmployeeLists from "./Slice/HrSection/EmployeeList";
import HrLeaveSlice from "./Slice/HrSection/HrLeaveSlice";
import { Country } from "./Slice/Employee/CountrySlice";
import CountrySlice from "./Slice/Employee/CountrySlice";
const getInitialToken = () => {
  const initialToken = localStorage.getItem("token") || null;

  return initialToken;
};

let store;

try {
  store = configureStore({
    reducer: {
      auth: AuthReducer,
      //comman
      profile: ProfileSlice,
      country:CountrySlice,
      // employee
      employee: EmployeeList,
      addEmployee: AddEmployeeSlice,
      updateEmployee: UpdateEmployee,

      // user
      user: UserReducer,
      userDelete: UserDeleteReducer,
      updateUser: updateUser,
      // company
      company: CompanyListSlice,
      addCompany,
      DeleteCompany,
      //department
      departmentlist: DepartmentListSlice,
      addDepartment,
      DeleteDepartment,
      //location
      addLocation,
      location: fetchLocation,
      DeleteLocation,
      //
      designation: DesignationListSlice,
      role: fetchRole,
      Adminsalary: AdvanceSalaryListSlice,
      //
      attandance: AttandanceSlice,
      // Emp section
      addAttandance: AddAttandance,
      empsalary: EmployeeSalary,
      // hr salary
      hrsalary: HrSalarySection,
      allowance: HrAllowanceSlice,
      hrattandance: HrAttandanceSlice,
      deduction: DeductionSlice,
      emp:EmployeeLists,
      leave:HrLeaveSlice,
      
      // Account 
      accountsalartRequst: AccountAdvanceSalaryrequestSlice,
      hrsearch: SearchNameSlice,
    },

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(authMiddleware),
    preloadedState: {
      auth: {
        token: getInitialToken(),
        // other auth state fields
      },
    },
  });

  console.log("Redux store created successfully:", store);
} catch (error) {
  console.error("Error creating Redux store:", error);
}

export default store;
