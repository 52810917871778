import React, { useState, useEffect } from "react";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu, // Import the SubMenu component
} from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { Apartment } from "@mui/icons-material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../../Redux/Slice/ProfileSlice";
import { useNavigate } from 'react-router-dom';

const Item = ({ title, to, icon, selected, setSelected }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const SubMenuItem = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
                marginLeft: "20px",
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const [is_auth, setIs_auth] = useState(false);

    const { profileData, profilestatus } = useSelector((state) => state.profile);
    useEffect(() => {
        dispatch(fetchProfile());

    }, []);

    useEffect(() => {
        if (profilestatus == 'success') {
             if (profileData.role_id == 3) {
              return true;
          }
            else {
                navigate("/");
            }
        }
        else if (profilestatus == 'rejected') {
            navigate("/");
        }


    }, [profilestatus]);
    return (

        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important",
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important",
                },
            }}
        >


            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3" color={colors.grey[100]}>
                                    Hrms
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {/* {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Ed Roh
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  VP Fancy Admin
                </Typography>
              </Box>
            </Box>
          )} */}

                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item
                            title="Request"
                            to="/account/salary/request"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Loan Request"
                            to="/account/loan/request"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
{/* 
                        <SubMenu title="Employees" icon={<PeopleOutlinedIcon />}>
                            <SubMenuItem
                                title="Employee Lists"
                                to="/admin/employeelist"

                            /> */}
                            {/* <SubMenuItem
                title="Basic Information"
                to=""
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="immigration"
                to="/employeelist"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Emergency Contact"
                to=""
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Social Networking"
                to="/employeelist"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Document"
                to="/"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Quafication"
                to="/"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Work Experince"
                to="/"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Bank Account"
                to="/"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Contract"
                to="/"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Leave"
                to="/"
                selected={selected}
                setSelected={setSelected}
              /> */}
                        {/* </SubMenu> */}
                        {/* <SubMenu title="Earning" icon={<MonetizationOnIcon />}>
                            <SubMenuItem title="Salary Adavance Request"
                                to="/account/salary/request"
                            />
                            <SubMenuItem title="Loan"></SubMenuItem>
                            <SubMenuItem title="Salary Diffrenence"></SubMenuItem>
                            <SubMenuItem title="OverTime"></SubMenuItem>
                            <SubMenuItem title="Transport Allowance"></SubMenuItem>
                            <SubMenuItem title="Food Allowance"></SubMenuItem>
                            <SubMenuItem title="Mobile Allowance"></SubMenuItem>
                        </SubMenu> */}
                        {/* <SubMenu title="Deduction" icon={<MonetizationOnIcon />}>
                            <SubMenuItem title="Loan Deduction"></SubMenuItem>
                            <SubMenuItem title="Mobile Deduction"></SubMenuItem>
                            <SubMenuItem title="Traffic Fine Deduction"></SubMenuItem>
                            <SubMenuItem title="Penalty"></SubMenuItem>
                            <SubMenuItem title="Transport Allowance"></SubMenuItem>
                            <SubMenuItem title="Residency Deduction"></SubMenuItem>
                            <SubMenuItem title="Social Security Deduction"></SubMenuItem>
                        </SubMenu> */}
                        {/* <SubMenu title="Payroll" icon={<MonetizationOnIcon />}>
                            <SubMenuItem title="Earning" />

                       
                        </SubMenu>
                        <SubMenu title="Attandance" icon={<MonetizationOnIcon />}>
                            <SubMenuItem title="List" to="/admin/attandanceList" />
                        </SubMenu> */}
                        {/* <SubMenu title="User" icon={<PeopleOutlinedIcon />}>
              <SubMenuItem
                title="User List"
                to="/user"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Assign Role"
                to="/assign_role"
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu> */}
                        {/* <SubMenu title="Orgainzation" icon={<Apartment />}>
              <SubMenuItem
                title="Company"
                to="/company"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Department"
                to="/department"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Location"
                to="/location"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Designation"
                to="/designation"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Announcements"
                to="/accouncements"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Company Policy"
                to="/companyPolicy"
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu> */}
                        {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Core Hr
            </Typography>
            <Item
              title="Promotion"
              to="/promotion"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Award"
              to="/award"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Travel"
              to="/faq"
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Complaints"
              to="/faq"
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Warnings"
              to="/faq"
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Add User"
              to="/AddUser"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
                    </Box>
                </Menu>
            </ProSidebar>

        </Box>
    );
};

export default Sidebar;
