import {
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, state } from "react";
import { useDispatch, useSelector } from "react-redux";
import HrThemeProvideWrapper from "../global/Hr/HrThemeProvideWrapper";
import Card from "@mui/material/Card";
import { getHrPrevison } from "../../Redux/Slice/HrSection/HrAttandanceSlice";
const HrPrevison = () => {
    const dispatch = useDispatch();
    const { hrattandanceData, hrattandancestatus, } = useSelector((state) => state.hrattandance);
    useEffect(() => {
        dispatch(getHrPrevison());
    }, []);
    return (
        <HrThemeProvideWrapper>
            <Card>
                <CardHeader>
                    Provision
                </CardHeader>
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Total Previson</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hrattandancestatus === 'success' && hrattandanceData && hrattandanceData.length > 0 ? (
                                hrattandanceData.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.privison}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3}>No data found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </CardContent>
            </Card>
        </HrThemeProvideWrapper>
    )
}
export default HrPrevison;