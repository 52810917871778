import React from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import EmpSidebar from "../../global/Employee/EmpSidebar";
import EmpTopbar from "../../global/Employee/EmpTopbar";
import { ColorModeContext, useMode } from "../../../theme";

const EmpThemeProviderWrapper = ({ children }) => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <EmpSidebar />
          <main className="content">
            <EmpTopbar />
            {children}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default EmpThemeProviderWrapper;
