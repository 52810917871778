import * as React from "react";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from 'react-router-dom';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    Checkbox,
    Select,
    MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { addRole } from "../../Redux/Slice/Role/AddRoleSlice";
import { fetchRole } from "../../Redux/Slice/Role/RoleListSlice";
import { fetchUser } from "../../Redux/Slice/User/UserListSlice";

import { assignRole } from "../../Redux/Slice/Role/AssignRoleSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        width: "auto",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& > *": {
        width: "auto",
    },
}));

export default function AssignRoleList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.data);
    const role = useSelector((state) => state.role);
    const loading = useSelector((state) => state.user.isLoader);
    const error = useSelector((state) => state.user.isError);
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState("");

    const [userId, setUserId] = React.useState();
    const [userName, setUserName] = React.useState();
    const handleOpenModal = () => {
        navigate('/role');
        // setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };


    const handleCheckboxChange = (index, id, name) => {
        setUserId(id);
        setUserName(name)
        const updatedCheckboxes = [...selectedCheckboxes];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setSelectedCheckboxes(updatedCheckboxes);
    };

    const handleSendData = () => {

        if (!userId) {
            alert('Select at list one user');
        }

        const selectedUserIds = user.data
            .filter((_, index) => selectedCheckboxes[index])
            .map((userData) => userData.id);

        const roleData = {

            id: userId,
            role_id: userId,
            name: selectedRole,
            selectedUserIds,
            selectedRoleId: selectedRole,

        };

        console.log(roleData);
        dispatch(assignRole(roleData));

        // Reset state or perform any other actions as needed
        setSelectedCheckboxes([]);
        setSelectedRole("");

        // Reset state or perform any other actions as needed
        setSelectedCheckboxes([]);
        setSelectedRole("");

        handleCloseModal();
    };

    React.useEffect(() => {
        dispatch(fetchUser());
        dispatch(fetchRole());
    }, [dispatch]);

    const handleRole = () => {
        const data = {
            name: getValueById("name"),
        }

        dispatch(addRole(data));
    }
    const getValueById = (id) => {
        const element = document.getElementById(id);
        return element ? element.value : "";
    };

    return (
        <div style={{ padding: "20px" }}>
            <div
                style={{ display: "flex", marginRight: "10px", marginBottom: "20px" }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#4d5976" }}
                    startIcon={<AddIcon />}
                    onClick={handleOpenModal}
                >
                    Role(دور)
                </Button>

                <Select
                    label="Role"
                    style={{ marginLeft: "10px" }}
                    margin="normal"
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        Select Role
                    </MenuItem>
                    {role.data.map((role_data) => (
                        <MenuItem key={role_data.id} value={role_data.name}>
                            {role_data.name}
                        </MenuItem>
                    ))}
                </Select>

                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={handleSendData}
                >
                     Send Data(إرسال البيانات)
                </Button>
            </div>
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Add Role(أضف دور)</DialogTitle>
                <DialogContent>
                    <Grid container spacing={12}>
                        <Grid item md={12}>
                            <h5>Role(دور)</h5>
                            <TextField id="name" label="Role" fullWidth margin="normal" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary" />
                    Cancel(يلغي)

                    <Button onClick={handleRole} color="primary" variant="contained" />
                    Send(البيانات)

                    <Button onClick={handleSendData} color="primary" variant="contained">
                        Send Data(إرسال البيانات)

                    </Button>
                </DialogActions>
            </Dialog>
            {loading ? (
                <div>Loading...</div>
            ) : error ? (
                <div>Error: {error.message}</div>
            ) : user && Array.isArray(user.data) && user.data.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>Image(صورة)</StyledTableCell>
                                <StyledTableCell>UserName(اسم المستخدم)</StyledTableCell>
                                <StyledTableCell>Permission Role(دور الإذن)</StyledTableCell>
                                <StyledTableCell>Assign Role(تعيين الدور)</StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {user.data.map((userData, index) => (
                                <StyledTableRow key={userData.index}>
                                    <StyledTableCell>
                                        <Checkbox
                                            color="primary"
                                            checked={selectedCheckboxes[index] || false}
                                            onChange={() => handleCheckboxChange(index, userData.id, userData.name)}



                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>{<img src={userData.image} />}</StyledTableCell>
                                    <StyledTableCell>{userData.name}</StyledTableCell>
                                    <StyledTableCell> {userData.role}
                                        {/* {(() => {
                                            if (userData.role_id == 1) {
                                                return "Admin";
                                            }
                                            else if (userData.role_id == 2) {
                                                return "User";
                                            }
                                            else {
                                                return "Employee";
                                            }
                                        })()} */}
                                    </StyledTableCell>
                                    <StyledTableCell> {userData.role}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div style={{ padding: "20px" }}>No data available</div>
            )}
        </div>
    );
}