import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const DeleteCompanyList = createAsyncThunk(
  "company/delete",
  async (credentials, { getState }) => {
    const authToken = getState().auth.token;

    console.log("DeleteCompanyList - authToken:", authToken);

    try {
      const res = await fetch(`${apiUrl}/api/admin/company/delete/` + credentials, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      const data = await res.json();

      console.log("DeleteCompanyList - response:", data);

      if (data.status) {
        toast.success("Deleted successfully!")
        return data;
      }
      toast.error("Error deleting company. Please try again."); 
      throw new Error(data.message || "Delete failed");
    } catch (error) {
      console.error("DeleteCompanyList - error:", error);
      throw error;
    }
  }
);

const DeleteCompanySlice = createSlice({
  name: "deleteCompany",
  initialState: {
    deleteCompany: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.addCase(DeleteCompanyList.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(DeleteCompanyList.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.deleteCompany = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(DeleteCompanyList.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default DeleteCompanySlice.reducer;
