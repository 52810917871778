import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const assignRole = createAsyncThunk(
    "auth/addEmployee",
    async (credentials) => {
        const token = getTokenFromLocalStorage();

        const res = await fetch(`${apiUrl}/api/role/assign_role`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
            body: JSON.stringify(credentials),
        });
        const data = await res.json();

        if (!res.ok) {
            throw new Error(data.message || "Add Role failed");
        }
        alert(data.message);
        return data;
    }
);

const AssignRoleSlice = createSlice({
    name: "Assignrole",
    initialState: {
        employee: null,
        isAuthenticated: false,
        isLoader: false,
        isError: false,
        token: null,
    },
    extraReducers: (builder) => {
        builder.addCase(assignRole.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(assignRole.fulfilled, (state, action) => {
            state.isLoader = false;
            state.isAuthenticated = true;
            state.employee = action.payload.data;
            state.token = action.payload.token;
        });
        builder.addCase(assignRole.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
    },
});

export default AssignRoleSlice.reducer;
