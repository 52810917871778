import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
export const DeleteRole = createAsyncThunk(
    "designation/delete",
    async (credentials, { getState }) => {
        const authToken = getState().auth.token;
        const res = await fetch(`${apiUrl}/api/role/delete-role/` + credentials, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        });
        const data = await res.json();

        if (data.status) {
            alert(data.message);
            return data;
        }
        throw new Error(data.message || "Login failed");
    }
);
const DeleteRoleList = createSlice({
    name: "deleterole",
    initialState: {
        user: null,
        isAuthenticated: false,
        isLoader: false,
        isError: false,
        token: null, // Add a token field to the state
    },
    extraReducers: (builder) => {
        builder.addCase(DeleteRole.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(DeleteRole.fulfilled, (state, action) => {
            state.isLoader = false;
            state.isAuthenticated = true;
            state.user = action.payload.data;
            state.token = action.payload.token;
        });
        builder.addCase(DeleteRole.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
    },
});
export default DeleteRoleList.reducer;
