import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchDepartment = createAsyncThunk(
  "Department/fetchDepartment",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/api/admin/deparment`, {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });

    const final = await res.json();
    return final.data;
  }
);

const DepartmentListSlice = createSlice({
  name: "Department",
  initialState: {
    department_data: null,
    isLoader: false,
    isError: false,
    depart_status:'pending',
    
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDepartment.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchDepartment.fulfilled, (state, action) => {
      state.depart_status ='success';
      state.isLoader = false;
      state.department_data = action.payload;
      console.log("Department data:", state.department_data); // Log the Department data
    });
    builder.addCase(fetchDepartment.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default DepartmentListSlice.reducer;
