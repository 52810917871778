import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { successmsg } from "../../../Helpers/successmsg";
import { handleApiError } from "../../../Helpers/handleApiError";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (formData) => {
    const formDataToSend = new FormData();
    formDataToSend.append("id", formData.id);
    formDataToSend.append("company", formData.company);
    formDataToSend.append("type", formData.type);
    formDataToSend.append("reg_number", formData.reg_number);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("website", formData.website);
    formDataToSend.append("tax_number", formData.tax_number);
    formDataToSend.append("image", formData.image);
    try {
      const token = getTokenFromLocalStorage();

      const response = await axios.post( `${apiUrl}/api/admin/company/update`,
      formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      if (data.status) {
        successmsg(data.message);

      }
      return data;
    } catch (error) {
      handleApiError(error, 'failed');
    }
  }
);

const CompanyAddSlice = createSlice({
  name: "updateCompany",
  initialState: {
    updateCompany: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateCompany.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.updateCompany = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(updateCompany.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default CompanyAddSlice.reducer;
