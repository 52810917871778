import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const accountSalaryRequest = createAsyncThunk(
    "Account/salaryrequest",
    async (credentials) => {

        const token = getTokenFromLocalStorage();

        const res = await fetch(`${apiUrl}/api/account/salary`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },

        });
        const final = await res.json();
        if (final.data.status) {
            alert(final.data.message);
        }
        return final.data;

    }
);
// 2

export const accountSalaryRequestapprove = createAsyncThunk(
    "Account/salaryrequestapprove",
    async (credentials) => {
        try {
            const token = getTokenFromLocalStorage();

            const res = await fetch(`${apiUrl}/api/account/salary/approve`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });
            const final = await res.json();
            // console.log(final);
            return final.data;
        } catch (error) {

        }
    }
);

//3
export const accountLoanRequest = createAsyncThunk(
    "Account/accountLoanRequest",
    async (credentials) => {

        const token = getTokenFromLocalStorage();

        const res = await fetch(`${apiUrl}/api/account/loan`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },

        });
        const final = await res.json();
        if (final.data.status) {
            alert(final.data.message);
        }
        return final.data;

    }
);
// 4

export const accountLoanRequestapprove = createAsyncThunk(
    "Account/accountLoanRequestapprov",
    async (credentials) => {

        const token = getTokenFromLocalStorage();

        const res = await fetch(`${apiUrl}/api/account/loan/approve`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
            body: JSON.stringify(credentials),
        });
        const final = await res.json();
        // console.log(final);
        return final.data;

    }
);
const AccountAdvanceSalaryrequestSlice = createSlice({
    name: "accountSalary",
    initialState: {
        account: [],
        isLoader: false,
        isError: false,
        acountstatus: 'pending',
    },
    extraReducers: (builder) => {
        builder.addCase(accountSalaryRequest.pending, (state) => {
            state.isLoader = true;
            state.isError = false; // Reset error status on pending
        });
        builder.addCase(accountSalaryRequest.fulfilled, (state, action) => {
            state.acountstatus = 'success';
            state.isLoader = false;
            state.account = action.payload;
            console.log("Fetched Designation Dataaa:", action.payload);
        });
        builder.addCase(accountSalaryRequest.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        // 2
        builder.addCase(accountSalaryRequestapprove.pending, (state) => {
            state.isLoader = true;
            state.isError = false; // Reset error status on pending
        });
        builder.addCase(accountSalaryRequestapprove.fulfilled, (state, action) => {
            state.acountstatus = 'success';
            state.isLoader = false;
            state.account = action.payload;
            // console.log("Fetched Designation Dataaa:", action.payload);
        });
        builder.addCase(accountSalaryRequestapprove.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        // 3
        builder.addCase(accountLoanRequest.pending, (state) => {
            state.isLoader = true;
            state.isError = false; // Reset error status on pending
        });
        builder.addCase(accountLoanRequest.fulfilled, (state, action) => {
            state.acountstatus = 'success';
            state.isLoader = false;
            state.account = action.payload;
            // console.log("Fetched Designation Dataaa:", action.payload);
        });
        builder.addCase(accountLoanRequest.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        //4
        builder.addCase(accountLoanRequestapprove.pending, (state) => {
            state.isLoader = true;
            state.isError = false; // Reset error status on pending
        });
        builder.addCase(accountLoanRequestapprove.fulfilled, (state, action) => {
            state.acountstatus = 'success';
            state.isLoader = false;
            state.account = action.payload;
            // console.log("Fetched Designation Dataaa:", action.payload);
        });
        builder.addCase(accountLoanRequestapprove.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
    },
});

export default AccountAdvanceSalaryrequestSlice.reducer;
