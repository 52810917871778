import React, { useState, useEffect } from "react";
import {
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import EmpThemeProviderWrapper from "../global/Employee/EmployeeThemeProviderWrapper";
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from "react-redux";
import { emptotalattandance } from "../../Redux/Slice/EmployeeSection/AddAttandance";

const EmpAttandance = () => {
    const dispatch = useDispatch();
    const { attandances, attandanceStatus } = useSelector((state) => state.addAttandance);
    const [months, setMonths] = useState([
        { value: 1, name: 'January' },
        { value: 2, name: 'February' },
        { value: 3, name: 'March' },
        { value: 4, name: 'April' },
        { value: 5, name: 'May' },
        { value: 6, name: 'June' },
        { value: 7, name: 'July' },
        { value: 8, name: 'August' },
        { value: 9, name: 'September' },
        { value: 10, name: 'October' },
        { value: 11, name: 'November' },
        { value: 12, name: 'December' },
    ]);

    // Use effect if needed
    useEffect(() => {
        dispatch(emptotalattandance());
    }, []);

    return (
        <EmpThemeProviderWrapper>
            <Card>
                <CardHeader title="Employee Attendance" />
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Month</TableCell>
                                    <TableCell align="left">Present</TableCell>
                                    <TableCell align="left">Absent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {attandanceStatus === 'success' && attandances && attandances.attendance_data ? (
                                    attandances.attendance_data.map((data) => (
                                        <TableRow key={data.month}>
                                            <TableCell align="left">{months[data.month - 1].name}</TableCell>
                                            <TableCell align="left">{data.present}</TableCell>
                                            <TableCell align="left">{data.absent}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">No data</TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell align="left">Total Present</TableCell>
                                    <TableCell align="left">{attandances && attandances.total_present}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Total Absent</TableCell>
                                    <TableCell align="left">{attandances && attandances.total_absent}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </EmpThemeProviderWrapper>
    );
};

export default EmpAttandance;
