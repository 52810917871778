import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { adminSearchBySalary } from "../../Redux/Slice/Employee/EmployeeListSlice";
import EditIcon from "@mui/icons-material/Edit";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { fetchempsalary } from "../../Redux/Slice/Salary/AdvanceSalaryListSlice";
import AdminThemeProviderWrapper from "../../scenes/global/Admin/AdminThemeProviderWrapper";
import { approveEmpSalaryRequest } from "../../Redux/Slice/Salary/AdvanceSalaryListSlice";
import { downloadExcel } from "../../Redux/Slice/Employee/EmployeeListSlice";
import { adminAddsalarys } from "../../Redux/Slice/Salary/AdvanceSalaryListSlice";

const SalaryAdavance = () => {
  const dispatch = useDispatch();
  const { empsalary, status } = useSelector((state) => state.Adminsalary);
  const { data } = useSelector((state) => state.employee);
  const [openModal, setOpenModal] = React.useState(false);
  const [addModal, setAddModal] = React.useState(false);
  const [search, setSearch] = useState("");
  const [image, setImage] = useState("");
  const [salaryId, setSalaryId] = useState();
  const [salrystatus, setSalaryStatus] = useState("");
  const [editId, SetEditId] = useState();
  const [editStatus, SeteditStatus] = useState();
  const [formData, setFormData] = useState({
    id: data?.id,
    status: "Pending",
    amount: "",
    emi: '',
    date: '',
    name: data?.name,
    email: data?.email,
    emp_code: data?.enp_code,
    salary: data?.salary,
    user_id: data?.user_id,
    image: null,
  });

  useEffect(() => {
    dispatch(fetchempsalary(1));
    dispatch(adminSearchBySalary());
  }, []);

  const handleOpenModal = (id, amount, status) => {
    // setFormData({
    //   id: id,
    //   amount: amount,
    //   status: status,
    // });
    setSalaryId(id);

    setOpenModal(true);
  };

  const handleaddModal = (id, amount, status) => {
    setFormData({
      id: id,
      amount: amount,
      status: status,
    });
    setAddModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCloseModal = (id) => {
    setSalaryId(id);
    setOpenModal(false);
  };

  const handleCloseAddModal = () => {
    setAddModal(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  useEffect(() => {
    // This effect will run whenever image state is updated
    console.log("Updated Image:", image);
  }, [image]); // Dependency array ensures the effect runs when image state changes

  const handleForm = () => {
    // if (!salaryId || !image || !salrystatus) {
    //   console.error("Some data is missing");
    //   return;
    // }

    // const formData = new FormData();
    // formData.append("id", salaryId);
    // formData.append("image", image);
    // formData.append("status", salrystatus);

    // console.log("FormData:", formData);
const mergedata ={
  id:salaryId,
  status:salrystatus,
}
    dispatch(approveEmpSalaryRequest(mergedata));
  };
  const searchByName = () => {
    const serachdata = search;
    dispatch(adminSearchBySalary(serachdata));
  };

  const downloadExcelData = () => {
    dispatch(downloadExcel());
  };

  const handleFormadd = async (e) => {

    if (data == null) {
      alert("please Search user");
    }
    else if (formData.amount === "") {
      alert("Amount Required");
    } else if (formData.date === "") {
      alert("Date Required");
    }
    const merge = {
      id: data.id,
      value: formData,
    }
    dispatch(adminAddsalarys(merge));
  };

  return (
    <AdminThemeProviderWrapper>
      <div style={containerStyle}>
        <div style={detailsStyle}>
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>Add Advance Salary</DialogTitle>
            <DialogContent>
              <Grid container spacing={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Status
                  </Typography>

                  <select
                    id="salrystatus"
                    name="salrystatus"
                    onChange={(e) => setSalaryStatus(e.target.value)}
                    value={salrystatus}
                    className="form-control"
                  >
                    <option value="default" disabled>
                      Select an option
                    </option>
                    <option value="Pending">Pending</option>
                    <option value="Approve">Approve</option>
                    <option value="Reject">Reject</option>
                  </select>
                </Grid>

                {salrystatus == "Approve" ? (
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom>
                      Recipt
                    </Typography>
                    <input
                      name="image"
                      id="image"
                      type="file"
                      onChange={handleImageChange}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="secondary">
                Cancel
              </Button>
              <Button color="primary" variant="contained" onClick={handleForm}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={addModal} onClose={handleCloseAddModal}>
            <DialogTitle>Add Advance Salary</DialogTitle>
            <DialogContent>
              <Grid container spacing={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Amount
                  </Typography>

                  <TextField
                    value={formData.company}
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    // error={!!formErrors.amount}
                    // helperText={formErrors.amount}
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Deducction Start
                  </Typography>

                  <TextField
                    value={formData.company}
                    type="date"
                    name="date"
                    onChange={handleChange}
                    value={formData.date}
                    // error={!!formErrors.date}
                    // helperText={formErrors.date}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    EmI
                  </Typography>

                  <TextField
                    value={formData.company}
                    type="number"
                    name="emi"
                    onChange={handleChange}
                    value={formData.emi}
                    // error={!!formErrors.emi}
                    // helperText={formErrors.emi}
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddModal} color="secondary">
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleFormadd}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
          {/*  Search user */}
          <Card>
            <CardHeader title="Salary" />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={handleaddModal}
                >
                  Add Advance Salary
                </Button>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type="search"
                  name="name"
                  fullWidth
                  margin="normal"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  name="search"
                  fullWidth
                  startIcon={<Search />}
                  aria-placeholder="search"
                  onClick={searchByName}
                />
              </Grid>
            </Grid>
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Name
                      </Typography>
                      <TextField
                        name="name"
                        fullWidth
                        margin="normal"
                        value={data.name}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Email
                      </Typography>
                      <TextField
                        fullWidth
                        margin="normal"
                        name="email"
                        value={data.email}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Salary
                      </Typography>
                      <TextField
                        name="salary"
                        fullWidth
                        margin="normal"
                        value={data.salary}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Emp code
                      </Typography>
                      <TextField
                        name="emp_code"
                        fullWidth
                        margin="normal"
                        value={data.emp_code}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Id</TableCell>
                    <TableCell>name</TableCell>
                    <TableCell>Emp Code</TableCell>
                    <TableCell>Salary</TableCell>
                    <TableCell>Advance</TableCell>
                    <TableCell>Emi</TableCell>
                    <TableCell>Approval</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Download</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status == "Success"
                    ? empsalary.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.emp_code}</TableCell>
                        <TableCell>{row.amount}</TableCell>
                        <TableCell>{row.salary}</TableCell>
                        <TableCell>{row.emi}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        {row.status == "Approve" ? (
                          <TableCell></TableCell>
                        ) : (
                          <IconButton>
                            <EditIcon
                              onClick={() =>
                                handleOpenModal(
                                  row.salary_id,
                                  row.amount,
                                  row.status
                                )
                              }
                            />
                          </IconButton>
                        )}

                        <TableCell>
                          <CloudDownloadIcon
                            onClick={() => downloadExcelData()}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                    : "no data"}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </AdminThemeProviderWrapper>
  );
};
const containerStyle = {
  display: "flex",
  flexDirection: "row",
  height: "100vh",
};

const navStyle = {
  flex: "0 0 30%",
  marginTop: "30px",
  padding: "20px",
};

const detailsStyle = {
  flex: "1",
  padding: "20px",
};

export default SalaryAdavance;
