import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
export const DeleteEmployee = createAsyncThunk(
  "company/delete",
  async (credentials, { getState }) => {
    const authToken = getState().auth.token;
    const res = await fetch(
      `${apiUrl}/api/admin/employee/delete/` + credentials,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    const data = await res.json();

    if (data.status) {
      alert(data.message);
      return data;
    }
    throw new Error(data.message || "Login failed");
  }
);
const DeleteDepartmentSlice = createSlice({
  name: "deleteUser",
  initialState: {
    deleteUser: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null, // Add a token field to the state
  },
  extraReducers: (builder) => {
    builder.addCase(DeleteEmployee.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(DeleteEmployee.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.deleteUser = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(DeleteEmployee.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});
export default DeleteDepartmentSlice.reducer;
