import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckboxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckboxIcon from "@mui/icons-material/CheckBox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Popover,
  Typography,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompany } from "../../Redux/Slice/Company/CompanyListSlice";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { fetchDepartment } from "../../Redux/Slice/Department/DepartmentListSlice";
import { addEmployee } from "../../Redux/Slice/Employee/AddEmployeeSlice";
import { fetchEmployee } from "../../Redux/Slice/Employee/EmployeeListSlice";
import dayjs from "dayjs";
import { updateEmployee } from "../../Redux/Slice/Employee/UpdateEmployeeSlice";
import HrThemeProvideWrapper from "../global/Hr/HrThemeProvideWrapper";
import { fetchDesignation } from "../../Redux/Slice/Designation/DesignationListSlice";
import InputBase from "@mui/material/InputBase";
import { saveAs } from "file-saver";
import { DeleteEmployee } from "../../Redux/Slice/Employee/DeleteemployeeSlice";
import { import_user } from "../../Redux/Slice/HrSection/EmployeeList";
import * as XLSX from 'xlsx';
export default function Employee() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [role, setRole] = React.useState(1);
  const [country, setcountry] = React.useState("");
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [dobOfJoining, setDobOfJoining] = React.useState(null);
  const employee = useSelector((state) => state.employee?.data);
  console.log("employee", employee);
  const loading = useSelector((state) => state.employee.isLoader);
  const error = useSelector((state) => state.employee.isError);
  const [selectedFilterOptions, setSelectedFilterOptions] = React.useState({
    company: [],
    department: [],
    designation: [],
    id: [],
    emp_code: [],
  });
  const selectedCompanies = 0;
  const { department_data, depart_status } = useSelector(
    (state) => state.departmentlist
  );
  const { designation_data, designation_status } = useSelector(
    (state) => state.designation
  );
  const { companyData, comapny_status } = useSelector((state) => state.company);
  const navigate = useNavigate();
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const countries = [
    { value: "afghanistan" },
    { value: "armenia" },
    { value: "azerbaijan" },
    { value: "bahrain" },
    { value: "bangladesh" },
    { value: "bhutan" },
    { value: "brunei" },
    { value: "cambodia" },
    { value: "china" },
    { value: "cyprus" },
    { value: "georgia" },
    { value: "india" },
    { value: "indonesia" },
    { value: "iran" },
    { value: "iraq" },
    { value: "israel" },
    { value: "japan" },
    { value: "jordan" },
    { value: "kazakhstan" },
    { value: "kuwait" },
    { value: "kyrgyzstan" },
    { value: "laos" },
    { value: "lebanon" },
    { value: "malaysia" },
    { value: "maldives" },
    { value: "mongolia" },
    { value: "myanmar" },
    { value: "nepal" },
    { value: "north korea" },
    { value: "oman" },
    { value: "pakistan" },
    { value: "palestine" },
    { value: "philippines" },
    { value: "qatar" },
    { value: "saudi arabia" },
    { value: "singapore" },
    { value: "south korea" },
    { value: "sri lanka" },
    { value: "syria" },
    { value: "tajikistan" },
    { value: "thailand" },
    { value: "timor-leste" },
    { value: "turkey" },
    { value: "turkmenistan" },
    { value: "united arab emirates" },
    { value: "uzbekistan" },
    { value: "vietnam" },
    { value: "yemen" },

    // Add more countries as needed
  ];
  const [errordata, seterrordata] = React.useState({
    id: "",
    name: "",
    email: "",
    gender: "",
  });
  const handleAddEmployee = async () => {
    const employeeData = {
      name: getValueById("name"),
      last_name: getValueById("last_name"),
      middle_name: getValueById("middle_name"),
      staff_id: getValueById("staff_id"),
      email: getValueById("email"),
      phone: getValueById("phone"),
      dob: getValueById("dob"),
      gender:
        document.querySelector('input[name="gender"]:checked')?.value || "",
      company: selectedCompany,
      department: selectedDepartment,
      designation: selectedDesignation,
      office_shift: selectedOfficeShift,
      username: getValueById("username"),
      role: role,
      password: getValueById("password"),
      dob_of_joining: getValueById("dob_of_joining"),
      attendance_type: getValueById("attendance_type"),
      empCode: getValueById("emp_Code"),
      country: country,
      // image: selectedImage,
    };

    try {
      // Validate required fields
      const errors = {};

      if (!employeeData.name) {
        errors.name = "Name is required";
      }

      if (!employeeData.email) {
        errors.email = "Email is required";
      }

      if (!employeeData.gender) {
        errors.gender = "Gender is required";
      }

      if (!employeeData.empCode) {
        errors.emp_code = "Employee is required";
      }

      seterrordata(errors);

      // Check if there are any errors
      if (Object.keys(errors).length > 0) {
        return;
      }
      // Add the employee
      await dispatch(addEmployee(employeeData, role));
      dispatch(fetchEmployee());
      handleCloseModal();
    } catch (error) {
      console.error("Error adding employee:", error);
      // Handle errors, if any
      // toast.error("Error adding employee. Please try again.");
    }
  };

  const getValueById = (id) => {
    const element = document.getElementById(id);
    return element ? element.value : "";
  };

  // const [companyData, setCompanyData] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [selectedDepartment, setSelectedDepartment] = React.useState("");
  const [selectedDesignation, setSelectedDesignation] = React.useState("");
  const [selectedOfficeShift, setSelectedOfficeShift] = React.useState("");
  const handleChange = (property, event) => {
    if (property === "company") {
      setSelectedCompany(event.target.value);
    } else if (property === "role") {
      setRole(event.target.value);
    } else if (property === "department") {
      setSelectedDepartment(event.target.value);
    } else if (property === "designation") {
      setSelectedDesignation(event.target.value);
    } else if (property === "office_shift") {
      setSelectedOfficeShift(event.target.value);
    } else if (property === "country") {
      setcountry(event.target.value);
    } else {
      // Handle other properties if needed
    }
  };

  React.useEffect(() => {
    dispatch(fetchEmployee());
    dispatch(fetchDepartment());
    dispatch(fetchDesignation());
    dispatch(fetchCompany());
  }, [dispatch]);

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [editEmployeeData, setEditEmployeeData] = React.useState({
    id: "",
    name: "",
    last_name: "",
    staff_id: "",
    email: "",
    phone: "",
    dob: null, // Set to the appropriate initial value for DatePicker
    gender: "",
    company: "",
    department: "",
    designation: "",
    office_shift: "",
    username: "",
    role: "",
    password: "",
    dob_of_joining: null, // Set to the appropriate initial value for DatePicker
    attendance_type: "",
    image: null, // Set to the appropriate initial value
  });
  const handleEditModalChange = (field, value) => {
    setEditEmployeeData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleOpenEditModal = (employeeData) => {
    if (employeeData) {
      setSelectedEmployee(employeeData);
      // Set the edit modal form data based on the selected user
      setEditEmployeeData({
        id: employeeData.id,
        name: employeeData.name,
        last_name: employeeData.last_name,
        staff_id: employeeData.staff_id,
        email: employeeData.email,
        phone: employeeData.phone,
        dob: dayjs(employeeData.dob), // Convert to the appropriate format for DatePicker
        gender: employeeData.gender,
        company: employeeData.company,
        department: employeeData.department,
        designation: employeeData.designation,
        office_shift: employeeData.office_shift,
        username: employeeData.username,
        role: employeeData.role,
        password: employeeData.password,
        dob_of_joining: dayjs(employeeData.dob_of_joining), // Convert to the appropriate format for DatePicker
        attendance_type: employeeData.attendance_type,
        image: employeeData.image,
      });
    } else {
      setSelectedEmployee(null);
      setEditEmployeeData({
        id: "",
        name: "",
        last_name: "",
        staff_id: "",
        email: "",
        phone: "",
        dob: null,
        gender: "",
        company: "",
        department: "",
        designation: "",
        office_shift: "",
        username: "",
        role: "",
        password: "",
        dob_of_joining: null,
        attendance_type: "",
        image: null,
      });
    }
    setOpenEditModal(true);
  };
  const handleEditImageChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected File:", file);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        console.log("Image Data URL:", reader.result);
        handleEditModalChange("image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const downloadExcel = () => {
    const ExcelJS = require("exceljs");
    const workbook = new ExcelJS.Workbook();

    // Define columns for the sheets
    const employeeColumns = [
      { header: "Sr.No", key: "id", width: 10 },
      { header: "Emp code", key: "emp_code", width: 20 },
      { header: "Name", key: "name", width: 20 },
      { header: "Company", key: "company", width: 20 },
      { header: "Department", key: "department", width: 20 },
      { header: "Role", key: "role", width: 20 },
      { header: "Designation", key: "designation", width: 20 },
      { header: "Phone", key: "phone", width: 20 },
    ];

    // Add Employee sheet
    const employeeSheet = workbook.addWorksheet("Employee");
    employeeSheet.columns = employeeColumns;

    // Extract data from the Employee table
    const employeeTable = document.getElementById("employee_data");
    const employeeRows = employeeTable.querySelectorAll("tbody tr");

    const employeeData = Array.from(employeeRows).map((row, index) => {
      const cells = row.querySelectorAll("td");
      return {
        id: index + 1,
        emp_code: cells[1].textContent.trim(),
        name: cells[2].textContent.trim(),
        company: cells[3].textContent.trim(),
        department: cells[4].textContent.trim(),
        role: cells[5].textContent.trim(),
        designation: cells[6].textContent.trim(),
        phone: cells[7].textContent.trim(),
      };
    });

    // Add data to Employee sheet
    employeeSheet.addRows(employeeData);

    // Add additional sheets with similar logic for other data

    // Save the workbook to a file using Blob
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "example.xlsx";

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);

        console.log("Workbook created successfully!");
      })
      .catch((error) => {
        console.error("Error creating workbook:", error);
      });
  };

  const handleEditEmployee = () => {
    // Extract fields from editUserData
    const {
      id,
      name,
      last_name,
      staff_id,
      email,
      phone,
      dob,
      gender,
      company,
      department,
      designation,
      office_shift,
      username,
      role,
      password,
      dob_of_joining,
      attendance_type,
      image,
    } = editEmployeeData;

    // Create the credentials object
    const credentials = {
      id,
      name,
      last_name,
      staff_id,
      email,
      phone,
      dob,
      gender,
      company,
      department,
      designation,
      office_shift,
      username,
      role,
      password, // You may want to handle the case where password is undefined
      dob_of_joining,
      attendance_type,
      image,
    };

    dispatch(updateEmployee(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchEmployee());
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Architect:", error);
        // Toast.error("Error updating Architect. Please try again.");
      });
  };
  const handleOpenEmpDetails = (id) => {
    navigate(`/hr/basic-details/${id}`);
  };

  const cellStyle = {
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#2d388e",
    color: "white",
  };
  const tableStyle = { fontSize: "13px", padding: "4px" };
  const modalText = { fontSize: "10px" };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event, employeeData) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employeeData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedEmployee(null);
  };
  const handleDeleteUser = (employeeId) => {
    // Dispatch the DeleteEmployee action with the employee ID as payload
    dispatch(DeleteEmployee(employeeId))
      .then((result) => {
        // Handle success, if necessary
        console.log("Employee deleted successfully:", result);
        dispatch(fetchEmployee());
      })
      .catch((error) => {
        // Handle error, if necessary
        console.error("Error deleting employee:", error);
      });
  };

  const handleMenuItemClick = (action) => {
    handleClose();
    if (action === "view") {
      handleOpenEmpDetails(selectedEmployee.id);
    } else if (action === "edit") {
      handleOpenEditModal(selectedEmployee.id);
    } else if (action === "delete") {
      handleDeleteUser(selectedEmployee.id);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Access the uploaded file
   const reader = new FileReader();
    let allSheetData = [];

    reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });


        workbook.SheetNames.forEach((sheetName, index) => {
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });


            if (index == 0) {

                allSheetData[sheetName] = jsonData.slice(0);

            } else {

                allSheetData[sheetName] = jsonData.slice(0);

            }
        });
        const sectionsArray = Object.entries(allSheetData);
        // const dataString  = JSON.stringify(allSheetData);
        import_users(sectionsArray);
        // setExcelData(sectionsArray);
        // setError('');
    };

    reader.readAsArrayBuffer(file);

}; 


  const import_users = (file) => {
    // const file = e.target.files[0]; // Access the first file from the files array
    dispatch(import_user(file));
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //  ================================================= filter=====================================================//
  // Extracting and logging company names

  // const empCode = employee.data.map((employee) => employee.emp_code);
  // console.log("Employee empCode:", empCode);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedName, setSelectedName] = React.useState([]);
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  // // Extracting and logging company names
  // const companyNamesSet = new Set();
  // employee.data.forEach((employee) => {
  //   companyNamesSet.add(employee.company);
  // });

  // const companyNames = Array.from(companyNamesSet);

  // // Extracting and logging company names
  // const departmentNamesSet = new Set();
  // employee.data.forEach((employee) => {
  //   departmentNamesSet.add(employee.department);
  // });
  // const departmentNames = Array.from(departmentNamesSet);

  // const designationNamesSet = new Set();
  // employee.data.forEach((employee) => {
  //   designationNamesSet.add(employee.designation);
  // });

  // const designationtNames = Array.from(designationNamesSet);

  const filteredData = Array.isArray(employee)
    ? employee.filter((employeeData) => {
        const empCodeString =
          employeeData.emp_code !== null && employeeData.emp_code !== undefined
            ? employeeData.emp_code.toString().toLowerCase()
            : "";

        // Check if the properties are defined before calling toLowerCase
        const nameLowerCase = employeeData.name?.toLowerCase() || "";
        const companyLowerCase = employeeData.company?.toLowerCase() || "";
        const departmentLowerCase =
          employeeData.department?.toLowerCase() || "";
        const isNameSelected =
          selectedFilterOptions.id.length === 0 ||
          selectedFilterOptions.id.includes(employeeData.id);
        const isEmpCodeSelected =
          selectedFilterOptions.emp_code.length === 0 ||
          selectedFilterOptions.emp_code.includes(employeeData.emp_code);
        const isCompanySelected =
          selectedFilterOptions.company.length === 0 ||
          selectedFilterOptions.company.includes(employeeData.company);
        const isDepartmentSelected =
          selectedFilterOptions.department.length === 0 ||
          selectedFilterOptions.department.includes(employeeData.department);
        const isDesignationSelected =
          selectedFilterOptions.designation.length === 0 ||
          selectedFilterOptions.designation.includes(employeeData.designation);

        return (
          (nameLowerCase.includes(searchTerm) ||
            departmentLowerCase.includes(searchTerm) ||
            empCodeString.includes(searchTerm)) &&
          isCompanySelected &&
          isDepartmentSelected &&
          isDesignationSelected &&
          isNameSelected &&
          isEmpCodeSelected
        );
      })
    : [];
  console.log("filteredData", filteredData);
  const slicedData =
    filteredData?.length > 0
      ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];

  const handleOpenFilterModal = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseFilterModal = () => {
    setAnchorEl2(null);
  };
  console.log("slicedData", slicedData);
  return (
    <HrThemeProvideWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            background: "linear-gradient(to right, #1b2365, #181f5e)",
            marginBottom: "7px",
          }}
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Employee/إضافة موظف
        </Button>

        <TextField 
        type="file"
        name="import_user"
        onChange={(e)=>handleFileChange(e)}
        />
        <Autocomplete
          multiple
          id="id-dropdown"
          options={
            Array.isArray(employee)
              ? employee.map((employee) => employee.id)
              : []
          }
          getOptionLabel={(option) =>
            employee.find((emp) => emp.id === option)?.name || ""
          }
          disableCloseOnSelect
          onChange={(event, values) =>
            setSelectedFilterOptions((prevOptions) => ({
              ...prevOptions,
              id: values,
            }))
          }
          value={selectedFilterOptions.id}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckboxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckboxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {employee.find((emp) => emp.id === option)?.name || ""}
              {employee.find((emp) => emp.id === option)?.middle_name || ""}
              {employee.find((emp) => emp.id === option)?.last_name || ""}
            </li>
          )}
          style={{
            width: "200px",
            marginBottom: "10px",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Name"
              sx={{
                "& .MuiInputBase-root": {
                  paddingLeft: "5px", // Adjust the left padding
                  paddingRight: "5px", // Adjust the right padding
                  borderRadius: "5px", // Set the desired border radius
                },
                "& .MuiAutocomplete-inputRoot": {
                  backgroundColor: "white", // Set the background color for the dropdown
                },
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <span
                {...getTagProps({ index })}
                key={index}
                style={{ display: "none" }}
              />
            ))
          }
        />
        <Autocomplete
          multiple
          id="id-dropdown"
          options={
            Array.isArray(employee)
              ? employee.map((employee) => employee.id)
              : []
          }
          getOptionLabel={(option) =>
            employee.find((emp) => emp.id === option)?.emp_code || ""
          }
          disableCloseOnSelect
          onChange={(event, values) =>
            setSelectedFilterOptions((prevOptions) => ({
              ...prevOptions,
              id: values,
            }))
          }
          value={selectedFilterOptions.id}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckboxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckboxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {employee.find((emp) => emp.id === option)?.emp_code || ""}
            </li>
          )}
          style={{
            width: "200px",
            marginBottom: "10px",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Emp Code"
              sx={{
                "& .MuiInputBase-root": {
                  paddingLeft: "5px", // Adjust the left padding
                  paddingRight: "5px", // Adjust the right padding
                  borderRadius: "5px", // Set the desired border radius
                },
                "& .MuiAutocomplete-inputRoot": {
                  backgroundColor: "white", // Set the background color for the dropdown
                },
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <span
                {...getTagProps({ index })}
                key={index}
                style={{ display: "none" }}
              />
            ))
          }
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <InputBase
            sx={{ ml: 2 }}
            placeholder="Search"
            onChange={handleSearch}
            style={{
              color: "#000",
              paddingLeft: "10px",
              padding: "3px",
              paddingRight: "10px",
              borderRadius: "5px",
              backgroundColor: "#fff",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s, box-shadow 0.3s",
              height: "48px",
              marginBottom: "8px",
            }}
          />

          <IconButton onClick={downloadExcel} style={{ marginLeft: "10px" }}>
            <SystemUpdateAltIcon />
          </IconButton>
          <IconButton onClick={handleOpenFilterModal}>
            <FilterAltIcon />
          </IconButton>
          <Popover
            open={Boolean(anchorEl2)}
            anchorEl={anchorEl2}
            onClose={handleCloseFilterModal}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ padding: "20px" }}>
              <Autocomplete
                multiple
                id="company-dropdown"
                options={
                  Array.isArray(employee)
                    ? [
                        ...new Set(
                          employee
                            .filter(
                              (employee) =>
                                employee.company !== null &&
                                employee.company.trim() !== ""
                            )
                            .map((employee) => employee.company)
                        ),
                      ]
                    : []
                }
                disableCloseOnSelect
                onChange={(event, values) =>
                  setSelectedFilterOptions((prevOptions) => ({
                    ...prevOptions,
                    company: values,
                  }))
                }
                value={selectedFilterOptions.company}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckboxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckboxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                style={{ width: "200px", marginBottom: "10px" }}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Company" />
                )}
              />
              <Autocomplete
                multiple
                id="department-dropdown"
                options={
                  Array.isArray(employee)
                    ? [
                        ...new Set(
                          employee
                            .filter(
                              (employee) =>
                                employee.department !== null &&
                                employee.department.trim() !== ""
                            )
                            .map((employee) => employee.department)
                        ),
                      ]
                    : []
                }
                disableCloseOnSelect
                onChange={(event, values) =>
                  setSelectedFilterOptions((prevOptions) => ({
                    ...prevOptions,
                    department: values,
                  }))
                }
                value={selectedFilterOptions.department}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckboxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckboxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                style={{ width: "200px", marginBottom: "10px" }}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Department" />
                )}
              />

              <Autocomplete
                multiple
                id="designation-dropdown"
                options={
                  Array.isArray(employee)
                    ? [
                        ...new Set(
                          employee
                            .filter(
                              (employee) =>
                                employee.designation !== null &&
                                employee.designation.trim() !== ""
                            )
                            .map((employee) => employee.designation)
                        ),
                      ]
                    : []
                }
                disableCloseOnSelect
                onChange={(event, values) =>
                  setSelectedFilterOptions((prevOptions) => ({
                    ...prevOptions,
                    designation: values,
                  }))
                }
                value={selectedFilterOptions.designation}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckboxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckboxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                style={{ width: "200px", marginBottom: "10px" }}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Designation" />
                )}
              />
            </div>
          </Popover>
        </div>
      </div>

      <Dialog open={openModal} maxWidth="md">
        <DialogTitle>Add Employee/إضافة موظف</DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <h5>First Name</h5>
                  <TextField
                    id="name"
                    fullWidth
                    margin="normal"
                    error={!!errordata.name}
                    helperText={errordata.name}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <h5>Middle Name</h5>
                  <TextField
                    id="middle_name"
                    fullWidth
                    name=""
                    margin="normal"
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <h5>Last Name</h5>
                  <TextField
                    id="last_name"
                    fullWidth
                    name=""
                    margin="normal"
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <h5>Employee Code</h5>
                  <TextField
                    id="emp_Code"
                    fullWidth
                    name=""
                    // margin="normal"
                    error={!!errordata.emp_code}
                    helperText={errordata.emp_code}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <h5>Company</h5>
                  <Select
                    fullWidth
                    margin="normal"
                    value={selectedCompany || "Select Company"}
                    onChange={(event) => handleChange("company", event)}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="Select Company" disabled>
                      Select Company
                    </MenuItem>
                    {comapny_status == "success" ? (
                      companyData.data.map((company) => (
                        <MenuItem key={company.id} value={company.company}>
                          {company.company}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </Grid>

                <Grid item xs={4}>
                  <h5>Email/البريد الإلكتروني</h5>
                  <TextField
                    id="email"
                    fullWidth
                    error={!!errordata.email}
                    helperText={errordata.email}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <h5 style={{ marginBottom: "35px" }}>Phone/الهاتف</h5>
                  <TextField
                    id="phone"
                    fullWidth
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <h5 style={{ marginBottom: "35px" }}>
                    Date of Birth/تاريخ الميلاد
                  </h5>
                  <TextField
                    type="date"
                    variant="outlined"
                    id="dob"
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={4}>
                  <h5>Date Of Joining/تاريخ الانضمام</h5>
                  <TextField
                    type="date"
                    variant="outlined"
                    id="dob_of_joining"
                    margin="normal"
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={4}>
                  <h5>Gender/جنس</h5>
                  <RadioGroup row aria-label="gender" name="gender">
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                  {!!errordata.gender && (
                    <FormHelperText style={{ color: "red" }}>
                      {errordata.gender}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <h5>Country</h5>
                  <Select
                    id="country"
                    fullWidth
                    margin="normal"
                    value={country || "Select Country"}
                    onChange={(event) => handleChange("country", event)}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="Select Country" disabled>
                      Select Country
                    </MenuItem>
                    {countries.map((country, index) => (
                      <MenuItem key={index} value={country.value}>
                        {country.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={4}>
                  <h5>Department</h5>
                  <Select
                    fullWidth
                    margin="normal"
                    value={selectedDepartment || "Select Department"}
                    onChange={(event) => handleChange("department", event)}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="Select Department" disabled>
                      Select Department
                    </MenuItem>
                    {depart_status == "success" ? (
                      department_data.map((department) => (
                        <MenuItem key={department.id} value={department.name}>
                          {department.name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <h5>Designation</h5>
                  <Select
                    fullWidth
                    margin="normal"
                    value={selectedDesignation || "Select Designation"}
                    onChange={(event) => handleChange("designation", event)}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="Select Designation" disabled>
                      Select Designation
                    </MenuItem>
                    {designation_status === "success" ? (
                      designation_data.map((designation) => (
                        <MenuItem
                          key={designation.id}
                          value={designation.designation}
                        >
                          {designation.designation}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <h5>Office Shift</h5>
                  <Select
                    fullWidth
                    margin="normal"
                    value={selectedOfficeShift || "Select Office Shift"}
                    onChange={(event) => handleChange("office_shift", event)}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="Select Office Shift" disabled>
                      Select Office Shift
                    </MenuItem>
                    <MenuItem value="8.00 To 16.00"> 8.00 To 16.00</MenuItem>
                    <MenuItem value="8.30 To 16.30"> 8.30 To 16.30</MenuItem>
                    <MenuItem value="9.00 To 17.00"> 9.00 To 17.00</MenuItem>
                    <MenuItem value="9.30 To 17.30"> 9.30 To 17.30</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={4}>
                  <h5>Select Role</h5>
                  <Select
                    fullWidth
                    margin="normal"
                    id="role"
                    value={role || "select role"}
                    onChange={(event) => handleChange("role", event)}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="Select Role" disabled>
                      Select Role
                    </MenuItem>
                    <MenuItem value="1">Admin </MenuItem>
                    <MenuItem value="2">Hr </MenuItem>
                    <MenuItem value="3">Account </MenuItem>
                    <MenuItem value="4">Employee </MenuItem>
                    <MenuItem value="5">Factory </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>

        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleAddEmployee}
            color="primary"
            variant="contained"
            sx={{
              width: 100,
              background: "linear-gradient(to right, #1b2365, #181f5e)",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : filteredData && filteredData.length > 0 ? (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table aria-label="customized table" id="employee_data">
              <TableHead>
                <TableRow>
                  <TableCell style={cellStyle}>Sl. No.</TableCell>
                  <TableCell style={cellStyle}>Emp Code</TableCell>
                  <TableCell style={cellStyle}>Name</TableCell>
                  <TableCell style={cellStyle}>Company</TableCell>
                  <TableCell style={cellStyle}>Department</TableCell>
                  <TableCell style={cellStyle}>Role</TableCell>
                  <TableCell style={cellStyle}>Desgination</TableCell>
                  <TableCell style={cellStyle}>Phone</TableCell>
                  <TableCell style={cellStyle}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {slicedData.map((employeeData, index) => (
                  <TableRow key={employeeData.id}>
                    <TableCell style={tableStyle}>{index + 1}</TableCell>
                    <TableCell style={tableStyle}>
                      {employeeData.emp_code}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {employeeData.name} {employeeData.middle_name}{" "}
                      {employeeData.last_name}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {employeeData.company}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {employeeData.department}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {employeeData.role === 1
                        ? "Admin"
                        : employeeData.role === 2
                        ? "Hr"
                        : employeeData.role === 3
                        ? "Account"
                        : employeeData.role === 4
                        ? "Employee"
                        : "Factory"}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {employeeData.designation}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {employeeData.phone}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      <IconButton
                        onClick={(e) => handleMenuClick(e, employeeData)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Typography sx={{ p: 1 }}>
                          <IconButton
                            style={{ color: "#2d388e" }}
                            onClick={() => handleMenuItemClick("view")}
                          >
                            <EditIcon /> <h5 style={modalText}>Edit</h5>
                          </IconButton>

                          <IconButton
                            color="error"
                            onClick={() => handleMenuItemClick("delete")}
                          >
                            <DeleteIcon /> <h5 style={modalText}>Delete</h5>
                          </IconButton>
                        </Typography>
                      </Popover>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100, -1]}
            component="div"
            count={employee.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
          />
        </React.Fragment>
      ) : (
        <div style={{ padding: "20px" }}>No Employee available</div>
      )}
      <Dialog open={openEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>Add Employee</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <input
              type="hidden"
              id="eid"
              value={selectedEmployee ? selectedEmployee.id : ""}
            />
            <Grid item xs={6}>
              <h5>First Name</h5>
              <TextField
                id="ename"
                label="First Name"
                defaultValue={selectedEmployee ? selectedEmployee.name : ""}
                onChange={(e) => handleEditModalChange("name", e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Last Name</h5>
              <TextField
                id="elast_name"
                label="Last Name"
                defaultValue={
                  selectedEmployee ? selectedEmployee.last_name : ""
                }
                onChange={(e) =>
                  handleEditModalChange("last_name", e.target.value)
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Staff Id</h5>
              <TextField
                id="estaff_id"
                label="Staff Id"
                defaultValue={selectedEmployee ? selectedEmployee.staff_id : ""}
                onChange={(e) =>
                  handleEditModalChange("staff_id", e.target.value)
                }
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Email</h5>
              <TextField
                id="eemail"
                label="Email"
                fullWidth
                margin="normal"
                defaultValue={selectedEmployee ? selectedEmployee.email : ""}
                onChange={(e) => handleEditModalChange("email", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Phone</h5>
              <TextField
                id="ephone"
                label="Phone"
                fullWidth
                margin="normal"
                defaultValue={selectedEmployee ? selectedEmployee.phone : ""}
                onChange={(e) => handleEditModalChange("phone", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Date Of Birth</h5>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="edob"
                  value={selectedEmployee ? dayjs(selectedEmployee.dob) : null}
                  onChange={(date) => handleEditModalChange("dob", date)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <h5>Gender</h5>
              <RadioGroup
                row
                aria-label="gender"
                name="gender"
                defaultValue={selectedEmployee ? selectedEmployee.gender : ""}
                onChange={(e) =>
                  handleEditModalChange("gender", e.target.value)
                }
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={6}>
              <h5>Company</h5>
              <TextField
                id="ecompany"
                label="Company"
                fullWidth
                margin="normal"
                defaultValue={selectedEmployee ? selectedEmployee.company : ""}
                onChange={(e) =>
                  handleEditModalChange("company", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Department</h5>
              <TextField
                id="edepartment"
                label="Department"
                fullWidth
                margin="normal"
                defaultValue={
                  selectedEmployee ? selectedEmployee.department : ""
                }
                onChange={(e) =>
                  handleEditModalChange("department", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Designation</h5>
              <TextField
                id="edesignation"
                label="Designation"
                fullWidth
                margin="normal"
                defaultValue={
                  selectedEmployee ? selectedEmployee.designation : ""
                }
                onChange={(e) =>
                  handleEditModalChange("designation", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Office Shift</h5>
              <TextField
                id="eoffice_shift"
                label="Office Shift"
                fullWidth
                margin="normal"
                defaultValue={
                  selectedEmployee ? selectedEmployee.office_shift : ""
                }
                onChange={(e) =>
                  handleEditModalChange("office_shift", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>User Name</h5>
              <TextField
                id="eusername"
                label="User Name"
                fullWidth
                margin="normal"
                defaultValue={selectedEmployee ? selectedEmployee.username : ""}
                onChange={(e) =>
                  handleEditModalChange("username", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Role</h5>
              <TextField
                id="role"
                label="Role"
                fullWidth
                margin="normal"
                defaultValue={selectedEmployee ? selectedEmployee.role : ""}
                onChange={(e) => handleEditModalChange("role", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Date Of Joining</h5>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="edob_of_joining"
                  value={
                    selectedEmployee
                      ? dayjs(selectedEmployee.dob_of_joining)
                      : null
                  }
                  onChange={(date) =>
                    handleEditModalChange("dob_of_joining", date)
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <h5>Attendance Type</h5>
              <TextField
                id="eattendance_type"
                label="Attendance Type"
                fullWidth
                margin="normal"
                defaultValue={
                  selectedEmployee ? selectedEmployee.attendance_type : ""
                }
                onChange={(e) =>
                  handleEditModalChange("attendance_type", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleEditEmployee}
            variant="contained"
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </HrThemeProvideWrapper>
  );
}
