import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../Redux/Slice/User/UserListSlice";
import { DeleteUserList } from "../../Redux/Slice/User/UserDeleteSlice";
import dayjs from "dayjs";
import { addUser } from "../../Redux/Slice/User/UserAddSlice";
import { updateUser } from "../../Redux/Slice/User/UpdateUserSlice";
import { fetchCompany } from "../../Redux/Slice/Company/CompanyListSlice";
import { fetchDepartment } from "../../Redux/Slice/Department/DepartmentListSlice";
import { fetchDesignation } from "../../Redux/Slice/Designation/DesignationListSlice";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UserList() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const loading = useSelector((state) => state.user.isLoader);
  const error = useSelector((state) => state.user.isError);

  const [openModal, setOpenModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [department, setDepartment] = React.useState([]);
  const [companyData, setCompanyData] = React.useState([]);
  const [selectedDepartment ,setSelectedDepartment] = React.useState("Select Department");
  const [designtion, setDesigntion] = React.useState([]);
  const [selecteddesigntion ,setSelectedDesigntion] = React.useState("Select Designation");
  const [selectedCompany, setSelectedCompany] =
    React.useState("Select Company");
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchUser());
    // dispatch(fetchDepartment());
    fetchData();
    fetchDeprt();
    fetchDesig();
    // console.log(dept);
  }, [dispatch]);

  const fetchData = async () => {
    try {
      const response = await dispatch(fetchCompany());
      const dataFromResponse = response.payload.data || [];
      setCompanyData(dataFromResponse);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };
  const fetchDeprt = async () => {
    try {
      const response = await dispatch(fetchDepartment());
      const dataFromResponse = response.payload.data || [];
      setDepartment(dataFromResponse);
      setDepartment(dataFromResponse);
    } catch (error) {
      console.error("Error fetching department data:", error);
    }
  }; 
  const fetchDesig = async () => {
    try {
      const response = await dispatch(fetchDesignation());
      const dataFromResponse = response.payload.data || [];
      setDesigntion(response.payload.data);
      setDesigntion(dataFromResponse);
      setDesigntion(dataFromResponse);
      console.log(designtion)
    } catch (error) {
      console.error("Error fetching department data:", error);
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddUser = () => {
    const getValueById = (id) => {
      const element = document.getElementById(id);
      return element ? element.value : ""; // Return an empty string if element is null
    };

    const userData = {
      name: getValueById("name"),
      last_name: getValueById("last_name"),
      staff_id: getValueById("staff_id"),
      email: getValueById("email"),
      phone: getValueById("phone"),
      dob: getValueById("dob"),
      gender:
        document.querySelector('input[name="gender"]:checked')?.value || "",
      company: getValueById("company"),
      department: getValueById("department"),
      designation: getValueById("designation"),
      office_shift: getValueById("office_shift"),
      username: getValueById("username"),
      role: getValueById("role"),
      password: getValueById("password"),
      dob_of_joining: getValueById("dob_of_joining"),
      attendance_type: getValueById("attendance_type"),
      image: selectedImage,
    };

    dispatch(addUser(userData));

    handleCloseModal();
  };

  const handleDeleteUser = (userId) => {
    // Dispatch the DeleteUserList action with the userId
    dispatch(DeleteUserList(userId))
      .then(() => {
        // If deletion is successful, fetch the updated user data
        dispatch(fetchUser());
      })
      .catch((error) => {
        // Handle errors, if any
        console.error("Error deleting user:", error);
      });
  };

  const [editUserData, setEditUserData] = React.useState({
    id: "",
    name: "",
    last_name: "",
    staff_id: "",
    email: "",
    phone: "",
    dob: null, // Set to the appropriate initial value for DatePicker
    gender: "",
    company: "",
    department: "",
    designation: "",
    office_shift: "",
    username: "",
    role: "",
    password: "",
    dob_of_joining: null, // Set to the appropriate initial value for DatePicker
    attendance_type: "",
    image: null, // Set to the appropriate initial value
  });
  const handleEditModalChange = (field, value) => {
    setEditUserData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleOpenEditModal = (userData) => {
    if (userData) {
      setSelectedUser(userData);
      // Set the edit modal form data based on the selected user
      setEditUserData({
        id: userData.id,
        name: userData.name,
        last_name: userData.last_name,
        staff_id: userData.staff_id,
        email: userData.email,
        phone: userData.phone,
        dob: dayjs(userData.dob), // Convert to the appropriate format for DatePicker
        gender: userData.gender,
        company: userData.company,
        department: userData.department,
        designation: userData.designation,
        office_shift: userData.office_shift,
        username: userData.username,
        role: userData.role,
        password: userData.password,
        dob_of_joining: dayjs(userData.dob_of_joining), // Convert to the appropriate format for DatePicker
        attendance_type: userData.attendance_type,
        image: userData.image,
      });
    } else {
      setSelectedUser(null);
      setEditUserData({
        id: "",
        name: "",
        last_name: "",
        staff_id: "",
        email: "",
        phone: "",
        dob: null,
        gender: "",
        company: "",
        department: "",
        designation: "",
        office_shift: "",
        username: "",
        role: "",
        password: "",
        dob_of_joining: null,
        attendance_type: "",
        image: null,
      });
    }
    setOpenEditModal(true);
  };
  const handleEditImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        handleEditModalChange("image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleEditUser = () => {
    // Extract fields from editUserData
    const {
      id,
      name,
      last_name,
      staff_id,
      email,
      phone,
      dob,
      gender,
      company,
      department,
      designation,
      office_shift,
      username,
      role,
      password,
      dob_of_joining,
      attendance_type,
      image,
    } = editUserData;

    // Create the credentials object
    const credentials = {
      id,
      name,
      last_name,
      staff_id,
      email,
      phone,
      dob,
      gender,
      company,
      department,
      designation,
      office_shift,
      username,
      role,
      password, // You may want to handle the case where password is undefined
      dob_of_joining,
      attendance_type,
      image,
    };

    // Dispatch the updateUser action with the credentials
    dispatch(updateUser(credentials));

    // Close the edit modal
    handleCloseEditModal();
  };

  return (
    <div style={{ padding: "20px" }}>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: "20px", backgroundColor: "#4d5976" }}
        startIcon={<AddIcon />}
        onClick={handleOpenModal}
      >
        Add User
      </Button>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>

          <Grid container spacing={2}>

            <Grid item xs={6}>
              <h5>First Name</h5>
              <TextField
                id="name"
                label="First Name"
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Last Name</h5>
              <TextField
                id="last_name"
                label="Last Name"
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Staff Id</h5>
              <TextField
                id="staff_id"
                label="Staff Id"
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Email</h5>
              <TextField id="email" label="Email" fullWidth margin="normal" />
            </Grid>
            <Grid item xs={6}>
              <h5>Phone</h5>
              <TextField id="phone" label="Phone" fullWidth margin="normal" />
            </Grid>
            <Grid item xs={6}>
              <h5>Date Of Birth</h5>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker id="dob" />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <h5>Gender</h5>
              <RadioGroup row aria-label="gender" name="gender">
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              <h5>Company</h5>
              <Select
                id="company"
                label="Company"
                fullWidth
                margin="normal"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                <MenuItem value="Select Company" disabled>
                  Select Company
                </MenuItem>
                {companyData.map((company) => (
                  <MenuItem key={company.id} value={company.company}>
                    {company.company}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <h5>Department</h5>

              <Select
                id="department"
                label="department"
                fullWidth
                margin="normal"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
              >
                <MenuItem value="Select Department" disabled>
                  Select Department
                </MenuItem>
                {department.map((dept) => (
                  <MenuItem key={dept.id} value={dept.name}>
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>

            </Grid>
            <Grid item xs={6}>
              <h5>Designation</h5>
              <Select
                id="designation"
                label="designation"
                fullWidth
                margin="normal"
                value={selecteddesigntion}
                onChange={(e) => setSelectedDesigntion(e.target.value)}
              >
                <MenuItem value="Select Designatiion" disabled>
                  Select Designatiion
                </MenuItem>
                {designtion.map((dept) => (
                  <MenuItem key={dept.id} value={dept.name}>
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <h5>Office Shift</h5>
              <TextField
                id="office_shift"
                label="Office Shift"
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>User Name</h5>
              <TextField
                id="username"
                label="User Name"
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Role</h5>
              <TextField id="role" label="Role" fullWidth margin="normal" />
            </Grid>
            <Grid item xs={6}>
              <h5>Password</h5>
              <TextField
                id="password"
                label="Password"
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Date Of Joining</h5>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker id="dob_of_joining" />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <h5>Attendance Type</h5>
              <TextField
                id="attendance_type"
                label="Attendance Type"
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Image</h5>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="image-input"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="image-input">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ marginTop: "16px" }}
                >
                  Upload Image
                </Button>
              </label>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{ maxWidth: "100%", marginTop: "16px" }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddUser} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : user && Array.isArray(user.data) && user.data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>User</StyledTableCell>
                <StyledTableCell>Company</StyledTableCell>
                <StyledTableCell>Contact</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user.data.map((userData, index) => (
                <StyledTableRow key={userData.id}>

                  <StyledTableCell>{index + 1}</StyledTableCell>

                  <StyledTableCell>{userData.id}</StyledTableCell>

                  <StyledTableCell>{userData.name}</StyledTableCell>
                  <StyledTableCell>{userData.company}</StyledTableCell>
                  <StyledTableCell>{userData.phone}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenEditModal(userData)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenEditModal(userData)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteUser(userData.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ padding: "20px" }}>No User available</div>
      )}
      <Dialog open={openEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <input
              type="hidden"
              id="eid"
              value={selectedUser ? selectedUser.id : ""}
            />
            <Grid item xs={6}>
              <h5>First Name</h5>
              <TextField
                id="ename"
                label="First Name"
                defaultValue={selectedUser ? selectedUser.name : ""}
                onChange={(e) => handleEditModalChange("name", e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Last Name</h5>
              <TextField
                id="elast_name"
                label="Last Name"
                defaultValue={selectedUser ? selectedUser.last_name : ""}
                onChange={(e) =>
                  handleEditModalChange("last_name", e.target.value)
                }
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Staff Id</h5>
              <TextField
                id="estaff_id"
                label="Staff Id"
                defaultValue={selectedUser ? selectedUser.staff_id : ""}
                onChange={(e) =>
                  handleEditModalChange("staff_id", e.target.value)
                }
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Email</h5>
              <TextField
                id="eemail"
                label="Email"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.email : ""}
                onChange={(e) => handleEditModalChange("email", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Phone</h5>
              <TextField
                id="ephone"
                label="Phone"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.phone : ""}
                onChange={(e) => handleEditModalChange("phone", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Date Of Birth</h5>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="edob"
                  value={selectedUser ? dayjs(selectedUser.dob) : null}
                  onChange={(date) => handleEditModalChange("dob", date)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <h5>Gender</h5>
              <RadioGroup
                row
                aria-label="gender"
                name="gender"
                defaultValue={selectedUser ? selectedUser.gender : ""}
                onChange={(e) =>
                  handleEditModalChange("gender", e.target.value)
                }
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={6}>
              <h5>Company</h5>
              <TextField
                id="ecompany"
                label="Company"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.company : ""}
                onChange={(e) =>
                  handleEditModalChange("company", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Department</h5>
              <TextField
                id="edepartment"
                label="Department"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.department : ""}
                onChange={(e) =>
                  handleEditModalChange("department", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Designation</h5>
              <TextField
                id="edesignation"
                label="Designation"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.designation : ""}
                onChange={(e) =>
                  handleEditModalChange("designation", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Office Shift</h5>
              <TextField
                id="eoffice_shift"
                label="Office Shift"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.office_shift : ""}
                onChange={(e) =>
                  handleEditModalChange("office_shift", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>User Name</h5>
              <TextField
                id="eusername"
                label="User Name"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.username : ""}
                onChange={(e) =>
                  handleEditModalChange("username", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Role</h5>
              <TextField
                id="erole"
                label="Role"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.role : ""}
                onChange={(e) => handleEditModalChange("role", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Password</h5>
              <TextField
                id="epassword"
                label="Password"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.password : ""}
                onChange={(e) =>
                  handleEditModalChange("password", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Date Of Joining</h5>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="edob_of_joining"
                  value={
                    selectedUser ? dayjs(selectedUser.dob_of_joining) : null
                  }
                  onChange={(date) =>
                    handleEditModalChange("dob_of_joining", date)
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <h5>Attendance Type</h5>
              <TextField
                id="eattendance_type"
                label="Attendance Type"
                fullWidth
                margin="normal"
                defaultValue={selectedUser ? selectedUser.attendance_type : ""}
                onChange={(e) =>
                  handleEditModalChange("attendance_type", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <h5>Image</h5>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="eimage-input"
                type="file"
                onChange={handleEditImageChange}
              />
              <label htmlFor="eimage-input">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ marginTop: "16px" }}
                >
                  Upload Image
                </Button>
              </label>
              {editUserData.image && (
                <img
                  src={editUserData.image}
                  alt="Selected"
                  style={{ maxWidth: "100%", marginTop: "16px" }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="secondary">
            Cancel
          </Button>
          <Button color="primary" onClick={handleEditUser} variant="contained">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
