// Import necessary dependencies
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

// Async thunk for adding a company
export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (formData) => {
    const formDataToSend = new FormData();

    formDataToSend.append("company", formData.company);
    formDataToSend.append("type", formData.type);
    formDataToSend.append("reg_number", formData.reg_number);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("website", formData.website);
    formDataToSend.append("tax_number", formData.tax_number);
    formDataToSend.append("image", formData.image);

    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(`${apiUrl}/api/admin/company/addcompnay`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend,
      });

      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        return data;
      } else {
        throw new Error(data.errors); // Throw an error with the error message received
      }
    } catch (error) {
      toast.error(error.message); // Display the error message in a toast
      throw error; // Re-throw the error to be caught in the .catch() block
    }
  }
);

// Create a slice for the company state
const CompanyAddSlice = createSlice({
  name: "company",
  initialState: {
    company: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.addCase(addCompany.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(addCompany.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.company = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(addCompany.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

// Export the reducer
export default CompanyAddSlice.reducer;
