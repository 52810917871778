import React, { useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Select,
    MenuItem,
    Checkbox,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import HrThemeProvideWrapper from '../global/Hr/HrThemeProvideWrapper';
import { useState } from 'react';
import { fetchhrattandance } from '../../Redux/Slice/HrSection/HrAttandanceSlice';
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { SettingsInputSvideoRounded } from '@mui/icons-material';
import { saveClock_out } from '../../Redux/Slice/HrSection/HrAttandanceSlice';
import { HrSaveAttandance } from '../../Redux/Slice/HrSection/HrAttandanceSlice';
import AddIcon from "@mui/icons-material/Add";
import { salarySearchByName } from '../../Redux/Slice/HrSection/HrSalarySection';
const Hrattandance = () => {
    const dispatch = useDispatch();
    const { hrattandanceData, hrattandancestatus, } = useSelector((state) => state.hrattandance);
    const { searchdata, searchstatus } = useSelector((state) => state.hrsearch);
    const [daysOfWeekForMonth, setDaysOfWeekForMonth] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(1);
    const [currentDate, setCurrentDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [type, setType] = useState('factory_employee');
    const [openModal, setOpenModal] = React.useState(false);
    const [attandanceModel, setAttandanceModel] = React.useState(false);
    const [clock_out, setclock_out] = useState();
    const [userId, setUserId] = useState();
    const [user, setUser] = useState();
    const [search, setSearch] = useState();
    const [months, setMonths] = useState([
        { value: 1, name: 'January' },
        { value: 2, name: 'February' },
        { value: 3, name: 'March' },
        { value: 4, name: 'April' },
        { value: 5, name: 'May' },
        { value: 6, name: 'June' },
        { value: 7, name: 'July' },
        { value: 8, name: 'August' },
        { value: 9, name: 'September' },
        { value: 10, name: 'October' },
        { value: 11, name: 'November' },
        { value: 12, name: 'December' },
    ]);

    const [startYear, setStartYear] = useState(2020);
    const [endYear, setendYear] = useState(2030);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        date: '',
        clock_in: '',
        clock_out: '',
    });
    const [formErrors, setFormErrors] = useState({
        id: '',
        name: '',
        date: '',
        clock_in: '',
        clock_out: '',
    });
    useEffect(() => {
        // dispatch(hrsalary());
        dispatch(salarySearchByName());
    }, []);
    const searchByName = (data) => {
        dispatch(salarySearchByName(data));
    }
    useEffect(() => {
        setFormData({ id: searchdata?.id, name: searchdata?.name });
    }, [searchdata]);
    useEffect(() => {
        // const currentDate = new Date();
        const currentDateFormatted = new Date().toISOString().split('T')[0];

        setCurrentDate(currentDateFormatted);
    }, []);
    const yearArray = [];
    for (let year = startYear; year <= endYear; year++) {
        yearArray.push(year);
    }

    // return yearArray;
    const [days, setDays] = useState([]);

    const handleMonthChange = (event) => {
        const selectedValue = event.target.value;
        getDaysOfWeek(2024, selectedValue);
        setSelectedMonth(selectedValue);
    };

    const isSunday = (day) => {
        // Replace this logic with your actual logic to determine if the day is a Sunday
        // For example, assuming that day 1 is Sunday
        return day % 7 === 1;
    };

    function getDaysOfWeek(year, month) {
        const daysOfWeek = [];
        const daysNumber = [];
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);

        let i = 1;
        for (let day = firstDayOfMonth; day <= lastDayOfMonth; day.setDate(day.getDate() + 1)) {
            daysOfWeek.push(day.getDay()); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
            daysNumber.push(i);
            i++;
        }
        setDays(daysNumber);
        setDaysOfWeekForMonth(daysOfWeek);
    }
    useEffect(() => {
        const merge = {
            date: currentDate,
            end_date: endDate,
            user: user,
            type: type,
        };

        dispatch(fetchhrattandance(merge));
    }, [endDate, currentDate, user, type]);
    useEffect(() => {

        getDaysOfWeek(startYear, selectedMonth);

    }, []);
    const datemonth = (hrattandanceData) => {
        const att_date = hrattandanceData ? hrattandanceData : '';
        const dateObject = new Date(att_date);

        const day = dateObject.getDate();
        const month = dateObject.toLocaleString('default', { month: 'short' });
        const year = dateObject.getFullYear();

        const formattedDate = `${day} ${month} ${year}`;
        return formattedDate ? formattedDate : '--';
    }
    const handleChange = (event) => {
        setType(event.target.value);
    };
    const handleOpenModal = (data) => {
        setUserId(data.userId)
        setclock_out(data.clock_out);
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleCloseAttandanceModal = () => {
        setAttandanceModel(false);
    }
    const handleOpenAttandanceModal = () => {
        setAttandanceModel(true);
    }
    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    const saveAttandance = () => {
        if (formData.name === '') {
            setFormData({ ...formData, name: 'Name required' });
            return;
        } else if (formData.date === '') {
            setFormData({ ...formData, date: 'Date required' });
            return;
        } else if (formData.clock_in === '') {
            setFormData({ ...formData, clock_in: 'Clock In required' });
            return;
        } else if (formData.clock_out === '') {
            setFormData({ ...formData, clock_out: 'Clock Out required' });
            return;
        }
        else {
            dispatch(HrSaveAttandance(formData));
        }
    };


    const saveClock_out_time = () => {
        const merge = {
            userId: userId,
            clock_out: clock_out,
        }
        dispatch(saveClock_out(merge));

    }
    return (
        <HrThemeProvideWrapper>
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Add Clock out Time</DialogTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Clock out Time
                        </Typography>
                        <TextField
                            type='time'
                            name="clock_out"
                            fullWidth
                            margin="normal"
                            value={clock_out}
                            onChange={(e) => setclock_out(e.target.value)}
                            style={{ width: '200px' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={saveClock_out_time}>Save</Button>
                    </Grid>
                </Grid>
            </Dialog>

            {/* attandance model */}

            <Dialog open={attandanceModel} onClose={handleCloseAttandanceModal}>
                <DialogTitle>Add Attandance</DialogTitle>
                <Grid container spacing={2} style={{ padding: '35px' }}>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            Search
                        </Typography>

                        <TextField
                            type="search"
                            name="search"
                            value={search}
                            onChange={(e) => searchByName(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            Name
                        </Typography>

                        <TextField

                            name="name"
                            value={formData.name}
                            onChange={handleChange1}
                            error={!!formErrors.name}
                            helperText={formErrors.name}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            Date
                        </Typography>

                        <TextField

                            type="date"
                            name="date"
                            onChange={handleChange1}
                            value={formData.date}
                            error={!!formErrors.date}
                            helperText={formErrors.date}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            Clock IN
                        </Typography>

                        <TextField

                            type="time"
                            name="clock_in"
                            onChange={handleChange1}
                            value={formData.clock_in}
                            error={!!formErrors.clock_in}
                            helperText={formErrors.clock_in}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            Clock_out
                        </Typography>

                        <TextField

                            type="time"
                            name="clock_out"
                            onChange={handleChange1}
                            value={formData.clock_out}
                            error={!!formErrors.clock_out}
                            helperText={formErrors.clock_out}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={saveAttandance}>Save</Button>
                    </Grid>
                </Grid>
            </Dialog>

            <Card>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <CardHeader title="Attendance" />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            startIcon={<AddIcon />}
                            onClick={handleOpenAttandanceModal}
                        >
                            Add Attandance
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <CardContent >
                            <Typography variant="h6" gutterBottom>
                                Employee Type
                            </Typography>
                            <Select
                                id="select"
                                value={type}
                                onChange={handleChange}
                                style={{ width: '200px' }}
                            >
                                <MenuItem value="office_employee">Office Employee</MenuItem>
                                <MenuItem value="factory_employee">Factory Employee</MenuItem>
                            </Select>
                        </CardContent>
                    </Grid>
                    <Grid item xs={3}>
                        <CardContent >
                            <Typography variant="h6" gutterBottom>
                                Search
                            </Typography>
                            <TextField
                                value={user}

                                name="user"
                                onChange={(e) => setUser(e.target.value)}
                                // error={!!formErrors.amount}
                                // helperText={formErrors.amount}
                                required
                            />
                        </CardContent>
                    </Grid>
                    <Grid item xs={3} >
                        <CardContent >
                            <Typography variant="h6" gutterBottom>
                                Date from
                            </Typography>
                            <TextField
                                value={currentDate}
                                type="date"
                                name="currentDate"
                                onChange={(e) => setCurrentDate(e.target.value)}
                                // error={!!formErrors.amount}
                                // helperText={formErrors.amount}
                                required
                            />
                        </CardContent>
                    </Grid>
                    <Grid item xs={3} >
                        <CardContent >
                            <Typography variant="h6" gutterBottom>
                                Date To
                            </Typography>
                            <TextField
                                value={endDate}
                                type="date"
                                name="endDate"
                                onChange={(e) => setEndDate(e.target.value)}
                                // error={!!formErrors.amount}
                                // helperText={formErrors.amount}
                                required
                            />
                        </CardContent>
                    </Grid>
                </Grid>
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">S.No</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Emp Code</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="left">Clock In</TableCell>
                                    <TableCell align="left">Clock Out</TableCell>
                                    <TableCell align="left">Woking Hour</TableCell>
                                    <TableCell align="left">Over Time</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {hrattandancestatus === 'success'  && hrattandanceData?  (
                                    hrattandanceData.map((attendance) => (
                                        <TableRow key={attendance.id}>
                                            <TableCell align="left"> {attendance.id}</TableCell>
                                            <TableCell align="left"> {datemonth(attendance.attendance_date)}</TableCell>
                                            <TableCell align="left"> {attendance.emp_code}</TableCell>
                                            <TableCell align="left"> {attendance.name}</TableCell>
                                            <TableCell align="left"> {attendance.attendance_status == 1 ? 'Present' : 'Absent'}</TableCell>
                                            <TableCell align="left"> {attendance.clock_in}</TableCell>
                                            <TableCell align="left"> {attendance.clock_out}</TableCell>
                                            <TableCell align="left"> {attendance.total_work}</TableCell>
                                            <TableCell align="left"> {attendance.overtime}</TableCell>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleOpenModal(attendance)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={days.length + 2} align="center">
                                            no data
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>

                </CardContent>
            </Card>
        </HrThemeProvideWrapper>
    );
};

export default Hrattandance;
