import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { successmsg } from "../../../Helpers/successmsg";
import { handleApiError } from "../../../Helpers/handleApiError";
const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};
export const fetch_deduction = createAsyncThunk(
    "hr/fetch_deduction",

    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {
            const res = await fetch(`${apiUrl}/api/hr/deduction/` + credentials, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },

            });

            const final = await res.json();
           
         
            
            return final;
        }
        catch (erorr) {
            handleApiError(erorr, 'failed');
        }
    }




);
//2
export const add_hr_deduction = createAsyncThunk(
    "hr/add_deduction",

    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {
            const res = await fetch(`${apiUrl}/api/hr/deduction/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });

            const final = await res.json();
          
            successmsg(final.message); 
            if (final.status) {
                successmsg(final.message);
            }
            return final;
        }
        catch (erorr) {
            handleApiError(erorr, 'failed');
        }
    }




);
const DeductionSlice = createSlice({
    name: "deduction",
    initialState: {
        isAdding: false,
        isError: false,
        deducationdata: null,
        deductionstatus: 'pending',
    },
    reducers: {
        resetState: (state) => {
            state.isAdding = false;
            state.isError = false;
            state.deductionstatus = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetch_deduction.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(fetch_deduction.fulfilled, (state, action) => {
            state.deductionstatus = 'success';
            state.isAdding = false;
            state.deducationdata = action.payload.data;
        });
        builder.addCase(fetch_deduction.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
        //2
        builder.addCase(add_hr_deduction.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(add_hr_deduction.fulfilled, (state, action) => {
            state.deductionstatus = 'success';
            state.isAdding = false;
            state.deducationdata = action.payload.data;
        });
        builder.addCase(add_hr_deduction.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
    }
});
export default DeductionSlice.reducer;