import React, { useState, useEffect } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Collapse,
  IconButton,
} from "@mui/material";
import {
  HomeOutlined as HomeOutlinedIcon,
  PeopleOutlined as PeopleOutlinedIcon,
  MonetizationOn as MonetizationOnIcon,
  Schedule as ScheduleIcon,
  AttachMoney as AttachMoneyIcon,
  Apartment,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  MenuOutlined as MenuOutlinedIcon,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

const SidebarContent = ({
  isOpen,
  handleSubMenuClick,
  openSubMenu,
  getArrowIcon,
}) => {
  const location = useLocation();

  const sidebarData = [
    // {
    //   title: "Earning",
    //   icon: <MonetizationOnIcon />,
    //   items: [
    //     "Salary",
    //     "Loan Deduction",
    //     "Transport Allowance",
    //     "Food Allowance",
    //     "Mobile Allowance",
    //   ],
    // },
    {
      title: "Employees",
      icon: <PeopleOutlinedIcon />,
      items: [
        "Employee Details",
        // "Leave"
      ],
    },
    // {
    //   title: "Attendance",
    //   icon: <ScheduleIcon />,
    //   items: ["Details", "Attendance"],
    // },
    // {
    //   title: "Payroll",
    //   icon: <AttachMoneyIcon />,
    //   items: ["Salary Recept", "Generate Salary", "Attendance"],
    // },
    {
      title: "Organization",
      icon: <Apartment />,
      items: [
        "Company",
        "Department",
        // "Location",
        "Designation",
        // "Announcements",
        // "Company Policy",
      ],
    },
    // { title: "Provision", icon: <PeopleOutlinedIcon />, items: ["Provision"] },
  ];
  const pagesHeadingStyle = {
    color: "#161616", // Adjust the color as needed
    fontWeight: "400", // Adjust the font weight as needed
    fontSize: "1.2rem", // Adjust the font size as needed
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        marginTop: 50,
      }}
    >
      <Paper
        style={{
          flex: "1",
          padding: "20px",
          display: isOpen ? "block" : "none",
          overflowY: "auto",
          boxShadow: "none",
          transition: "margin-left 0.3s ease",
          marginLeft: isOpen ? 0 : "-300px",
        }}
      >
        <List>
          {/* <div
            style={{
              borderBottom: "1px solid #41ca56",
              paddingBottom: "15px",
            }}
          >
            <h3 style={pagesHeadingStyle}>Dashboard</h3>
            <ListItem
              button
              component={Link}
              to="/hr/dashboard" // Specify the correct path for the dashboard
              selected={location.pathname === "/hr/dashboard"}
            >
              <ListItemIcon>
                <HomeOutlinedIcon /> {/* Add the Home icon here */}
          {/* </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </div> */}
          <div
            style={{
              borderBottom: "1px solid #41ca56",
              paddingBottom: "15px",
            }}
          >
            <h3 style={pagesHeadingStyle}>Admin</h3>
            {sidebarData.map((category, index) => (
              <React.Fragment key={category.title}>
                <ListItem button onClick={() => handleSubMenuClick(index)}>
                  <ListItemIcon>{category.icon}</ListItemIcon>
                  <ListItemText primary={category.title} />
                  {isOpen && getArrowIcon(index)}
                </ListItem>
                {isOpen && (
                  <Collapse in={openSubMenu === index}>
                    <List>
                      {category.items.map((item) => (
                        <ListItem
                          button
                          key={item}
                          component={Link}
                          to={`/hr/${item.toLowerCase().replace(/\s/g, "-")}`}
                          selected={
                            location.pathname ===
                            `/hr/${item.toLowerCase().replace(/\s/g, "-")}`
                          }
                        >
                          <ListItemText primary={item} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </div>
        </List>
      </Paper>
    </div>
  );
};

const Sidebar = () => {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleSubMenuClick = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  const getArrowIcon = (index) => {
    return openSubMenu === index ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 760) {
        setDrawerOpen(false);
      } else {
        setDrawerOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <IconButton
        style={{ position: "absolute", top: 5, left: 25, zIndex: 1 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            color="primary"
            mb={2}
            style={{
              position: "absolute",
              left: "-6px",
              top: "1px",
            }}
          >
            <img
              src="https://alfalah.co/wp-content/uploads/2022/05/new-logo-transpernt.png" // Set the actual path to your image
              alt="Image"
              style={{
                width: "65px", // Set the width as per your requirement
              }}
            />
          </Typography>
          <div
            style={{
              background: isHovered ? "#1b2252" : "#2d388e",
              borderRadius: "10px",
              padding: "6px",
              transition: "background-color 0.3s ease",
              position: "absolute",
              left: "140px",
              top: "10px",
              color: "white",
            }}
            onClick={toggleDrawer}
          >
            <MenuOutlinedIcon />
          </div>
        </div>
      </IconButton>
      <SidebarContent
        isOpen={isDrawerOpen}
        handleSubMenuClick={handleSubMenuClick}
        openSubMenu={openSubMenu}
        getArrowIcon={getArrowIcon}
      />
    </>
  );
};

export default Sidebar;
