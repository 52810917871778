
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchAttandance } from '../../Redux/Slice/Attandance/AttandanceSlice';
import AdminThemeProviderWrapper from '../global/Admin/AdminThemeProviderWrapper';
const AttandanceList = () => {
    const dispatch = useDispatch();
    const { attandanceData, status } = useSelector((state) => state.attandance);
    useEffect(() => {
        dispatch(fetchAttandance());
    }, []);
    console.log("attannn data ", attandanceData);
    return (
        <AdminThemeProviderWrapper>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Attendance date</TableCell>
                            <TableCell>Clock In</TableCell>
                            <TableCell>	Clock In Ip</TableCell>
                            <TableCell>	Clock Out</TableCell>
                            <TableCell>	Clock Out Ip</TableCell>
                            <TableCell>	Clock In Out</TableCell>
                            <TableCell>	Time Late</TableCell>
                            <TableCell>	Early Leaving</TableCell>
                            <TableCell>	Overtime</TableCell>
                            <TableCell>	Total Work	</TableCell>
                            {/* <TableCell>	Total Rest</TableCell> */}
                            <TableCell>	Attendance Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {status == "success" ? (
                            attandanceData.map((row) => (

                                <TableRow key={row.id}>
                                    <TableCell>{row.attendance_date}</TableCell>
                                    <TableCell>{row.clock_in}</TableCell>
                                    <TableCell>{row.clock_in_ip}</TableCell>
                                    <TableCell>{row.clock_out}</TableCell>
                                    <TableCell>{row.clock_in_ip}</TableCell>
                                    <TableCell>{row.clock_out}</TableCell>
                                    <TableCell>{row.clock_out_ip}</TableCell>
                                    <TableCell>{row.clock_in_out}</TableCell>
                                    <TableCell>{row.time_late}</TableCell>
                                    <TableCell>{row.early_leaving}</TableCell>
                                    <TableCell>{row.overtime}</TableCell>
                                    <TableCell>{row.total_work}</TableCell>
                                    {/* <TableCell>{row.total_rest}</TableCell> */}
                                    <TableCell>{row.attendance_status}</TableCell>
                                </TableRow>
                            )
                            )
                        ) : ("no data")
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </AdminThemeProviderWrapper>
    )
}
export default AttandanceList;