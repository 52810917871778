import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const addUser = createAsyncThunk("auth/admin/addUser", async (credentials) => {
  const token = getTokenFromLocalStorage();

  const res = await fetch(`${apiUrl}/api/user/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
    },
    body: JSON.stringify(credentials),
  });
  const data = await res.json();

  if (data.status) {
    alert(data.message);
    return data;
    
  }
  throw new Error(data.message || "Login failed");
  
});

const UserAddSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.addCase(addUser.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.user = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(addUser.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default UserAddSlice.reducer;
