import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Award = () => {
  // const [modalOpen, setModalOpen] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  // const handleView = (rowId) => {
  //     setSelectedRow(rowId);
  //     setModalOpen(true);
  // };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Award Name</TableCell>
              <TableCell>Employee</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Row 1, Cell 1</TableCell>
              <TableCell>Row 1, Cell 2</TableCell>
              <TableCell>Row 1, Cell 3</TableCell>
              <TableCell>Row 1, Cell 3</TableCell>
              <TableCell>
                <IconButton color="secondary">
                  <VisibilityIcon />
                </IconButton>
                <IconButton color="error">
                  <EditIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Row 2, Cell 1</TableCell>
              <TableCell>Row 2, Cell 2</TableCell>
              <TableCell>Row 2, Cell 3</TableCell>
              <TableCell>Row 1, Cell 3</TableCell>
              <TableCell>
                <IconButton color="secondary">
                  <VisibilityIcon />
                </IconButton>
                <IconButton color="error">
                  <EditIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Award;
