import React, { useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
} from '@mui/material';
import EmpThemeProviderWrapper from "../global/Employee/EmployeeThemeProviderWrapper";
import { useDispatch, useSelector } from 'react-redux';
import { empsalryreceipt } from '../../Redux/Slice/EmployeeSection/EmployeeSalary';

const EmpViewSalary = () => {
    const dispatch = useDispatch();
    const { salary, status } = useSelector((state) => state.empsalary);

    useEffect(() => {
        dispatch(empsalryreceipt());
    }, []);

    return (
        <EmpThemeProviderWrapper>
            <Card style={{ height: '100%' }}>
                <Grid container spacing={2} style={{ height: '100%' }}>
                    <Grid item xs={12} md={4} style={{ height: '100%' }}>
                        <CardHeader title="Salary Receipt" />
                        <CardContent style={{ height: '100%' }}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead></TableHead>
                                    <TableBody>
                                        {status === 'success' &&
                                            salary &&
                                            Object.keys(salary).map((userId) => {
                                                const userData = salary[userId];

                                                return (
                                                    <div key={userId}>
                                                        <Typography variant="h6">{userData.name}</Typography>
                                                        <List>
                                                            <ListItem>
                                                                <ListItemText primary={`Total Salary: ${userData.total_salary}`} />
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemText primary={`Present: ${userData.present}`} />
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemText primary={`Total Absent: ${userData.total_absent}`} />
                                                            </ListItem>
                                                        </List>
                                                        <Divider />
                                                        <Typography variant="h6">Deductions</Typography>
                                                        <List>
                                                            {userData.salary_deducation.deductions.map((deduction, index) => (
                                                                <ListItem key={index}>
                                                                    <ListItemText
                                                                        primary={`Type: ${deduction.type}, Amount: ${deduction.amount}`}
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                        <Divider />
                                                        <Typography variant="h6">Net Salary</Typography>
                                                        <Typography>{`Net Salary: ${userData.salary_deducation.net_salary}`}</Typography>
                                                        <Divider />
                                                    </div>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </EmpThemeProviderWrapper>
    );
};

export default EmpViewSalary;
