import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { successmsg } from "../../../Helpers/successmsg";
import { handleApiError } from "../../../Helpers/handleApiError";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const fetch_emp_list = createAsyncThunk(
  "hr/fetch_deduction",

  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/hr/employee`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });

      const final = await res.json();

      return final;
    } catch (erorr) {
      handleApiError(erorr, "failed");
    }
  }
);
//2
export const add_emp = createAsyncThunk(
  "hr/add_deduction",

  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/hr/deduction/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });

      const final = await res.json();

      successmsg(final.message);
      if (final.status) {
        successmsg(final.message);
      }
      return final;
    } catch (erorr) {
      handleApiError(erorr, "failed");
    }
  }
);

// 3
export const import_user = createAsyncThunk(
  "hr/import_user",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    const formDataToSend = new FormData();

    // Append the image file to the FormData object
    formDataToSend.append("excel", credentials[0][1]);

    try {
      const res = await fetch(`${apiUrl}/api/hr/employee/import_user`, {
        method: "POST",
        headers: {
          // Remove "Content-Type" header or set it to "multipart/form-data"
          // Files are sent as multipart/form-data, not application/json
          "Authorization": `Bearer ${token}`, // Include authentication token in headers
          "Accept": "*/*" // Specify acceptable content types
        },
        body: formDataToSend,
      });

      const final = await res.json();

      successmsg(final.message);
      if (final.status) {
        successmsg(final.message);
      }
      return final;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);


const EmployeeList = createSlice({
  name: "deduction",
  initialState: {
    isAdding: false,
    isError: false,
    empdata: null,
    empstatus: "pending",
  },
  reducers: {
    resetState: (state) => {
      state.isAdding = false;
      state.isError = false;
      state.empstatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch_emp_list.pending, (state) => {
      state.isAdding = true;
      state.isError = false;
    });
    builder.addCase(fetch_emp_list.fulfilled, (state, action) => {
      state.empstatus = "success";
      state.isAdding = false;
      state.empdata = action.payload.data;
    });
    builder.addCase(fetch_emp_list.rejected, (state) => {
      state.isAdding = false;
      state.isError = true;
    });
    //2
    builder.addCase(add_emp.pending, (state) => {
      state.isAdding = true;
      state.isError = false;
    });
    builder.addCase(add_emp.fulfilled, (state, action) => {
      state.empstatus = "success";
      state.isAdding = false;
      state.empdata = action.payload.data;
    });
    builder.addCase(add_emp.rejected, (state) => {
      state.isAdding = false;
      state.isError = true;
    });

    // 3
    builder.addCase(import_user.pending, (state) => {
      state.isAdding = true;
      state.isError = false;
    });
    builder.addCase(import_user.fulfilled, (state, action) => {
      state.empstatus = "success";
      state.isAdding = false;
      state.empdata = action.payload.data;
    });
    builder.addCase(import_user.rejected, (state) => {
      state.isAdding = false;
      state.isError = true;
    });

  },
});
export default EmployeeList.reducer;
