import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  TextField,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Promotion = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleView = (rowId) => {
    setSelectedRow(rowId);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  //   const textFieldStyle = {
  //     width: "100%",
  //     marginBottom: "16px",
  //   };
  //   const buttonStyle = {
  //     width: "100%",
  //     margin: "8px 0",
  //   };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Employee</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Promotion Title</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Row 1, Cell 1</TableCell>
              <TableCell>Row 1, Cell 2</TableCell>
              <TableCell>Row 1, Cell 3</TableCell>
              <TableCell>Row 1, Cell 3</TableCell>
              <TableCell>
                <IconButton onClick={() => handleView(1)} color="secondary">
                  <VisibilityIcon />
                </IconButton>
                <IconButton color="error">
                  <EditIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Row 2, Cell 1</TableCell>
              <TableCell>Row 2, Cell 2</TableCell>
              <TableCell>Row 2, Cell 3</TableCell>
              <TableCell>Row 1, Cell 3</TableCell>
              <TableCell>
                <IconButton color="secondary">
                  <VisibilityIcon />
                </IconButton>
                <IconButton color="error">
                  <EditIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        style={modalStyle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 50000,
        }}
      >
        <Fade in={modalOpen}>
          <div>
            <Card>
              <CardContent>
                <h2>{selectedRow ? `Edit Row ${selectedRow}` : "New Row"}</h2>
                <form>
                  <TextField label="Field 1" name="field1" variant="outlined" />
                  <TextField label="Field 2" name="field2" variant="outlined" />
                  <TextField label="Field 3" name="field3" variant="outlined" />
                  <Button type="submit" variant="contained" color="primary">
                    {selectedRow ? "Save Changes" : "Add Row"}
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default Promotion;
