import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchRole = createAsyncThunk(
  "Role/fetchRole",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/api/role`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();

    return final;
  }
);

const RoleListSlice = createSlice({
  name: "role",
  initialState: {
    data: [],
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRole.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(fetchRole.fulfilled, (state, action) => {
      state.isLoader = false;
      state.data = action.payload.data;
      // console.log("Fetched Designation Dataaa:", action.payload.data);
    });
    builder.addCase(fetchRole.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default RoleListSlice.reducer;
