import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ViewEmployeeDetailsNav from "./ViewEmployeeDetailsNav";
import HrThemeProvideWrapper from "../../scenes/global/Hr/HrThemeProvideWrapper";
import { useDispatch, useSelector } from "react-redux";
import { employeeDetails } from "../../Redux/Slice/Employee/EmployeeListSlice";
import { saveAs } from "file-saver";
import {
  add_document,
  fetchDocument,
} from "../../Redux/Slice/Employee/EmployeeListSlice";
import { useParams } from "react-router-dom";

const Document = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.employee);
  const [doc_type, setdoc_type] = useState("Dl");
  const [formData, setFormData] = useState({
    id: id,
    type: "",
    lincense_no: "",
    lincense_valid: "",
    vechile_type: "",
    license_images: null,
    passport_no: "",
    passport_valid: "",
    passport_image: null,
    visa_no: "",
    visa_issue_date: "",
    visa_valid: "",
    visa_image: null,
    immigration_issue_date: "",
    immigration_valid: "",
    immigration_review: "",
    immigration_image: null,
    immigration_issue_country: "",
    civil_id: "",
    civil_id_valid: "",
    civil_id_upto: "",
    civil_image: null,
  });
  useEffect(() => {
    dispatch(fetchDocument(id));
  }, []);

  console.log("formData", formData);

  useEffect(() => {
    if (status == "success") {
      setFormData({
        id: id,
        lincense_no: data?.lincense_no,
        lincense_valid: data?.lincense_valid,
        vechile_type: data?.vechile_type,
        license_images: data?.license_images,
        passport_no: data?.passport_no,
        passport_valid: data?.passport_valid_upto,
        passport_image: data?.passport_image,
        visa_no: data?.visa_no,
        visa_issue_date: data?.visa_issue_date,
        visa_valid: data?.visa_valid,
        visa_image: data?.visa_image,
        immigration_issue_date: data?.immigration_issue_date,
        immigration_valid: data?.immigration_valid_upto,
        immigration_review: data?.immigration_review_date,
        immigration_image: data?.immigration_image,
        immigration_issue_country: data?.immigration_issue_country,
        civil_id: data?.civil_id,
        civil_id_valid: data?.civil_id_valid_from,
        civil_id_upto: data?.civil_id_valid_to,
        civil_image: data?.civil_image,
      });
    }
  }, [status, data]);

  const handleFileChange = (e) => {
    const file1 = e.target.files[0];
    const img = e.target.name;
    console.log("images", img);
    console.log("file1", file1);
    setFormData((prevData) => ({
      ...prevData,
      [img]: file1,
    }));
  };

  const handleInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e, type) => {
    e.preventDefault();
    const merge = {
      data: formData,
      form_type: type,
    };
    console.log(merge);

    await dispatch(add_document(merge));

    // Wait for 1 second (1000 milliseconds) before fetching updated data
    setTimeout(async () => {
      await dispatch(employeeDetails(id)); // Fetch updated data after successful update
      setFormData({
        ...formData,
        type: "",
      });
    }, 5000);
  };

  const downloaddata = (type, data) => {
    let imageUrl;
    let imageName;

    switch (type) {
      case "dl":
        imageUrl = data?.license_images;
        imageName = "License";
        break;
      case "passport":
        imageUrl = data?.passport_image;
        imageName = "Passport";
        break;
      case "visa":
        imageUrl = data?.visa_image;
        imageName = "Visa";
        break;
      case "immigration":
        imageUrl = data?.immigration_image;
        imageName = "Immigration";
        break;
      case "civil":
        imageUrl = data?.civil_image;
        imageName = "Civil ID";
        break;
      default:
        console.error("Invalid document type");
        return;
    }
    if (!imageUrl) {
      console.error(`Image URL for ${imageName} is missing or invalid`);
      return;
    } else {
      // const link = document.createElement('a');
      window.open(imageUrl, "_blank");
      // link.href = imageUrl;
      // link.download = `${imageName}.jpg`;

      // // Append the anchor element to the document body
      // document.body.appendChild(link);

      // // Simulate a click on the anchor element
      // link.click();

      // // Remove the anchor element from the document body
      // document.body.removeChild(link);
    }
  };

  return (
    <HrThemeProvideWrapper>
      <div style={containerStyle}>
        <div style={navStyle}>
          <ViewEmployeeDetailsNav />
        </div>
        <div style={detailsStyle}>
          {status === "success" && Object.keys(formData).length > 0 ? (
            <>
              <Card style={detailsStyle2}>
                <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
                  <CardHeader title="License" />

                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      <form>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              License No
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              name="lincense_no"
                              required
                              value={formData.lincense_no}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Date of Expiry
                            </Typography>
                            <TextField
                              type="date"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              name="lincense_valid"
                              value={formData.lincense_valid}
                              required
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              style={{ marginBottom: 20 }}
                            >
                              Vechile Type
                            </Typography>
                            <TextField
                              variant="outlined"
                              fullWidth
                              name="vechile_type"
                              required
                              value={formData.vechile_type}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              License Image
                            </Typography>
                            <TextField
                              type="file"
                              variant="outlined"
                              margin="normal"
                              name="license"
                              fullWidth
                              required
                              onChange={handleFileChange}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {data?.license_images ? (
                              <img
                                src={data?.license_images}
                                alt="Employee"
                                style={{ maxWidth: "50%", maxHeight: "50px" }}
                              />
                            ) : (
                              <>
                                {/* Render something else or leave it empty */}
                              </>
                            )}
                          </Grid>

                          <Grid item xs={2}>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={(e) => handleSubmit(e, "dl")}
                              sx={{
                                width: 200,
                                background:
                                  "linear-gradient(to right, #1b2365, #181f5e)",
                              }}
                            >
                              Update
                            </Button>
                          </Grid>
                          <Grid item xs={2} style={{ marginLeft: "80px" }}>
                            <Button
                              type="button"
                              variant="contained"
                              color="secondary"
                              fullWidth
                              onClick={() => downloaddata("dl", data)}
                              sx={{
                                width: 200,
                              }}
                            >
                              Download License
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Typography>
                  </CardContent>
                </div>
              </Card>
              <Card style={{ marginTop: "10px" }}>
                <CardHeader title="Passport" />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    <form>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Passport No
                          </Typography>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="passport_no"
                            required
                            value={formData.passport_no}
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Valid Upto
                          </Typography>
                          <TextField
                            type="date"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="lincense_valid"
                            value={formData.passport_valid}
                            required
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Passport Image
                          </Typography>
                          <TextField
                            type="file"
                            variant="outlined"
                            name="passport"
                            margin="normal"
                            fullWidth
                            required
                            onChange={handleFileChange}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {data?.passport_image ? (
                            <img
                              src={data?.passport_image}
                              alt="Employee"
                              style={{ maxWidth: "50%", maxHeight: "50px" }}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>

                        <Grid item xs={2}>
                          <Button
                            type="button"
                            variant="contained"
                            sx={{
                              width: 200,
                              background:
                                "linear-gradient(to right, #1b2365, #181f5e)",
                            }}
                            onClick={(e) => handleSubmit(e, "passport")}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Typography>
                </CardContent>
              </Card>
              <Card style={{ marginTop: "10px" }}>
                <CardHeader title="Visa" />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    <form>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Visa No
                          </Typography>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="visa_no"
                            required
                            value={formData.visa_no}
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Issue Date
                          </Typography>
                          <TextField
                            type="date"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="visa_issue_date"
                            value={formData.visa_issue_date}
                            required
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Valid Upto
                          </Typography>
                          <TextField
                            type="date"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="visa_valid"
                            value={formData.visa_valid}
                            required
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Visa Image
                          </Typography>
                          <TextField
                            type="file"
                            variant="outlined"
                            name="visa"
                            margin="normal"
                            fullWidth
                            required
                            onChange={handleFileChange}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {data?.visa_image ? (
                            <img
                              src={data?.visa_image}
                              alt="Employee"
                              style={{ maxWidth: "50%", maxHeight: "50px" }}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            type="button"
                            variant="contained"
                            sx={{
                              width: 200,
                              background:
                                "linear-gradient(to right, #1b2365, #181f5e)",
                            }}
                            onClick={(e) => handleSubmit(e, "visa")}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Typography>
                </CardContent>
              </Card>

              <Card style={{ marginTop: "10px" }}>
                <CardHeader title="Immigration" />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    <form>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Issue Date
                          </Typography>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="immigration_issue_date"
                            required
                            value={formData.immigration_issue_date}
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Valid Upto
                          </Typography>
                          <TextField
                            type="date"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="immigration_valid"
                            value={formData.immigration_valid}
                            required
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Review Date
                          </Typography>
                          <TextField
                            type="date"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="immigration_review"
                            value={formData.immigration_review}
                            required
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Immigration
                          </Typography>
                          <TextField
                            type="file"
                            variant="outlined"
                            margin="normal"
                            name="immigration"
                            fullWidth
                            required
                            onChange={handleFileChange}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Immigration issue country
                          </Typography>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            name="immigration_issue_country"
                            fullWidth
                            required
                            value={formData.immigration_issue_country}
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {data?.immigration_image ? (
                            <img
                              src={data?.immigration_image}
                              alt="Employee"
                              style={{ maxWidth: "50%", maxHeight: "50px" }}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            type="button"
                            variant="contained"
                            sx={{
                              width: 200,
                              background:
                                "linear-gradient(to right, #1b2365, #181f5e)",
                            }}
                            onClick={(e) => handleSubmit(e, "immigration")}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Typography>
                </CardContent>
              </Card>
              <Card style={{ marginTop: "10px" }}>
                <CardHeader title="Civit Id" />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    <form>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Civil Id No
                          </Typography>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="civil_id"
                            required
                            value={formData.civil_id}
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Valid from
                          </Typography>
                          <TextField
                            type="date"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="civil_id_valid"
                            value={formData.civil_id_valid}
                            required
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Valid Upto
                          </Typography>
                          <TextField
                            type="date"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="civil_id_upto"
                            value={formData.civil_id_upto}
                            required
                            onChange={handleInput}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" gutterBottom>
                            Civil Id Image
                          </Typography>
                          <TextField
                            type="file"
                            variant="outlined"
                            margin="normal"
                            name="civil"
                            fullWidth
                            required
                            onChange={handleFileChange}
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {data?.civil_image ? (
                            <img
                              src={data?.civil_image}
                              alt="Employee"
                              style={{ maxWidth: "50%", maxHeight: "50px" }}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            type="button"
                            variant="contained"
                            sx={{
                              width: 200,
                              background:
                                "linear-gradient(to right, #1b2365, #181f5e)",
                            }}
                            onClick={(e) => handleSubmit(e, "civil")}
                            sx={{
                              width: 200,
                              background:
                                "linear-gradient(to right, #1b2365, #181f5e)",
                            }}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Typography>
                </CardContent>
              </Card>
            </>
          ) : (
            <div style={{ padding: "20px" }}>No Data available</div>
          )}
        </div>
      </div>
    </HrThemeProvideWrapper>
  );
};

const containerStyle = {
  display: "flex",
  //   flexDirection: "row",
};

const navStyle = {
  flex: "0 0 17%",
};

const detailsStyle = {
  paddingLeft: "20px",
  width: "100%",
};
const detailsStyle2 = {
  background: `url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')`,
  backgroundSize: "cover",
};

export default Document;
