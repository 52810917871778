import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    console.log("Request Payload:", credentials);
    const res = await fetch(`${apiUrl}/api/admin/user/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(data.message || "Add Update User failed");
    }
    return data;
  }
);

const updateUserSlice = createSlice({
  name: "updateUser",
  initialState: {
    updateUser: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
    errorMessage: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
      state.errorMessage = null; // Reset the error message on pending
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.updateUser = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isLoader = false;
      state.isError = true;
      state.errorMessage = action.error.message; // Set the error message
    });
  },
});

export default updateUserSlice.reducer;
