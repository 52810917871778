import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";

import { salarySearchByName } from "../../../Redux/Slice/HrSection/HrSalarySection";
import { add_hr_deduction, fetch_deduction } from "../../../Redux/Slice/HrSection/DeductionSlice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import HrThemeProvideWrapper from "../../global/Hr/HrThemeProvideWrapper";
import Checkbox from "@mui/material/Checkbox";

const TraficDeduction = () => {
  const dispatch = useDispatch();
  const { deducationdata, deductionstatus, } = useSelector((state) => state.deduction);
  const { searchdata, hrsalaryStatus } = useSelector((state) => state.hrsearch);
  const [openModal, setOpenModal] = React.useState(false);
  const [search, setSearch] = useState();
  const [type, setType] = useState('trafic');
  const [checked, setChecked] = useState(false);
  const [formData, setFormData] = useState({
    user_id: searchdata?.id,
    name: searchdata?.name,
    email: searchdata?.email,
    salary: searchdata?.salary,
    emp_code: searchdata?.emp_code,
    amount: '',
    reason: '',
    ckeck: '',
    amount_de: '',

  });
  useEffect(() => {
    dispatch(fetch_deduction('trafic'));

  }, []);
  console.log(deducationdata);
  useEffect(() => {
    dispatch(salarySearchByName());
  }, []);
  useEffect(() => {
    setFormData({ user_id: searchdata?.id, name: searchdata?.name, email: searchdata?.email, salary: searchdata?.salary, emp_code: searchdata?.emp_code, type: type });
  }, [searchdata]);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [formErrors, setFormErrors] = useState({
    amount: "",
    emi: "",
  });
  const searchByName = () => {
    dispatch(salarySearchByName(search));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  };
  const handleForm = async (e) => {
    console.log(formData.amount);
    if (formData.amount === "") {

      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        amount: 'Amount Required'
      }));
      return false;
    }
    if (formData.amount == '') {
      alert();
    }
    else {
      const merge = {
        type: type,
        value: formData,
        user_id: formData.user_id,

      };
      dispatch(add_hr_deduction(formData)); // Assuming you have a function add_hr_deduction that accepts the correct parameters.
      setOpenModal(false);
    }



  };
  const changeEmi = (value) => {
   
    setFormData({ user_id: searchdata?.id, ckeck: value, name: searchdata?.name, email: searchdata?.email, salary: searchdata?.salary, emp_code: searchdata?.emp_code, type: type, amount: formData.amount });
  }

  return (
    <HrThemeProvideWrapper>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Trafic Deduction</DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6} >
            <TextField
              type="search"
              name="name"
              fullWidth
              margin="normal"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ marginTop: 24 }}
            />
          </Grid>
          <Grid item xs={2} style={{ marginTop: 2 }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              name="search"
              fullWidth
              startIcon={<Search />}
              aria-placeholder="search"
              onClick={searchByName}
              style={{ marginTop: 24 }}
            >
              {/* Button content */}
            </Button>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={2}>
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Name
                      </Typography>
                      <TextField
                        name="name"
                        fullWidth
                        margin="normal"
                        value={formData.name}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Amount
                      </Typography>
                      <TextField
                        name="amount"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={formData.amount}
                        onChange={handleChange}
                        error={!!formErrors.amount}
                        helperText={formErrors.amount}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Emi
                      </Typography>
                      <Checkbox
                        value="10"
                        onChange={(e) => changeEmi('10')}
                        checked={formData.check === '10'} // check if '10' is selected
                      />
                      10%
                      <Checkbox
                        value='100'
                        onChange={(e) => changeEmi('100')}
                        checked={formData.check === '100'} // check if '100' is selected
                      />
                      100%
                      <Checkbox
                        value='full'
                        onChange={(e) => changeEmi('full')}
                        checked={formData.check === 'full'} // check if 'full' is selected
                      />
                      As Define


                      {formData.ckeck == 'full' ? (
                        <>
                          <Typography variant="h6" gutterBottom>
                            Emi Amount
                          </Typography>
                          <TextField
                            name="amount_de"
                            fullWidth
                            margin="normal"
                            value={formData.amount_de}
                            onChange={handleChange}
                            required
                          />
                        </>
                      ) : (<></>)}

                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Reason
                      </Typography>
                      <TextField
                        name="reason"
                        fullWidth
                        margin="normal"
                        value={formData.reason}
                        onChange={handleChange}
                        required
                      />
                    </Grid>

                  </Grid>
                </form>
              </Typography>
            </CardContent>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleForm}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Card>
        <CardHeader title="Salary" />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              Fine User
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              Fine Company
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>

                <TableCell>Reason</TableCell>
                <TableCell>amount</TableCell>
                <TableCell> Date</TableCell>
                <TableCell> Salary</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deductionstatus == "success"
                ? deducationdata.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.reason}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                    <TableCell>{row.salary}</TableCell>

                  </TableRow>
                ))
                : "no data"}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </HrThemeProvideWrapper>
  );
};
export default TraficDeduction;
