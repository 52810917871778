import { React, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ViewEmployeeDetailsNav from "./ViewEmployeeDetailsNav";
import HrThemeProvideWrapper from "../../scenes/global/Hr/HrThemeProvideWrapper";
import TablePagination from "@mui/material/TablePagination";
import {
  fetch_work_experience,
  update_work_experience,
  addWorkExperience,
} from "../../Redux/Slice/Employee/EmployeeListSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
const WorkExperience = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.employee);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const loading = useSelector((state) => state.employee.isLoader);
  const error = useSelector((state) => state.employee.isError);
  const [formData, setFormData] = useState({
    id: "",
    user_id: id,
    name: "",
    post: "",
    form: "",
    to: "",
    document: "",
    description: "",
    image: null,
  });
  const [editData, setEditData] = useState({
    id: "",
    user_id: id,
    school: "",
    level: "",
    form: "",
    to: "",
    document: null,
    img: "",
    description: "",
    language: "",
  });

  useEffect(() => {
    dispatch(fetch_work_experience(id));
  }, [status]);
  const handleform = () => {
    dispatch(update_work_experience(editData)).then(async () => {
      await dispatch(fetch_work_experience(id));
      setEditModal(false);
    });
  };
  const handleAddform = () => {
    dispatch(addWorkExperience(formData)).then(async () => {
      await dispatch(fetch_work_experience(id));
      setOpenModal(false);
    });
  };
  const handleInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChangeedit = (event) => {
    const { name, value } = event.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      document: file,
    });
  };
  const handleFileChangeupdate = (e) => {
    const file = e.target.files[0];
    setEditData({
      ...editData,
      document: file,
    });
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditModal(false);
  };
  const handleEditModel = (
    id,
    user_id,
    company_name,
    post,
    from,
    to,
    img,
    description
  ) => {
    setEditData({
      id: id,
      user_id: user_id,
      name: company_name,
      post: post,
      form: from,
      to: to,
      img: img,
      description: description,
    });
    setEditModal(true);
  };
  const cellStyle = {
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#2d388e",
    color: "white",
  };
  const tableStyle = { fontSize: "13px", padding: "4px" };
  // ==========filter==========//

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Slice the data for pagination
  const slicedData =
    data?.length > 0
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];

  return (
    <HrThemeProvideWrapper>
      <div style={containerStyle}>
        <div style={navStyle}>
          <ViewEmployeeDetailsNav />
        </div>
        <div style={detailsStyle}>
          <Card style={detailsStyle2}>
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <CardHeader title="Experience" />
              <div style={detailsStyle}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginBottom: "20px",
                    background: "linear-gradient(to right, #1b2365, #181f5e)",
                  }}
                  startIcon={<AddIcon />}
                  onClick={handleOpenModal}
                >
                  Add Experience
                </Button>
                <Dialog open={openModal} onClose={handleCloseModal}>
                  <DialogTitle>Add Experience</DialogTitle>
                  <div
                    style={{
                      background:
                        "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                    >
                      <DialogContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                              Company Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              name="name"
                              required
                              value={formData.name}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Post
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              name="post"
                              fullWidth
                              required
                              value={formData.post}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Time Period(form)
                            </Typography>
                            <TextField
                              type="date"
                              name="form"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              value={formData.form}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              To
                            </Typography>
                            <TextField
                              type="date"
                              name="to"
                              variant="outlined"
                              margin="normal"
                              placeholder="to"
                              fullWidth
                              required
                              value={formData.to}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Document Upload
                            </Typography>
                            <TextField
                              type="file"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              onChange={handleFileChange}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                              Description
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              name="description"
                              onChange={handleInput}
                              value={formData.description}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </div>
                  </div>
                  <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleAddform}
                      sx={{
                        width: 100,
                        background:
                          "linear-gradient(to right, #1b2365, #181f5e)",
                      }}
                    >
                      Add
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* edit */}
                <Dialog open={editModal} onClose={handleCloseModal}>
                  <DialogTitle>Update Experience</DialogTitle>
                  <div
                    style={{
                      background:
                        "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                    >
                      <DialogContent>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Company Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              name="name"
                              required
                              value={editData.name}
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Post
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              name="post"
                              fullWidth
                              required
                              value={editData.post}
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Time Period(form)
                            </Typography>
                            <TextField
                              type="date"
                              name="form"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              value={editData.form}
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              To
                            </Typography>
                            <TextField
                              type="date"
                              name="to"
                              variant="outlined"
                              margin="normal"
                              placeholder="to"
                              fullWidth
                              required
                              value={editData.to}
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Document Upload
                            </Typography>
                            <TextField
                              type="file"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              onChange={handleFileChangeupdate}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <img
                              src={editData.img}
                              alt="document"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100px",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                              Description
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              name="description"
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              value={editData.description}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </div>
                  </div>
                  <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleform}
                      sx={{
                        width: 100,
                        background:
                          "linear-gradient(to right, #1b2365, #181f5e)",
                      }}
                    >
                      Add
                    </Button>
                  </DialogActions>
                </Dialog>
                {loading ? (
                  <div>Loading...</div>
                ) : error ? (
                  <div>Error: {error.message}</div>
                ) : data && data.length > 0 ? (
                  <div>
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={cellStyle}>Sl. No.</TableCell>
                            <TableCell style={cellStyle}>Company</TableCell>
                            <TableCell style={cellStyle}>Post</TableCell>
                            <TableCell style={cellStyle}>
                              Time Period(Form)
                            </TableCell>
                            <TableCell style={cellStyle}>
                              Time Period(To)
                            </TableCell>
                            <TableCell style={cellStyle}>Document</TableCell>

                            <TableCell style={cellStyle}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {slicedData.map((employeeData, index) => (
                            <TableRow key={employeeData.id}>
                              <TableCell style={tableStyle}>
                                {index + 1}
                              </TableCell>
                              <TableCell style={tableStyle}>
                                {employeeData.company_name}
                              </TableCell>
                              <TableCell style={tableStyle}>
                                {employeeData.post}
                              </TableCell>
                              <TableCell style={tableStyle}>
                                {employeeData.from}
                              </TableCell>
                              <TableCell style={tableStyle}>
                                {employeeData.to}
                              </TableCell>
                              <TableCell style={tableStyle}>
                                <img
                                  src={employeeData.img}
                                  alt="Employee"
                                  style={{ maxWidth: "50%", maxHeight: "50px" }}
                                />
                              </TableCell>
                              <TableCell style={tableStyle}>
                                <IconButton
                                  onClick={() =>
                                    handleEditModel(
                                      employeeData.id,
                                      employeeData.user_id,
                                      employeeData.company_name,
                                      employeeData.post,
                                      employeeData.from,
                                      employeeData.to,
                                      employeeData.img,
                                      employeeData.description
                                    )
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, -1]}
                      component="div"
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage="Rows per page"
                    />
                  </div>
                ) : (
                  <div style={{ padding: "20px" }}>No Employee available</div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </HrThemeProvideWrapper>
  );
};
const containerStyle = {
  display: "flex",
  //   flexDirection: "row",
};

const navStyle = {
  flex: "0 0 17%",
};

const detailsStyle = {
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "20px",
  width: "100%",
};
const detailsStyle2 = {
  background: `url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')`,
  backgroundSize: "cover",
};

export default WorkExperience;
