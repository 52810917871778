import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
export const DeleteDesignation = createAsyncThunk(
    "designation/delete",
    async (credentials, { getState }) => {
        const authToken = getState().auth.token;
        try {
            const res = await fetch(`${apiUrl}/api/admin/designation/delete/` + credentials, {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            });
            const data = await res.json();

            if (data.status) {
                successmsg(data.message);
            }
            else {
                handleApiError(data.errors, 'failed');
            }
            return data;
        }
        catch (error) {
            handleApiError(error, "failed");
        }
    }

);
const DeleteDesignationSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        isAuthenticated: false,
        isLoader: false,
        isError: false,
        token: null, // Add a token field to the state
    },
    extraReducers: (builder) => {
        builder.addCase(DeleteDesignation.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(DeleteDesignation.fulfilled, (state, action) => {
            state.isLoader = false;
            state.isAuthenticated = true;
            state.user = action.payload.data;
            state.token = action.payload.token;
        });
        builder.addCase(DeleteDesignation.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
    },
});
export default DeleteDesignationSlice.reducer;
