import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const fetchempsalary = createAsyncThunk(
    "employees/fetchempsalary",
    async (credentials) => {
        console.log("dgf");
        const token = getTokenFromLocalStorage();

        const res = await fetch(`${apiUrl}/api/admin/employee/advance/salary`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
            body: JSON.stringify(credentials),
        });
        const data = await res.json();
        // if (data.status) {
        //     alert(data.message);
        // }
        return data.data;
    }
);
// 2
export const approveEmpSalaryRequest = createAsyncThunk(
    "employees/approveEmpSalaryRequest",
    async (credentials) => {

        const token = getTokenFromLocalStorage();
        const formData = new FormData();
        formData.append('imgage', credentials.img);
        // console.log(credentials.img);

        const res = await fetch(`${apiUrl}/api/admin/employee/advance/salary/approve`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
            body: JSON.stringify(credentials),
        });
        const data = await res.json();
        if (data.status) {
            alert(data.message);
        }
        return data.data;
    }
);
// 3
export const fetcheloan = createAsyncThunk(
    "employees/fetcheloan",
    async (credentials) => {
        console.log("dgf");
        const token = getTokenFromLocalStorage();

        const res = await fetch(`${apiUrl}/api/admin/employee/loan`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
            // body: JSON.stringify(credentials),
        });
        const data = await res.json();
        // if (data.status) {
        //     alert(data.message);
        // }
        return data.data;
    }
);
// 4
export const approveEmploanRequest = createAsyncThunk(
    "employees/approveEmploanRequest",
    async (credentials) => {

        const token = getTokenFromLocalStorage();
        const formData = new FormData();
        formData.append('imgage', credentials.img);
        // console.log(credentials.img);

        const res = await fetch(`${apiUrl}/api/admin/employee/loan/approve`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
            body: JSON.stringify(credentials),
        });
        const data = await res.json();
        if (data.status) {
            alert(data.message);
        }
        return data.data;
    }
);
// 5
export const adminAddsalarys = createAsyncThunk(
    "Employee/adminAddsalary",
    async (credentials) => {
  
      const token = getTokenFromLocalStorage();
      console.log("add admin slice");
      const res = await fetch(`${apiUrl}/api/admin/employee/advance/salary/create`, {
        // method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });
  
      const final = await res.json();
  
      return final;
    }
  );
const AdvanceSalaryListSlice = createSlice({
    name: "empsalary",
    initialState: {
        empsalary: null,
        isLoader: false,
        isError: false,
        status: 'Pending',
        searchEmp: null,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchempsalary.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchempsalary.fulfilled, (state, action) => {
            state.status = "Success";
            state.isLoader = false;
            state.empsalary = action.payload;
            console.log("Company data:", state.empsalary);
        });
        builder.addCase(fetchempsalary.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        // 2
        builder.addCase(approveEmpSalaryRequest.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(approveEmpSalaryRequest.fulfilled, (state, action) => {
            state.status = "Success";
            state.isLoader = false;
            state.empsalary = action.payload;
            console.log("Company data:", state.empsalary);
        });
        builder.addCase(approveEmpSalaryRequest.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        //  3
        builder.addCase(fetcheloan.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetcheloan.fulfilled, (state, action) => {
            state.status = "Success";
            state.isLoader = false;
            state.empsalary = action.payload;
            console.log("Company data:", state.empsalary);
        });
        builder.addCase(fetcheloan.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        //4 
        builder.addCase(approveEmploanRequest.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(approveEmploanRequest.fulfilled, (state, action) => {
            state.status = "Success";
            state.isLoader = false;
            state.empsalary = action.payload;
            console.log("Company data:", state.empsalary);
        });
        builder.addCase(approveEmploanRequest.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        // 5
        builder.addCase(adminAddsalarys.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(adminAddsalarys.fulfilled, (state, action) => {
            state.status = "Success";
            state.isLoader = false;
            state.empsalary = action.payload;
            console.log("Company data:", state.empsalary);
        });
        builder.addCase(adminAddsalarys.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
    },
});

export default AdvanceSalaryListSlice.reducer;
