import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ViewEmployeeDetailsNav from "./ViewEmployeeDetailsNav";
import {
  EmergencyContact,
  EmergencyContact_update,
} from "../../Redux/Slice/Employee/EmployeeListSlice";
import { useDispatch, useSelector } from "react-redux";
import HrThemeProvideWrapper from "../../scenes/global/Hr/HrThemeProvideWrapper";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
const EmergencyDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.employee);
  const [selectedValue, setSelectedValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const cellStyle = {
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#2d388e",
    color: "white",
  };
  const tableStyle = { fontSize: "13px", padding: "4px" };
  const [formData, setFormData] = useState({
    id: id,
    relation: "",
    name: "",
    address: "",
    username: "",
    contact1: "",
    contact3: "",
    phone: "",
    city: "",
    state: "", // Set to the appropriate initial value for DatePicker
    country: "",
    zip_code: "",
    email: "",
  });
  const [errordata, seterrorData] = useState({
    id: "",
    relation: "",
    name: "",
    phone: "",
    city: "",
    state: "", // Set to the appropriate initial value for DatePicker
    country: "",
  });
  useEffect(() => {
    dispatch(EmergencyContact(id));
  }, [dispatch, data]);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const countries = [
    { value: "afghanistan" },
    { value: "armenia" },
    { value: "azerbaijan" },
    { value: "bahrain" },
    { value: "bangladesh" },
    { value: "bhutan" },
    { value: "brunei" },
    { value: "cambodia" },
    { value: "china" },
    { value: "cyprus" },
    { value: "georgia" },
    { value: "india" },
    { value: "indonesia" },
    { value: "iran" },
    { value: "iraq" },
    { value: "israel" },
    { value: "japan" },
    { value: "jordan" },
    { value: "kazakhstan" },
    { value: "kuwait" },
    { value: "kyrgyzstan" },
    { value: "laos" },
    { value: "lebanon" },
    { value: "malaysia" },
    { value: "maldives" },
    { value: "mongolia" },
    { value: "myanmar" },
    { value: "nepal" },
    { value: "north korea" },
    { value: "oman" },
    { value: "pakistan" },
    { value: "palestine" },
    { value: "philippines" },
    { value: "qatar" },
    { value: "saudi arabia" },
    { value: "singapore" },
    { value: "south korea" },
    { value: "sri lanka" },
    { value: "syria" },
    { value: "tajikistan" },
    { value: "thailand" },
    { value: "timor-leste" },
    { value: "turkey" },
    { value: "turkmenistan" },
    { value: "united arab emirates" },
    { value: "uzbekistan" },
    { value: "vietnam" },
    { value: "yemen" },

    // Add more countries as needed
  ];
  // useEffect(() => {
  //   if (status == "success") {
  //     setFormData({
  //       id: id,
  //       relation: data?.relation,
  //       name: data?.names,
  //       address: data?.address,
  //       username: data?.username,
  //       contact1: data?.contact1,
  //       contact3: data?.contact3,
  //       phone: data?.phones,
  //       city: data?.city,
  //       state: data?.state, // Set to the appropriate initial value for DatePicker
  //       country: data?.country,
  //       zip_code: data?.zip_code,
  //       email: data?.emails,
  //     });
  //   }
  // }, [data, status]);

  const handleInput = (e) => {
    // e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleform = () => {
    console.log(formData);
    try {
      // const errors = {};
      // if (!formData.relation) {
      //   errordata.relation = "relation is required";
      // }

      // if (!formData.name) {
      //   errordata.name = "Name is required";
      // }
      // if (!formData.phone) {
      //   errordata.phone = "Primary contct is required";
      // }
      // if (!formData.country) {
      //   errordata.country = "country  is required";
      // }
      // if (!formData.state) {
      //   errordata.state = "State is required";
      // }
      // if (!formData.city) {
      //   errordata.city = "city is required";
      // }
      // seterrorData(errors);

      // if (Object.keys(errors).length > 0) {
      //   return;
      // }
      dispatch(EmergencyContact_update(formData));
      setOpenModal(false);
      dispatch(EmergencyContact(id));
    } catch (error) {
      console.error("Error adding employee:", error);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      file: file,
    });
  };
  return (
    <HrThemeProvideWrapper>
      <div style={containerStyle}>
        <div style={navStyle}>
          <ViewEmployeeDetailsNav />
        </div>
        <div style={detailsStyle}>
          <Card style={detailsStyle2}>
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <CardHeader title="Emergency Contact" />
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginBottom: "20px",
                  background: "linear-gradient(to right, #1b2365, #181f5e)",
                }}
                startIcon={<AddIcon />}
                onClick={handleOpenModal}
              >
                Add Emergency Contact
              </Button>
              <CardContent>
                <Dialog open={openModal} onClose={handleCloseModal}>
                  <DialogTitle>Add Qualification</DialogTitle>
                  <div
                    style={{
                      background:
                        "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                    >
                      <DialogContent>
                        <Typography variant="body2" color="text.secondary">
                          <form>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  Relatives
                                </Typography>

                                <Select
                                  fullWidth
                                  margin="normal"
                                  name="relation"
                                  value={formData.relation || "Select relative"}
                                  onChange={handleInput}
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                >
                                  <MenuItem value="Select relative" disable>
                                    Select relative
                                  </MenuItem>
                                  <MenuItem value="fathet">Father</MenuItem>
                                  <MenuItem value="mother">Mother</MenuItem>
                                  <MenuItem value="son">Son</MenuItem>
                                  <MenuItem value="daughter">Daughter</MenuItem>
                                  <MenuItem value="uncle">Uncle</MenuItem>
                                  <MenuItem value="aunt">Aunt </MenuItem>
                                </Select>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  Name
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  name="name"
                                  value={formData.name}
                                  fullWidth
                                  onChange={handleInput}
                                  required
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                  error={!!errordata.name}
                                  helperText={errordata.name}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  Primary contact 1
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  name="phone"
                                  value={formData.phone}
                                  onChange={handleInput}
                                  fullWidth
                                  required
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                  error={!!errordata.phone}
                                  helperText={errordata.phone}
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  Primary contact 2
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  name="contact1"
                                  value={formData.contact1}
                                  fullWidth
                                  onChange={handleInput}
                                  required
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  Primary contact 3
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  name="contact3"
                                  value={formData.contact3}
                                  onChange={handleInput}
                                  fullWidth
                                  required
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  Email
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  name="email"
                                  value={formData.email}
                                  fullWidth
                                  onChange={handleInput}
                                  required
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                />
                              </Grid>
                              {/* <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom>
                      Person
                    </Typography>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      value={formData.name}
                      fullWidth
                      required
                    />
                  </Grid> */}

                              <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                  Adress
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  name="address"
                                  value={formData.address}
                                  onChange={handleInput}
                                  fullWidth
                                  multiline
                                  rows={3} // Adjust the number of rows as needed
                                  required
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  City
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  name="city"
                                  value={formData.city}
                                  onChange={handleInput}
                                  fullWidth
                                  required
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                  error={!!errordata.city}
                                  helperText={errordata.city}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  State
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  name="state"
                                  value={formData.state}
                                  onChange={handleInput}
                                  fullWidth
                                  required
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                  error={!!errordata.state}
                                  helperText={errordata.state}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  Zip Code
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  name="zip_code"
                                  value={formData.zip_code}
                                  onChange={handleInput}
                                  required
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>
                                  Country
                                </Typography>
                                <Select
                                  fullWidth
                                  margin="normal"
                                  name="country"
                                  value={formData.country || "Select Country"}
                                  onChange={(e) => handleInput(e)}
                                  sx={{
                                    backgroundColor: "rgba(212,216,252, 0.7)",
                                  }}
                                >
                                  <MenuItem value="Select Country" disabled>
                                    Select Country
                                  </MenuItem>
                                  {countries.map((country, index) => (
                                    <MenuItem key={index} value={country.value}>
                                      {country.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={12}>
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={handleform}
                                  sx={{
                                    width: 200,
                                    background:
                                      "linear-gradient(to right, #1b2365, #181f5e)",
                                  }}
                                >
                                  Update
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        </Typography>
                      </DialogContent>
                    </div>
                  </div>
                </Dialog>

                {/* Table Container */}
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={cellStyle}>Sl. No.</TableCell>
                        <TableCell style={cellStyle}>Relatives</TableCell>
                        <TableCell style={cellStyle}>Name</TableCell>
                        <TableCell style={cellStyle}>Phone</TableCell>
                        <TableCell style={cellStyle}>
                          Primary Contact 1
                        </TableCell>
                        <TableCell style={cellStyle}>
                          Primary Contact 2
                        </TableCell>
                        <TableCell style={cellStyle}>Email</TableCell>
                        <TableCell style={cellStyle}>Address</TableCell>
                        <TableCell style={cellStyle}>City</TableCell>
                        <TableCell style={cellStyle}>State</TableCell>
                        <TableCell style={cellStyle}>Zip Code</TableCell>
                        <TableCell style={cellStyle}>Country</TableCell>
                        {/* <TableCell style={cellStyle}>Action</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {status === 'success' && Array.isArray(data) && data.length > 0 ? (
                        data.map((employeeData, index) => (
                          <TableRow key={index}>
                            <TableCell style={tableStyle}>{index + 1}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.relation}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.names}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.phones}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.contact1}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.contact3}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.emails}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.address}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.city}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.state}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.zip_code}</TableCell>
                            <TableCell style={tableStyle}>{employeeData.country}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={12}
                            style={{ textAlign: "center", padding: "20px" }}
                          >
                            No data available
                          </TableCell>
                        </TableRow>
                      )}

                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </div>
          </Card>
        </div>
      </div>
    </HrThemeProvideWrapper>
  );
};
const containerStyle = {
  display: "flex",
  //   flexDirection: "row",
};

const navStyle = {
  flex: "0 0 17%",
};

const detailsStyle = {
  paddingLeft: "20px",
  width: "100%",
};
const detailsStyle2 = {
  background: `url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')`,
  backgroundSize: "cover",
};

export default EmergencyDetails;
