import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ViewEmployeeDetailsNav from "./ViewEmployeeDetailsNav";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import HrThemeProvideWrapper from "../../scenes/global/Hr/HrThemeProvideWrapper";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchbank,
  bank_info_update,
} from "../../Redux/Slice/Employee/EmployeeListSlice";
const BankAccount = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");
  const { data, status } = useSelector((state) => state.employee);
  const [formdata, setFormdata] = useState({
    accout_no: data?.accout_no,
    name: data?.customers_name,
    bank_code: data?.bank_code,
    bank_branch: data?.bank_branch,
    iban: data?.iban,
    id: id,
  });
  useEffect(() => {
    dispatch(fetchbank(id));
  }, []);
  useEffect(() => {
    if (status === "success") {
      setFormdata({
        accout_no: data?.accout_no,
        name: data?.customers_name,
        bank_code: data?.bank_code,
        bank_branch: data?.bank_branch,
        iban: data?.iban,
        id: id,
      });
    }
  }, [status, data, id]);
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleform = () => {
    console.log(formdata);
    dispatch(bank_info_update(formdata));
  };

  return (
    <HrThemeProvideWrapper>
      <div style={containerStyle}>
        <div style={navStyle}>
          <ViewEmployeeDetailsNav />
        </div>
        <div style={detailsStyle}>
          <Card style={detailsStyle2}>
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <CardHeader title="Bank Account" />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                          Account Number
                        </Typography>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="accout_no"
                          value={formdata.accout_no}
                          onChange={handleChange}
                          sx={{
                            backgroundColor: "rgba(212,216,252, 0.7)",
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                          Account Name
                        </Typography>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="name"
                          value={formdata.name}
                          onChange={handleChange}
                          sx={{
                            backgroundColor: "rgba(212,216,252, 0.7)",
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" gutterBottom>
                          Bank Code
                        </Typography>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="bank_code"
                          value={formdata.bank_code}
                          onChange={handleChange}
                          sx={{
                            backgroundColor: "rgba(212,216,252, 0.7)",
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" gutterBottom>
                          Bank Branch
                        </Typography>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="bank_branch"
                          onChange={handleChange}
                          sx={{
                            backgroundColor: "rgba(212,216,252, 0.7)",
                          }}
                          value={formdata.bank_branch}
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" gutterBottom>
                          IBAN
                        </Typography>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          name="iban"
                          value={formdata.iban}
                          fullWidth
                          onChange={handleChange}
                          sx={{
                            backgroundColor: "rgba(212,216,252, 0.7)",
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={4}></Grid>

                      <Grid item xs={2}>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={handleform}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Typography>
              </CardContent>
            </div>
          </Card>
        </div>
      </div>
    </HrThemeProvideWrapper>
  );
};
const containerStyle = {
  display: "flex",
  //   flexDirection: "row",
};

const navStyle = {
  flex: "0 0 17%",
};

const detailsStyle = {
  paddingLeft: "20px",
  width: "100%",
};
const detailsStyle2 = {
  background: `url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')`,
  backgroundSize: "cover",
};

export default BankAccount;
