import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import axios from "axios";
import { successmsg } from "../../../Helpers/successmsg";
import { handleApiError } from "../../../Helpers/handleApiError";
const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};
export const fetchhrattandance = createAsyncThunk(
    "hr/fetchhrattandance1",
    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {

            const res = await fetch(`${apiUrl}/api/hr/attandance/list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });

            const final = await res.json();

            return final;
        } catch (error) {

        }
    }
);
//2
export const saveClock_out = createAsyncThunk(
    "hr/saveClock_out",
    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {

            const res = await fetch(`${apiUrl}/api/hr/attandance/save_clock_out`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });

            const final = await res.json();
            if (final.status) {
                successmsg(final.message);
            }
            return final;
        } catch (error) {
            handleApiError(error, 'failed');
        }
    }
);
// 3
export const HrSaveAttandance = createAsyncThunk(
    "hr/saveAttandance",
    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {

            const res = await fetch(`${apiUrl}/api/hr/attandance/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });

            const final = await res.json();
            if (final.status) {
                successmsg(final.message);
            }
            else {
                handleApiError(final.errors, 'failed');
            }
            return final;
        } catch (error) {
            handleApiError(error, 'failed');
        }
    }
);

// 4
export const getHrPrevison = createAsyncThunk(
    "hr/getHrPrevison",
    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {

            const res = await fetch(`${apiUrl}/api/hr/employee/previson`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });

            const final = await res.json();
            if (final.status) {
                successmsg(final.message);
            }
            return final;
        } catch (error) {
            handleApiError(error, 'failed');
        }
    }
);

const HrAttandanceSlice = createSlice({
    name: "hrattanance",
    initialState: {
        isAdding: false,
        isError: false,
        hrattandanceData: [],
        hrattandancestatus: 'pending',
    },
    reducers: {
        resetState: (state) => {
            state.isAdding = false;
            state.isError = false;
            state.hrattandancestatus = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchhrattandance.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(fetchhrattandance.fulfilled, (state, action) => {
            state.hrattandancestatus = 'success';
            state.isAdding = false;
            state.hrattandanceData = action.payload.data;
            console.log("attandance data", state.hrattandanceData);
        });
        builder.addCase(fetchhrattandance.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
        // 2
        builder.addCase(saveClock_out.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(saveClock_out.fulfilled, (state, action) => {
            state.hrattandancestatus = 'success';
            state.isAdding = false;
            state.hrattandanceData = action.payload.data;
            console.log("attandance data", state.hrattandanceData);
        });
        builder.addCase(saveClock_out.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
        // 3
        builder.addCase(HrSaveAttandance.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(HrSaveAttandance.fulfilled, (state, action) => {
            state.hrattandancestatus = 'success';
            state.isAdding = false;
            state.hrattandanceData = action.payload.data;

        });
        builder.addCase(HrSaveAttandance.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
        // 4
        builder.addCase(getHrPrevison.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(getHrPrevison.fulfilled, (state, action) => {
            state.hrattandancestatus = 'success';
            state.isAdding = false;
            state.hrattandanceData = action.payload.data;

        });
        builder.addCase(getHrPrevison.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
    },
});

export default HrAttandanceSlice.reducer;
