import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { successmsg } from "../../../Helpers/successmsg";
import { handleApiError } from "../../../Helpers/handleApiError";
const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const addEmpAttandance = createAsyncThunk(
    "employees/addAttandance",
    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {
            const res = await fetch(`${apiUrl}/api/employee/attandance/clockIn`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });
            const data = await res.json();
            if (data.status) {

                successmsg(data.message);
            }
            return data;
        }
        catch (error) {
            handleApiError(error, 'failed');
        }
    }
); 
// 2
export const updateAttandance = createAsyncThunk(
    "employees/updateAttandance",
    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {
            const res = await fetch(`${apiUrl}/api/employee/attandance/update-record`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                // body: JSON.stringify(credentials),
            });
            const data = await res.json();
            // if (data.status) {
            //     successmsg(data.message);
            // }
            return data.data;
        }
        catch (error) {
            handleApiError(error, 'failed');
        }
    }

);
// 3
export const clockOutAttandance = createAsyncThunk(
    "employees/clockOutAttandance",
    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {
            const res = await fetch(`${apiUrl}/api/employee/attandance/clockOut`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });
            const data = await res.json();
            if (data.status) {
                successmsg(data.message);
            }
            return data;
        }
        catch (error) {
            handleApiError(error, 'failed');
        }
    }
);
// 4
export const getprevision = createAsyncThunk(
    "employees/getprevision",
    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {
            const res = await fetch(`${apiUrl}/api/employee/attandance/previson`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });
            const data = await res.json();
            if (data.status) {
                // successmsg(data.message);
            }
            return data;
        }
        catch (error) {
            handleApiError(error, 'failed');
        }
    }
);
// 5
export const emptotalattandance = createAsyncThunk(
    "employees/emptotalattandance",
    async (credentials) => {
        const token = getTokenFromLocalStorage();
        try {
            const res = await fetch(`${apiUrl}/api/employee/attandance/attandance/count`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });
            const data = await res.json();
            if (data.status) {
                // successmsg(data.message);
            }
            return data;
        }
        catch (error) {
            handleApiError(error, 'failed');
        }
    }
);


const AddAttandance = createSlice({
    name: "AddAttandance",
    initialState: {
        attandances: null,
        isAuthenticated: false,
        isLoader: false,
        isError: false,
        token: null,
        attandanceStatus: 'pending'
    },
    extraReducers: (builder) => {
        builder.addCase(addEmpAttandance.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(addEmpAttandance.fulfilled, (state, action) => {
            state.attandanceStatus = "success";
            state.isLoader = false;
            state.isAuthenticated = true;
            state.attandances = action.payload.data;
            // state.token = action.payload.token;
        });
        builder.addCase(addEmpAttandance.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        // 2
        builder.addCase(updateAttandance.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(updateAttandance.fulfilled, (state, action) => {
            state.attandanceStatus = "success";
            state.isLoader = false;
            state.isAuthenticated = true;
            state.attandances = action.payload;
            // state.token = action.payload.token;
            console.log(state.attandances);
        });
        builder.addCase(updateAttandance.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        //  3
        builder.addCase(clockOutAttandance.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(clockOutAttandance.fulfilled, (state, action) => {
            state.attandanceStatus = "success";
            state.isLoader = false;
            state.isAuthenticated = true;
            state.attandances = action.payload.data;
            // state.token = action.payload.token;
        });
        builder.addCase(clockOutAttandance.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        //4 
        builder.addCase(getprevision.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(getprevision.fulfilled, (state, action) => {
            state.attandanceStatus = "success";
            state.isLoader = false;
            state.isAuthenticated = true;
            state.attandances = action.payload.data;
            // state.token = action.payload.token;
        });
        builder.addCase(getprevision.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        //  5
        builder.addCase(emptotalattandance.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(emptotalattandance.fulfilled, (state, action) => {
            state.attandanceStatus = "success";
            state.isLoader = false;
            state.isAuthenticated = true;
            state.attandances = action.payload.data;
            // state.token = action.payload.token;
        });
        builder.addCase(emptotalattandance.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
    },
});

export default AddAttandance.reducer;
