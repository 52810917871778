import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ViewEmployeeDetailsNav from "./ViewEmployeeDetailsNav";
import { useDispatch, useSelector } from "react-redux";
import { FinancialsAdd } from "../../Redux/Slice/Employee/EmployeeListSlice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Financial } from "../../Redux/Slice/Employee/EmployeeListSlice";
import HrThemeProvideWrapper from "../../scenes/global/Hr/HrThemeProvideWrapper";
import { useParams } from "react-router-dom";
import { dateTimePickerTabsClasses } from "@mui/x-date-pickers";
const Financials = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.employee);
  const [Transport, setTransport] = useState(false);
  const [Transport_end, setTransport_end] = useState(false);
  const [Fuel, setFuel] = useState(false);
  const [Fuel_end, setFuel_end] = useState(false);
  const [Renumerartiion, setRenumerartiion] = useState(false);
  const [Renumerartiion_end, setRenumerartiion_end] = useState(false);
  const [Monetary, setMonetary] = useState(false);
  const [Monetary_end, setMonetary_end] = useState(false);
  const [updateId, setupdateId] = useState();
  const [Food, setFood] = useState(false);
  const [types, setType] = useState("transaction");
  const [Food_end, setFood_end] = useState(false);
  const loading = useSelector((state) => state.employee.isLoader);
  const error = useSelector((state) => state.employee.isError);
  // const transportStartDate = data[data.length - 1]?.transport_start;
  const [formData, setFormData] = useState({
    id: id,
    update_Id: "",
    salary: "0",
    transport: "0",
    fuels: "0",
    renumerartiions: "0",
    monetarys: "0",
    foods: "0",
    transport_start: "",
    transport_end: "",
    fuel_start: "",
    fuel_end: "",
    renumerartion_start: "",
    renumerartion_end: "",
    monetary_start: "",
    monetary_end: "",
    food_start: "",
    food_end: "",
    type: types,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // dispatch(updateEmployee(formData))
  };
  useEffect(() => {
    dispatch(Financial(id));
  }, [status]);
  const [formErrors, setFormErrors] = useState({
    id: id,
    update_Id: "",
    salary: "",
    transport: "",
    fuels: "",
    renumerartiions: "",
    monetarys: "",
    foods: "",
    transport_start: "",
    transport_end: "",
    fuel_start: "",
    fuel_end: "",
    renumerartion_start: "",
    renumerartion_end: "",
    monetary_start: "",
    monetary_end: "",
    food_start: "",
    food_end: "",
    end_date: "",
  });
  const resetFormErrors = () => {
    setFormErrors({
      id: id,
      update_Id: "",
      salary: "",
      transport: "",
      fuels: "",
      renumerartiions: "",
      monetarys: "",
      foods: "",
      transport_start: "",
      transport_end: "",
      fuel_start: "",
      fuel_end: "",
      renumerartion_start: "",
      renumerartion_end: "",
      monetary_start: "",
      monetary_end: "",
      food_start: "",
      food_end: "",
      end_date: "",
    });
  };

  // useEffect(() => {
  //   if (data.length > 0) {
  //     const lastData = data[data.length - 1];
  //     if (lastData.transport_start) {
  //       setTransport(true);
  //       setFormErrors(prevState => ({
  //         ...prevState,
  //         transport_start: lastData.transport_start || '',
  //       }));
  //     }
  //     if (lastData.fuel_start) {
  //       setFuel(true);
  //       setFormErrors(prevState => ({
  //         ...prevState,
  //         fuel_start: lastData.fuel_start || '',
  //       }));
  //     }
  //     if (lastData.renumerartiion_start) {
  //       setFormErrors(prevState => ({
  //         ...prevState,
  //         renumerartion_start: lastData.renumerartiion_start || '',
  //       }));
  //     }
  //     if (lastData.monetary_start) {
  //       setMonetary(true);
  //       setFormErrors(prevState => ({
  //         ...prevState,
  //         monetary_start: lastData.monetary_start || '',
  //       }));
  //     }
  //     if (lastData.food_start) {
  //       setFood(true);
  //       setFormErrors(prevState => ({
  //         ...prevState,
  //         food_start: lastData.food_start || '',
  //       }));
  //     }
  //   }
  // }, [data]);
  const lastTransportEnd = null;
  const handlesubmit = (e) => {
    if (formData.salary == "") {
      alert("salary required");
      return 0;
    } else if (formData.transport == "") {
      alert("transport required");
      return 0;
    } else if (formData.fuels == "") {
      alert("fuels required");
      return 0;
    } else if (formData.renumerartiions == "") {
      alert("renumerartiions required");
      return 0;
    } else if (formData.foods == "") {
      alert("foods required");
      return 0;
    }

    dispatch(FinancialsAdd(formData))
      .then(() => dispatch(Financial(id)))

      .catch((error) => {
        // Handle any errors here
        console.error("Error:", error);
      });
    handleCloseEditModal();
    resetFormErrors();
  };

  const openModel = (id, types, amount, date) => {
    setOpenEditModal(true);
    if (types == "transport") {
      setFormData((prevState) => ({
        ...prevState,
        update_Id: id,
        type: types,
        transport: amount,
      }));
      console.log(formData);
    }

    if (types == "foods") {
      setFormData((prevState) => ({
        ...prevState,
        update_Id: id,
        type: types,
        foods: amount,
      }));
    }
    if (types == "fuels") {
      setFormData((prevState) => ({
        ...prevState,
        update_Id: id,
        type: types,
        fuels: amount,
      }));
    }
    if (types == "renumerartiions") {
      setFormData((prevState) => ({
        ...prevState,
        update_Id: id,
        type: types,
        renumerartiions: amount,
      }));
    }
    if (types == "monetarys") {
      setFormData((prevState) => ({
        ...prevState,
        update_Id: id,
        type: types,
        monetarys: amount,
      }));
    }

    // if (fieldToUpdate) {
    //   setFormData(prevState => ({
    //     ...prevState,
    //     update_Id: id,
    //     type: types,
    //     [fieldToUpdate]: amount,

    //   }));
    // }
    // setOpenEditModal(false);
    // resetFormErrors();
    console.log(formData);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const cellStyle = {
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#2d388e",
    color: "white",
  };
  const tableStyle = { fontSize: "13px", padding: "4px" };
  const checkBoxStyle = { marginTop: "40px", marginLeft: "40px" };
  const checkBoxStyle2 = { marginTop: "40px" };

  return (
    <HrThemeProvideWrapper>
      <div style={containerStyle}>
        <div style={navStyle}>
          <ViewEmployeeDetailsNav />
        </div>
        <div style={detailsStyle}>
          {/* model  */}
          <Dialog open={openEditModal} onClose={handleCloseEditModal}>
            <DialogTitle>End Date</DialogTitle>
            <div
              style={{
                background:
                  "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
                backgroundSize: "cover",
              }}
            >
              <div
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  padding: "20px",
                }}
              >
                <DialogContent>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <TextField
                        type="date"
                        name="end_date"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        sx={{
                          backgroundColor: "rgba(212,216,252, 0.7)",
                        }}
                        value={formData.end_date}
                        onChange={handleInput}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        sx={{
                          width: 100,
                          background:
                            "linear-gradient(to right, #1b2365, #181f5e)",
                          color: "#fff",
                          textAlign: "center",
                        }}
                        onClick={handlesubmit}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
            </div>
          </Dialog>

          <Card style={detailsStyle2}>
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <CardHeader title="Contract" />

              <div
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  margin: "20px",
                  marginBottom: "20px",
                  borderRadius: "10px",
                }}
              >
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    <form method="post">
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="h6" gutterBottom>
                            Basic salary
                          </Typography>
                          <TextField
                            type="number"
                            variant="outlined"
                            margin="normal"
                            name="salary"
                            fullWidth
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                            value={formData.salary}
                            onChange={handleInput}
                            error={!!formErrors.tax_number}
                            helperText={formErrors.tax_number}
                          />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                          <Typography variant="h6" gutterBottom>
                            Transport
                          </Typography>
                          <TextField
                            type="number"
                            variant="outlined"
                            margin="normal"
                            name="transport"
                            fullWidth
                            required
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                            value={formData.transport}
                            onChange={handleInput}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {Transport ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                Start Date
                              </Typography>
                              <TextField
                                type="date"
                                name="transport_start"
                                variant="outlined"
                                margin="normal"
                                // value={formData.transport_start}
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }}
                                value={formData.transport_start}
                                onChange={handleInput}
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle}>
                              <Typography variant="h6" gutterBottom>
                                Start
                              </Typography>
                              <Checkbox onChange={() => setTransport(true)} />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {Transport_end ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                End Date
                              </Typography>
                              <TextField
                                type="date"
                                name="transport_end"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }}
                                value={formData.transport_end}
                                // required
                                onChange={handleInput}
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle2}>
                              <Typography variant="h6" gutterBottom>
                                Stop
                              </Typography>
                              <Checkbox
                                onChange={() => setTransport_end(true)}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h6" gutterBottom>
                            Fuel
                          </Typography>
                          <TextField
                            type="number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                            name="fuels"
                            value={formData.fuels}
                            onChange={handleInput}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {Fuel ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                Start Date
                              </Typography>
                              <TextField
                                type="date"
                                name="fuel_start"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }}
                                value={formData.fuel_start}
                                // required
                                onChange={handleInput}
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle}>
                              <Typography variant="h6" gutterBottom>
                                Start
                              </Typography>
                              <Checkbox onChange={() => setFuel(true)} />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {Fuel_end ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                End Date
                              </Typography>
                              <TextField
                                type="date"
                                name="fuel_end"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }}
                                value={formData.fuel_end}
                                // required
                                onChange={handleInput}
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle2}>
                              <Typography variant="h6" gutterBottom>
                                Stop
                              </Typography>
                              <Checkbox onChange={() => setFuel_end(true)} />
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" gutterBottom>
                            Renumerartiion
                          </Typography>
                          <TextField
                            type="number"
                            variant="outlined"
                            margin="normal"
                            name="renumerartiions"
                            fullWidth
                            required
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                            value={formData.renumerartiions}
                            onChange={handleInput}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {Renumerartiion ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                Start Date
                              </Typography>
                              <TextField
                                type="date"
                                name="renumerartion_start"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }}
                                value={formData.renumerartion_start}
                                onChange={handleInput}
                                // required
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle}>
                              <Typography variant="h6" gutterBottom>
                                Start
                              </Typography>
                              <Checkbox
                                onChange={() => setRenumerartiion(true)}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {Renumerartiion_end ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                End Date
                              </Typography>
                              <TextField
                                type="date"
                                name="renumerartion_end"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }}
                                value={formData.renumerartion_end}
                                onChange={handleInput}
                                // required
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle2}>
                              <Typography variant="h6" gutterBottom>
                                Stop
                              </Typography>
                              <Checkbox
                                onChange={() => setRenumerartiion_end(true)}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h6" gutterBottom>
                            Monetary
                          </Typography>
                          <TextField
                            type="number"
                            variant="outlined"
                            margin="normal"
                            name="monetarys"
                            fullWidth
                            required
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                            value={formData.monetarys}
                            onChange={handleInput}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {Monetary ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                Start Date
                              </Typography>
                              <TextField
                                type="date"
                                name="monetary_start"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }}
                                value={formData.monetary_start}
                                onChange={handleInput}
                                // required
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle}>
                              <Typography variant="h6" gutterBottom>
                                Start
                              </Typography>
                              <Checkbox onChange={() => setMonetary(true)} />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {Monetary_end ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                End Date
                              </Typography>
                              <TextField
                                type="date"
                                name="monetary_end"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }}
                                value={formData.monetary_end}
                                onChange={handleInput}
                                // required
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle2}>
                              <Typography variant="h6" gutterBottom>
                                Stop
                              </Typography>
                              <Checkbox
                                onChange={() => setMonetary_end(true)}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h6" gutterBottom>
                            Food
                          </Typography>
                          <TextField
                            type="number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            sx={{
                              backgroundColor: "rgba(212,216,252, 0.7)",
                            }}
                            name="foods"
                            value={formData.foods}
                            onChange={handleInput}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {Food ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                Start Date
                              </Typography>
                              <TextField
                                type="date"
                                name="food_start"
                                variant="outlined"
                                margin="normal"
                                value={formData.food_start}
                                onChange={handleInput}
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }} // required
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle}>
                              <Typography variant="h6" gutterBottom>
                                Start
                              </Typography>
                              <Checkbox onChange={() => setFood(true)} />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {Food_end ? (
                            <div>
                              <Typography variant="h6" gutterBottom>
                                End Date
                              </Typography>
                              <TextField
                                type="date"
                                name="food_end"
                                variant="outlined"
                                margin="normal"
                                value={formData.food_end}
                                onChange={handleInput}
                                fullWidth
                                required
                                sx={{
                                  backgroundColor: "rgba(212,216,252, 0.7)",
                                }} // required
                              />
                            </div>
                          ) : (
                            <div style={checkBoxStyle2}>
                              <Typography variant="h6" gutterBottom>
                                Stop
                              </Typography>
                              <Checkbox onChange={() => setFood_end(true)} />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            required
                            sx={{
                              width: 200,
                              background:
                                "linear-gradient(to right, #1b2365, #181f5e)",
                              marginTop: "30px",
                            }}
                            onClick={handlesubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Typography>
                </CardContent>
              </div>
              <div style={{ padding: "20px" }}>
                {/* Transport Table */}
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={cellStyle}>Sr.no</TableCell>
                          <TableCell style={cellStyle}>Salary</TableCell>
                          <TableCell style={cellStyle}>Transport</TableCell>
                          <TableCell style={cellStyle}>Start Date</TableCell>
                          <TableCell style={cellStyle}>End Date</TableCell>
                          <TableCell style={cellStyle}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {status === "success" &&
                        Array.isArray(data) &&
                        data.length > 0 ? (
                          data.map((employee, index) => (
                            <TableRow key={employee.id}>
                              {employee.transport > 0 ? (
                                <>
                                  <TableCell style={tableStyle}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.salary}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.transport}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.transport_start}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.transport_end}
                                  </TableCell>
                                  {lastTransportEnd !== null && (
                                    <TableCell style={tableStyle}>
                                      {lastTransportEnd}
                                    </TableCell>
                                  )}
                                  <TableCell style={tableStyle}>
                                    <IconButton
                                      onClick={() =>
                                        openModel(
                                          employee.id,
                                          "transport",
                                          employee.transport,
                                          employee.transport_start
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </TableCell>
                                </>
                              ) : (
                                <></>
                                // <TableCell colSpan={6} style={{ textAlign: "center", padding: "20px" }}>
                                //   {/* No data available */}
                                // </TableCell>
                              )}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>

                {/* Fuel Table */}
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={cellStyle}>Sr.no</TableCell>
                          <TableCell style={cellStyle}>Salary</TableCell>
                          <TableCell style={cellStyle}>Fuel</TableCell>
                          <TableCell style={cellStyle}>Start Date</TableCell>
                          <TableCell style={cellStyle}>End Date</TableCell>
                          <TableCell style={cellStyle}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {status === "success" &&
                        Array.isArray(data) &&
                        data.length > 0 ? (
                          data.map((employee, index) => (
                            <TableRow key={employee.id}>
                              {employee.fuel > 0 ? (
                                <>
                                  <TableCell style={tableStyle}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.salary}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.fuel}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.fuel_start}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.fuel_end}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    <IconButton
                                      onClick={() =>
                                        openModel(
                                          employee.id,
                                          "fuels",
                                          employee.fuel
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </TableCell>
                                </>
                              ) : (
                                <></>
                                // <TableCell colSpan={6} style={{ textAlign: "center", padding: "20px" }}>
                                //   {/* No data available */}
                                // </TableCell>
                              )}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>

                {/* Renumeration Table */}
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={cellStyle}>Sr.no</TableCell>
                          <TableCell style={cellStyle}>Salary</TableCell>
                          <TableCell style={cellStyle}>Renumeration</TableCell>
                          <TableCell style={cellStyle}>Start Date</TableCell>
                          <TableCell style={cellStyle}>End Date</TableCell>
                          <TableCell style={cellStyle}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {status === "success" &&
                        Array.isArray(data) &&
                        data.length > 0 ? (
                          data.map((employee, index) => (
                            <TableRow key={employee.id}>
                              {employee.renumeration > 0 ? (
                                <>
                                  <TableCell style={tableStyle}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.salary}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.renumeration}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.renumerartiion_start}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.renumerartiion_end}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    <IconButton
                                      onClick={() =>
                                        openModel(
                                          employee.id,
                                          "renumerartiions",
                                          employee.renumeration
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </TableCell>
                                </>
                              ) : (
                                <></>
                                // <TableCell colSpan={6} style={{ textAlign: "center", padding: "20px" }}>
                                //   {/* No data available */}
                                // </TableCell>
                              )}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>

                {/* Monetary Table */}
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={cellStyle}>Sr.no</TableCell>
                          <TableCell style={cellStyle}>Salary</TableCell>
                          <TableCell style={cellStyle}>Monetary</TableCell>
                          <TableCell style={cellStyle}>Start Date</TableCell>
                          <TableCell style={cellStyle}>End Date</TableCell>
                          <TableCell style={cellStyle}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {status === "success" &&
                        Array.isArray(data) &&
                        data.length > 0 ? (
                          data.map((employee, index) => (
                            <TableRow key={employee.id}>
                              {employee.monetary > 0 ? (
                                <>
                                  <TableCell style={tableStyle}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.salary}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.monetary}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.monetary_start}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.monetary_end}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    <IconButton
                                      onClick={() =>
                                        openModel(
                                          employee.id,
                                          "monetarys",
                                          employee.monetary
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </TableCell>
                                </>
                              ) : (
                                <></>
                                // <TableCell colSpan={6} style={{ textAlign: "center", padding: "20px" }}>
                                //   {/* No data available */}
                                // </TableCell>
                              )}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>

                {/* Food Table */}
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={cellStyle}>Sr.no</TableCell>
                          <TableCell style={cellStyle}>Salary</TableCell>
                          <TableCell style={cellStyle}>Food</TableCell>
                          <TableCell style={cellStyle}>Start Date</TableCell>
                          <TableCell style={cellStyle}>End Date</TableCell>
                          <TableCell style={cellStyle}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {status === "success" &&
                        Array.isArray(data) &&
                        data.length > 0 ? (
                          data.map((employee, index) => (
                            <TableRow key={employee.id}>
                              {employee.food > 0 ? (
                                <>
                                  <TableCell style={tableStyle}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.salary}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.food}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.food_start}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    {employee.food_end}
                                  </TableCell>
                                  <TableCell style={tableStyle}>
                                    <IconButton
                                      onClick={() =>
                                        openModel(
                                          employee.id,
                                          "foods",
                                          employee.food
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </TableCell>
                                </>
                              ) : (
                                <></>
                                // <TableCell colSpan={6} style={{ textAlign: "center", padding: "20px" }}>
                                //   {/* No data available */}
                                // </TableCell>
                              )}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </HrThemeProvideWrapper>
  );
};
const containerStyle = {
  display: "flex",
  //   flexDirection: "row",
};

const navStyle = {
  flex: "0 0 17%",
};

const detailsStyle = {
  paddingLeft: "20px",
  width: "100%",
};
const detailsStyle2 = {
  background: `url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')`,
  backgroundSize: "cover",
};

export default Financials;
