import AccountThemeProviderWrapper from "../global/Account/AccountThemeProviderWrapper";
import { accountLoanRequest } from "../../Redux/Slice/AccountSection/AccountAdvanceSalaryrequestSlice";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
} from "@mui/material";
import { accountLoanRequestapprove } from "../../Redux/Slice/AccountSection/AccountAdvanceSalaryrequestSlice";
const AccountLoanRequest = () => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = React.useState(false);
    const { account, acountstatus } = useSelector((state) => state.accountsalartRequst);
    const [formData, setFormData] = useState({
        // name1: hrsalaryByName.data?.name,
        id: '',
        type: '',
        transction_id: '',
    });
    useEffect(() => {
        dispatch(accountLoanRequest());

    }, []);
    const handleOpenModal = (id) => {
        setFormData({
            id: id,
        });
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

    };
    const formsumbmit = () => {
        console.log(formData);
        dispatch(accountLoanRequestapprove(formData));
    }
    return (
        <AccountThemeProviderWrapper>
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Add Employee/إضافة موظف</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h5>Payment Type</h5>
                            <TextField
                                name="type"

                                fullWidth
                                margin="normal"
                                // value={formData.type}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <h5>Transactiion ID</h5>
                            <TextField
                                name="transction_id"

                                fullWidth
                                margin="normal"
                                // value={formData.transction_id}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={formsumbmit}
                        color="primary"
                        variant="contained"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>amount</TableCell>
                            <TableCell> Date</TableCell>
                            <TableCell> Salary</TableCell>
                            <TableCell> Stauts</TableCell>
                            <TableCell> Transactiion Id</TableCell>
                            <TableCell> Type</TableCell>
                            <TableCell> Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {acountstatus == "success"
                            ? account.map((row) => (
                                row.status == 'Approve' ? (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.amount}</TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.salary}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.transacation_id}</TableCell>
                                        <TableCell>{row.payment_type}</TableCell>
                                        {row.transacation_id != null ? (<></>) : (
                                            <IconButton>
                                                <EditIcon onClick={() => handleOpenModal(row.id)} />
                                            </IconButton>
                                        )}
                                    </TableRow>
                                ) : (<></>)
                            ))
                            : "no data"}
                    </TableBody>
                </Table>
            </TableContainer>
        </AccountThemeProviderWrapper >
    )
}
export default AccountLoanRequest;