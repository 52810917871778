import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AddDesignation } from "../../Redux/Slice/Designation/AddDesignationSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InputBase from "@mui/material/InputBase";
import {
  Table,
  TableBody,
  TableContainer,
  Popover,
  Typography,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Checkbox,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchDesignation } from "../../Redux/Slice/Designation/DesignationListSlice";
import { DeleteDesignation } from "../../Redux/Slice/Designation/DeleteDesignationSlice";
import { fetchCompany } from "../../Redux/Slice/Company/CompanyListSlice";
import { fetchDepartment } from "../../Redux/Slice/Department/DepartmentListSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateDesignation } from "../../Redux/Slice/Designation/UpdateDesignationSlice";
import HrThemeProvideWrapper from "../global/Hr/HrThemeProvideWrapper";

export default function Designation() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const designation = useSelector((state) => state.designation);
  const loading = useSelector((state) => state.designation.isLoader);
  const error = useSelector((state) => state.designation.isError);
  const { department_data, depart_status } = useSelector(
    (state) => state.departmentlist
  );
  const [formData, setFormData] = useState({
    designation: "",
    company: "",
    department: "",
  });
  React.useEffect(() => {
    dispatch(fetchDesignation());
  }, [dispatch]);

  const designationData = designation.designation_data;

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    dispatch(fetchDepartment);
  }, []);
  const [companyData, setCompanyData] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCompany());
        const dataFromResponse = response.payload.data || [];
        setCompanyData(dataFromResponse);
      } catch (error) {
        console.error("Error fetching Company data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const [departmentData, setDepartmentData] = React.useState([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchDepartment());
        const dataFromResponse = response.payload.data || [];
        setDepartmentData(dataFromResponse);
      } catch (error) {
        console.error("Error fetching Department data:", error);
      }
    };

    fetchData();
  }, [dispatch]);
  const [formErrors, setFormErrors] = useState({
    designation: "",
    department: "",
    company: "",
  });
  const validateForm = () => {
    const errors = {};

    if (!formData.designation.trim()) {
      errors.designation = "Designation name is required";
    }

    if (!selectedCompany) {
      errors.company = "Company is required";
    }
    if (!selectedDepartment) {
      errors.department = "Department is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleForm = () => {
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      // Set errors and prevent form submission
      setFormErrors(errors);
      return;
    }

    // Add selectedLocation to the formData
    const formDataWithLocation = {
      ...formData,
      department: selectedDepartment,
      company: selectedCompany,
    };

    dispatch(AddDesignation(formDataWithLocation))
      .then(() => {
        // Reset form data and errors after successful addition
        setFormData({
          designation: "",
          department: "",
          company: "",
        });
        setFormErrors({});
        setSelectedDepartment(null);
        setSelectedCompany(null);

        // Reload data after successful addition
        dispatch(fetchDesignation());
        // toast.success("Designation added successfully!");

        // Close the modal
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding Designation:", error);
        toast.error("Error adding Designation. Please try again.");
      });
  };

  const handleDeleteDesignation = (Id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this Designation?"
    );
    if (isConfirmed) {
      dispatch(DeleteDesignation(Id))
        .then((result) => {
          // Handle success, if necessary
          console.log("Designation deleted successfully:", result);
          dispatch(fetchDesignation());
        })
        .catch((error) => {
          // Handle error, if necessary
          console.error("Error deleting Designation:", error);
        });
    }
  };
  // update
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedDesignation, setSelectedDesignation] = React.useState(null);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [editDesignationData, setEditDesignationData] = React.useState({
    id: "",
    designation: "",
    department: "",
    company: "",
  });
  console.log("editDesignationData", editDesignationData);
  const handleEditModalChange = (field, value) => {
    setEditDesignationData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    console.log(editDesignationData);
    // Dynamically update selectedCompany and selectedDepartment based on the current data
    if (field === "company") {
      setSelectedCompany(value);
    }
    if (field == "department") {
      setSelectedDepartment(value);
    }
  };

  const handleEditDesignation = () => {
    const { id, designation, department, company } = editDesignationData;

    const credentials = {
      id,
      designation,
      department,
      company,
    };

    // Include default values for dropdowns in the payload
    credentials.department = selectedDepartment || "";
    credentials.company = selectedCompany || "";

    dispatch(updateDesignation(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchDesignation());
        // toast.success("Designation updated successfully!");

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Designation:", error);
        toast.error("Error updating Designation. Please try again.");
      });
  };

  const handleOpenEditModal = (designationData) => {
    if (designationData) {
      setSelectedDesignation(designationData);
      setEditDesignationData({
        id: designationData.id,
        designation: designationData.designation,
        department: designationData.department,
        company: designationData.company,
      });

      // Set default values for dropdowns based on existing data
      setSelectedCompany(designationData.company);
      setSelectedDepartment(designationData.department);
      console.log(editDesignationData);
    } else {
      setSelectedDesignation(null);
      setEditDesignationData({
        id: "",
        designation: "",
        department: "",
        company: "",
      });
    }
    setOpenEditModal(true);
  };
  //  ====================== search==============================//
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = Array.isArray(designationData)
    ? designationData.filter((locationData) => {
        const nameLowerCase = locationData.designation?.toLowerCase() || "";
        const emailLowerCase = locationData.company?.toLowerCase() || "";
        return (
          nameLowerCase.includes(searchTerm) ||
          emailLowerCase.includes(searchTerm)
        );
      })
    : [];

  const slicedData =
    filteredData?.length > 0
      ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];
  console.log("slicedData", slicedData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMenuClick = (event, designationData) => {
    setAnchorEl(event.currentTarget);
    setSelectedDesignation(designationData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedDesignation(null);
  };

  const handleMenuItemClick = (action) => {
    handleClose();
    if (action === "delete") {
      handleDeleteDesignation(selectedDesignation.id);
    } else if (action === "edit") {
      handleOpenEditModal(selectedDesignation);
    }
  };

  const cellStyle = {
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#2d388e",
    color: "white",
  };
  const tableStyle = { fontSize: "13px", padding: "4px" };
  const modalText = { fontSize: "10px" };
  return (
    <HrThemeProvideWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",

          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            marginBottom: "20px",
            background: "linear-gradient(to right, #1b2365, #181f5e)",
          }}
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Designation
        </Button>
        <InputBase
          sx={{ ml: 2, marginBottom: "20px", width: "250px" }}
          placeholder="Search by Designation, Company"
          onChange={handleSearch}
          style={{
            color: "#000",
            paddingLeft: "10px",
            padding: "3px",
            paddingRight: "10px",
            borderRadius: "5px",
            backgroundColor: "#fff", // Background color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
            // outline: "none", // Remove outline on focus
            transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
          }}
        />
      </div>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Designation/إضافة تعيين</DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h5>Designation</h5>

                  <TextField
                    label="Designation"
                    fullWidth
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    margin="normal"
                    name="designation"
                    id="designation"
                    value={formData.designation}
                    onChange={handleChange}
                    error={!!formErrors.designation}
                    helperText={formErrors.designation}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Company</h5>
                  <Select
                    label="Company"
                    fullWidth
                    margin="normal"
                    value={selectedCompany || ""}
                    onChange={(e) => setSelectedCompany(e.target.value)}
                    displayEmpty
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    error={!!formErrors.department}
                    helperText={formErrors.department}
                  >
                    <MenuItem value="" disabled>
                      Select Company
                    </MenuItem>
                    {companyData.map((company) => (
                      <MenuItem key={company.id} value={company.company}>
                        {company.company}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.company && (
                    <p style={{ color: "red", fontSize: "10px" }}>
                      {formErrors.company}
                    </p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Department</h5>
                  <Select
                    label="Department"
                    fullWidth
                    margin="normal"
                    value={selectedDepartment || ""}
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                    displayEmpty
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    error={!!formErrors.company}
                    helperText={formErrors.company}
                  >
                    <MenuItem value="" disabled>
                      Select Department
                    </MenuItem>
                    {depart_status == "success" &&
                      department_data.map((department) => (
                        <MenuItem key={department.id} value={department.name}>
                          {department.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {formErrors.department && (
                    <p style={{ color: "red", fontSize: "10px" }}>
                      {formErrors.department}
                    </p>
                  )}
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleForm}
            sx={{
              width: 100,
              background: "linear-gradient(to right, #1b2365, #181f5e)",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : designationData && designationData.length > 0 ? (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table aria-label="customized table" id="employee_data">
              <TableHead>
                <TableRow>
                  <TableCell style={cellStyle}>Sl. No.</TableCell>
                  <TableCell style={cellStyle}>Designation/تعيين</TableCell>
                  <TableCell style={cellStyle}>Company/شركة</TableCell>
                  <TableCell style={cellStyle}>Department 1/القسم 1</TableCell>
                  <TableCell style={cellStyle}>Action/فعل</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {slicedData &&
                  slicedData.map((designationData, index) => (
                    <TableRow key={designationData.id}>
                      <TableCell style={tableStyle}>{index + 1}</TableCell>
                      <TableCell style={tableStyle}>
                        {designationData.designation}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {designationData.company}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {designationData.department}
                      </TableCell>

                      <TableCell style={tableStyle}>
                        <IconButton
                          onClick={(e) => handleMenuClick(e, designationData)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Typography sx={{ p: 1 }}>
                            <IconButton
                              style={{ color: "#2d388e" }}
                              onClick={() => handleMenuItemClick("edit")}
                            >
                              <EditIcon /> <h5 style={modalText}>Edit</h5>
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => handleMenuItemClick("delete")}
                            >
                              <DeleteIcon /> <h5 style={modalText}>Delete</h5>
                            </IconButton>
                          </Typography>
                        </Popover>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100, -1]}
            component="div"
            count={designation.designation_data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
          />
        </React.Fragment>
      ) : (
        <div style={{ padding: "20px" }}>No Employee available</div>
      )}

      <Dialog open={openEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>Update Designation/تحديث التعيين</DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <input
                  type="hidden"
                  id="eid"
                  value={editDesignationData ? editDesignationData.id : ""}
                />
                <Grid item xs={6}>
                  <h5>Designation</h5>
                  <TextField
                    id="designation"
                    defaultValue={
                      editDesignationData ? editDesignationData.designation : ""
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    onChange={(e) =>
                      handleEditModalChange("designation", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Company</h5>
                  <Select
                    fullWidth
                    margin="normal"
                    value={
                      editDesignationData ? editDesignationData.company : ""
                    }
                    // defaultValue={editDesignationData.selectedCompany || ""}
                    onChange={(e) =>
                      handleEditModalChange("company", e.target.value)
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Company
                    </MenuItem>
                    {companyData.map((company) => (
                      <MenuItem key={company.id} value={company.company}>
                        {company.company}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Department</h5>
                  <Select
                    fullWidth
                    margin="normal"
                    value={
                      editDesignationData ? editDesignationData.department : ""
                    }
                    onChange={(e) =>
                      handleEditModalChange("department", e.target.value)
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select department
                    </MenuItem>
                    {department_data &&
                      department_data.map((department) => (
                        <MenuItem key={department.id} value={department.name}>
                          {department.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleEditDesignation}
            variant="contained"
            sx={{
              width: 100,
              background: "linear-gradient(to right, #1b2365, #181f5e)",
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </HrThemeProvideWrapper>
  );
}
