import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hrloan,
  hrAddAdLoan,
  hrDownloadSalary,
} from "../../Redux/Slice/HrSection/HrSalarySection";
import { salarySearchByName } from "../../Redux/Slice/HrSection/SearchNameSlice";
import Search from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import HrThemeProvideWrapper from "../global/Hr/HrThemeProvideWrapper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import animationData from "../../animations/loader_animation0.json";
import Lottie from "lottie-react";

const HrSalaryAdvance = () => {
  const dispatch = useDispatch();
  const currentDate = new Date().toISOString().split('T')[0];
  const { hrsalaryData, hrsalaryStatus } = useSelector(
    (state) => state.hrsalary
  );
  const { searchdata, searchstatus } = useSelector((state) => state.hrsearch);
  const [openModal, setOpenModal] = React.useState(false);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    name: searchdata?.name,
    email: searchdata?.email,
    salary: searchdata?.salary,
    emp_code: searchdata?.emp_code,
    amount: "",
    date: "",
    emi: "",
    company: searchdata?.company, // Add the company property if applicable
  });

  useEffect(() => {
    dispatch(hrloan());
    dispatch(salarySearchByName());
  }, []);

  useEffect(() => {
    setFormData({
      id: searchdata?.id,
      name: searchdata?.name,
      email: searchdata?.email,
      salary: searchdata?.salary,
      emp_code: searchdata?.emp_code,
      company: searchdata?.company, // Add the company property if applicable
    });
  }, [searchdata]);

  const handleOpenModal = () => {
    if (searchdata != null) {
      setOpenModal(true);
    } else {
      toast.error("Please Select User", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [formErrors, setFormErrors] = useState({
    amount: "",
    date: "",
    emi: "",
  });

  const searchByName = () => {
    dispatch(salarySearchByName(search));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleForm = async (e) => {
    setFormErrors({
      amount: "",
      date: "",
      emi: "", // Add any other form fields that might have errors
    });

    // Check for validation
    if (formData.amount == "") {
     setFormErrors((prevErrors) => ({
        ...prevErrors,
        amount: "Amount Required",
      }));
      return;
    } else if (formData.date === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        date: "Date Required",
      }));
      return;
    }

    // Your other validations and logic here...

    if (formData.amount  >= formData.salary) {
      toast.error("Amount must be greater than salary", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    dispatch(hrAddAdLoan(formData));
    dispatch(hrloan());
  };

  const downloadExcelData = () => {
    dispatch(hrDownloadSalary(formData.id));
  };
  return (
    <HrThemeProvideWrapper>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Loan Amount</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Amount
              </Typography>
              <TextField
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                error={!!formErrors.amount}
                helperText={formErrors.amount}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Deduction Start
              </Typography>
              <TextField
                type="date"
                name="date"
                onChange={handleChange}
                value={formData.date}
                error={!!formErrors.date}
                helperText={formErrors.date}
                required
                inputProps={{ min: currentDate }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                EMI
              </Typography>
              <TextField
                type="number"
                name="emi"
                onChange={handleChange}
                value={formData.emi}
                error={!!formErrors.emi}
                helperText={formErrors.emi}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleForm}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Card>
        <CardHeader title="Salary" />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
              style={{ marginTop: '15px' }}
            >
              Add Loan
            </Button>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="search"
              name="name"
              fullWidth
              margin="normal"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ marginTop: '10px' }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              name="search"
              fullWidth
              startIcon={<Search />}
              aria-placeholder="search"
              onClick={searchByName}
              style={{ marginTop: '15px' }}

            />
          </Grid>
        </Grid>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            <form>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Name
                  </Typography>
                  <TextField
                    name="name"
                    fullWidth
                    margin="normal"
                    value={formData.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="email"
                    value={formData.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Salary
                  </Typography>
                  <TextField
                    name="salary"
                    fullWidth
                    margin="normal"
                    value={formData.salary}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Emp code
                  </Typography>
                  <TextField
                    name="emp_code"
                    fullWidth
                    margin="normal"
                    value={formData.emp_code}
                  />
                </Grid>
              </Grid>
            </form>
          </Typography>
        </CardContent>
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>amount</TableCell>
                <TableCell> Date</TableCell>
                <TableCell> Salary</TableCell>
                <TableCell> Stauts</TableCell>
                <TableCell> Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hrsalaryStatus == "success" && hrsalaryData
                ? hrsalaryData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.loan_amount}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.salary}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      <CloudDownloadIcon onClick={() => downloadExcelData()}
                      /></TableCell>
                  </TableRow>
                ))
                :
                <TableCell>
                <Lottie
                  animationData={animationData}
                  loop={true}
                  autoplay={true}
                  style={{ height: '100px', width: '100px', marginLeft: '500px' }}
                /></TableCell>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </HrThemeProvideWrapper>
  );
};
export default HrSalaryAdvance;
