import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
import axios from "axios";
const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};
export const fetchfoodAllowance = createAsyncThunk(
    "hr/fetchfoodAllowance",
    async (credentials) => {
        try {
            const token = getTokenFromLocalStorage();
            const res = await fetch(`${apiUrl}/api/hr/allowance/` + credentials, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },

            });

            const final = await res.json();

            // Log the response data in the console
            console.log("Response data:", final);

            return final;
        }
        catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
);
// 2
export const addfoodAllowance = createAsyncThunk(
    "hr/addfoodAllowance",
    async (credentials) => {
        try {
            const token = getTokenFromLocalStorage();
            const res = await fetch(`${apiUrl}/api/hr/allowance/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });

            const final = await res.json();
            if (final.status) {
                successmsg(final.message);
            }
            else {
                handleApiError(final.errors, 'Failed to fetch data. Please try again later.');
            }
            return final;
        }
        catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
);

// 3
export const fetchmobileAllowance = createAsyncThunk(
    "hr/fetchmobileAllowance",
    async (credentials) => {
        try {
            const token = getTokenFromLocalStorage();
            const res = await fetch(`${apiUrl}/api/hr/allowance/` + 'mobile', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },

            });

            const final = await res.json();

            // Log the response data in the console
            console.log("Response data:", final);

            return final;
        }
        catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
);
// 4
export const addfMobileAllowance = createAsyncThunk(
    "hr/addfMobileAllowance",
    async (credentials) => {
        try {
            const token = getTokenFromLocalStorage();
            const res = await fetch(`${apiUrl}/api/hr/allowance/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });

            const final = await res.json();
            if (final.data.status) {
                alert(final.data.message);
            }
            return final;
        }
        catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
);
// 5
export const fetchtransportAllowance = createAsyncThunk(
    "hr/fetchtransportAllowance",
    async (credentials) => {
        try {
            const token = getTokenFromLocalStorage();
            const res = await fetch(`${apiUrl}/api/hr/allowance/` + credentials, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },

            });

            const final = await res.json();

            // Log the response data in the console
            console.log("Response data:", final);

            return final;
        }
        catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
);
// 4
export const addftransportAllowance = createAsyncThunk(
    "hr/addftransportAllowance",
    async (credentials) => {
        try {
            const token = getTokenFromLocalStorage();
            const res = await fetch(`${apiUrl}/api/hr/allowance/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(credentials),
            });

            const final = await res.json();
            if (final.status) {
                successmsg(final.message);
            }
            else {
                handleApiError(final.errors, 'Failed to fetch data. Please try again later.');
            }
            return final;
        }
        catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
);
const HrAllowanceSlice = createSlice({
    name: "addEmployee",
    initialState: {
        isAdding: false,
        isError: false,
        allowancedata: null,
        allowancestatus: 'pending',
    },
    reducers: {
        resetState: (state) => {
            state.isAdding = false;
            state.isError = false;
            state.allowancedata = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchfoodAllowance.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(fetchfoodAllowance.fulfilled, (state, action) => {
            state.allowancestatus = 'success';
            state.isAdding = false;
            state.allowancedata = action.payload.data;
            // console.log("search data",state.searchdata);
        });
        builder.addCase(fetchfoodAllowance.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
        //2
        builder.addCase(addfoodAllowance.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(addfoodAllowance.fulfilled, (state, action) => {
            state.allowancestatus = 'success';
            state.isAdding = false;
            state.allowancedata = action.payload.data;
            // console.log("search data",state.searchdata);
        });
        builder.addCase(addfoodAllowance.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
        // 3
        builder.addCase(fetchmobileAllowance.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(fetchmobileAllowance.fulfilled, (state, action) => {
            state.allowancestatus = 'success';
            state.isAdding = false;
            state.allowancedata = action.payload.data;
            // console.log("search data",state.searchdata);
        });
        builder.addCase(fetchmobileAllowance.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
        //4
        builder.addCase(addfMobileAllowance.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(addfMobileAllowance.fulfilled, (state, action) => {
            state.allowancestatus = 'success';
            state.isAdding = false;
            state.allowancedata = action.payload.data;
            // console.log("search data",state.searchdata);
        });
        builder.addCase(addfMobileAllowance.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
        // 5
        builder.addCase(fetchtransportAllowance.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(fetchtransportAllowance.fulfilled, (state, action) => {
            state.allowancestatus = 'success';
            state.isAdding = false;
            state.allowancedata = action.payload.data;
            // console.log("search data",state.searchdata);
        });
        builder.addCase(fetchtransportAllowance.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
        //6
        builder.addCase(addftransportAllowance.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(addftransportAllowance.fulfilled, (state, action) => {
            state.allowancestatus = 'success';
            state.isAdding = false;
            state.allowancedata = action.payload.data;
            // console.log("search data",state.searchdata);
        });
        builder.addCase(addftransportAllowance.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
    },
});

export default HrAllowanceSlice.reducer;
