import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchUser = createAsyncThunk("User/fetchUser", async () => {
  const token = getTokenFromLocalStorage();

  const res = await fetch(`${apiUrl}/api/admin/user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const final = await res.json();
  return final;
});

const UserSlice = createSlice({
  name: "User",
  initialState: {
    data: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isLoader = false;
      state.data = action.payload;
      // console.log("User data:", action.payload);
    });
    builder.addCase(fetchUser.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default UserSlice.reducer;
