import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { addRole } from "../../Redux/Slice/Role/AddRoleSlice";
import { fetchRole } from "../../Redux/Slice/Role/RoleListSlice";
import { fetchUser } from "../../Redux/Slice/User/UserListSlice";
import { DeleteRole } from "../../Redux/Slice/Role/DeleteRoleList";
import { assignRole } from "../../Redux/Slice/Role/AssignRoleSlice";
import { UpdateRole } from "../../Redux/Slice/Role/UpdateRoleListSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "auto",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& > *": {
    width: "auto",
  },
}));

export default function RoleList() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const role = useSelector((state) => state.role);
  const loading = useSelector((state) => state.user.isLoader);
  const error = useSelector((state) => state.user.isError);
  const [openModal, setOpenModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState("");

  const [userId, setUserId] = React.useState();
  const [editrole, setEditRole] = React.useState('admmin');
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenEditModal(false);
  };


  const handleCheckboxChange = (index) => {

    const updatedCheckboxes = [...selectedCheckboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setSelectedCheckboxes(updatedCheckboxes);
  };



  React.useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchRole());
  }, [dispatch]);

  const handleRole = () => {
    const data = {
      name: getValueById("name"),
    }

    dispatch(addRole(data));
  }
  const getValueById = (id) => {
    const element = document.getElementById(id);
    return element ? element.value : "";
  };
  const handleDeleteUser = (id) => {
    dispatch(DeleteRole(id))
      .then(() => {
        dispatch(fetchRole());
        toast.success("Deleted successfully!"); // Show success notification
      })
      .catch((error) => {
        // Handle errors, if any
        console.error("Error deleting company:", error);
        toast.error("Error deleting company. Please try again."); // Show error notification
      });

  }
  const handleOpenEditModal = (data, name) => {
    setOpenEditModal(true);
    console.log(data, name);
    setEditRole(name);
    setUserId(data);

  }
  const UpdateRoles = () => {
    console.log(editrole);
    const mer = {
      name: editrole,
      id: userId,
    }
    dispatch(UpdateRole(mer));
    setOpenEditModal(false);
  }
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{ display: "flex", marginRight: "10px", marginBottom: "20px" }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#4d5976" }}
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Role(أضف دور)
        </Button>




      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Role(
          أضف دور)</DialogTitle>
        <DialogContent>
          <Grid container spacing={12}>
            <Grid item md={12}>
              <h5>Role(رول)</h5>
              <TextField id="name" label="Role" fullWidth margin="normal" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ marginTop: "16px" }}
            onClick={handleCloseModal}
          >
            Cancel(يلغي)
          </Button>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ marginTop: "16px" }}
            onClick={handleRole}
          >
            Send(يرسل)
          </Button>
          {/* <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button> */}
          {/* <Button onClick={handleRole} color="primary" variant="contained" />
          Send */}


        </DialogActions>
      </Dialog>
      <Dialog open={openEditModal} onClose={handleCloseModal}>
        <DialogTitle>Edit Role(تحرير الدور)</DialogTitle>
        <DialogContent>
          <Grid container spacing={12}>
            <Grid item md={12}>
              <h5>Role(رول)</h5>
              <TextField id="editrole" label="Role" fullWidth margin="normal" value={editrole} onChange={(e) => setEditRole(e.target.value)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ marginTop: "16px" }}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ marginTop: "16px" }}
            onClick={UpdateRoles}
          >
            Update
          </Button>

        </DialogActions>
      </Dialog>

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : user && Array.isArray(user.data) && user.data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Permission Role(دور الإذن)</StyledTableCell>
                <StyledTableCell>Description(وصف)</StyledTableCell>
                <StyledTableCell>Status(حالة)</StyledTableCell>
                <StyledTableCell>Create At(إنشاء في)</StyledTableCell>
                <StyledTableCell>Action(فعل)</StyledTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {role.data.map((roleData, index) => (
                <StyledTableRow key={roleData.index}>
                  <StyledTableCell>
                    <Checkbox
                      color="primary"
                      checked={selectedCheckboxes[index] || false}
                      onChange={() => handleCheckboxChange(index, roleData.id)}



                    />
                  </StyledTableCell>
                  <StyledTableCell>{roleData.name}</StyledTableCell>

                  <StyledTableCell>
                    {(() => {
                      if (roleData.role_id == 1) {
                        return " Assign Role Admin";
                      }
                      else if (roleData.role_id == 2) {
                        return "Assign Role User";
                      }
                      else {
                        return "Employee";
                      }
                    })()}
                  </StyledTableCell>
                  <StyledTableCell>
                    {"Active"}
                  </StyledTableCell>
                  <StyledTableCell>{roleData.created_at}</StyledTableCell>
                  <StyledTableCell>

                    <IconButton
                      color="error"
                      onClick={() => handleOpenEditModal(roleData.id, roleData.name)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteUser(roleData.id)}

                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ padding: "20px" }}>No data available</div>
      )}
    </div>
  );
}