import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { addCompany } from "../../Redux/Slice/Company/AddCompanySlice";
import { useDispatch, useSelector } from "react-redux";
import { DeleteCompanyList } from "../../Redux/Slice/Company/DeleteCompanySlice";
import TablePagination from "@mui/material/TablePagination";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Popover,
  Typography,
  Grid,
} from "@mui/material";
import { fetchCompany } from "../../Redux/Slice/Company/CompanyListSlice";
import { fetchLocation } from "../../Redux/Slice/Loaction/LoactionListSlice";
import HrThemeProvideWrapper from "../global/Hr/HrThemeProvideWrapper";
import { updateCompany } from "../../Redux/Slice/Company/CompanyUpdate";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InputBase from "@mui/material/InputBase";

export default function Company() {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company.companyData);
  const loading = useSelector((state) => state.company.isLoader);
  const error = useSelector((state) => state.company.isError);
  const [openModal, setOpenModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [formData, setFormData] = useState({
    company: "",
    type: "",
    treading_name: "",
    reg_number: "",
    phone: "",
    email: "",
    website: "",
    location: "dehli",
    tax_number: "",
    image: null,
  });
  const [editData, seteditData] = useState({
    id: "",
    company: "",
    type: "",
    // treading_name: "",
    reg_number: "",
    phone: "",
    email: "",
    website: "",
    location: "",
    tax_number: "",
    image: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditModal(false);
  };
  const editcompany = (data) => {
    seteditData({
      id: data.id,
      company: data.company,
      type: data.type,
      treading_name: data.treading_name,
      reg_number: data.reg_number,
      phone: data.phone,
      email: data.email,
      website: data.website,
      location: data.location,
      tax_number: data.tax_number,
      image: data.img,
    });
    setEditModal(true);
  };

  const [formErrors, setFormErrors] = useState({
    company: "",
    type: "",
    treading_name: "",
    reg_number: "",
    phone: "",
    email: "",
    website: "",
    location: "",
    tax_number: "",
    image: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleChangeedit = (e) => {
    const { name, value } = e.target;
    seteditData((preveditData) => ({
      ...preveditData,
      [name]: value,
    }));
  };
  const [previewImageUrl, setPreviewImageUrl] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));

    const imageUrl = URL.createObjectURL(file);
    setPreviewImageUrl(imageUrl);
  };
  const [editpreviewImageUrl, seteditPreviewImageUrl] = useState(null);

  const handleEditImageChange = (e) => {
    const file = e.target.files[0];
    seteditData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
    const imageUrledit = URL.createObjectURL(file);
    seteditPreviewImageUrl(imageUrledit);
  };
  const validateForm = () => {
    const errors = {};

    if (!formData.company.trim()) {
      errors.company = "Company name is required";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone No is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email No is required";
    }
    if (!formData.tax_number.trim()) {
      errors.tax_number = "Tax No is required";
    }
    if (!formData.image) {
      errors.image = "Image is required";
    }
    return errors;
  };

  const handleForm = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const fData = new FormData();

    // Add selectedLocation to the formData
    const formDataWithLocation = {
      ...formData,
      location: selectedLocation,
    };

    Object.keys(formDataWithLocation).forEach((key) => {
      fData.append(key, formDataWithLocation[key]);
    });

    // Append the image to the FormData
    // fData.append("image", formDataWithLocation.image);

    try {
      console.log(formData);
      await dispatch(addCompany(formData));

      // Reset form data and errors after successful addition
      setFormData({
        company: "",
        type: "",
        // treading_name: "",
        reg_number: "",
        phone: "",
        email: "",
        website: "",
        location: "",
        tax_number: "",
        image: "",
      });
      setFormErrors({});
      setSelectedLocation(null); // Reset selected location

      // Reload data after successful addition
      await dispatch(fetchCompany());
      // toast.success("Company added successfully!");

      // Close the modal
      handleCloseModal();
    } catch (error) {
      console.error("Error adding company:", error);
      // toast.error("Error adding company. Please try again.");
    }
  };

  React.useEffect(() => {
    dispatch(fetchCompany());
  }, [dispatch]);

  const handleDeleteCompany = (companyId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this company?"
    );
    if (isConfirmed) {
      dispatch(DeleteCompanyList(companyId))
        .then((result) => {
          // Handle success, if necessary
          console.log("Company deleted successfully:", result);
          dispatch(fetchCompany());
        })
        .catch((error) => {
          // Handle error, if necessary
          console.error("Error deleting Company:", error);
        });
    }
  };

  const handleCheckboxChange = (companyId) => {
    const isSelected = selectedRows.includes(companyId);
    if (isSelected) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== companyId)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, companyId]);
    }
  };

  // fetch location dropdown
  const [locationData, setLocationData] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchLocation());
        const dataFromResponse = response.payload.data || [];
        setLocationData(dataFromResponse);
      } catch (error) {
        console.error("Error fetching Department data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const updatecompanydata = () => {
    dispatch(updateCompany(editData))
      .then(() => dispatch(fetchCompany()))
      .then(handleCloseModal)
      .catch((error) => {
        console.error("Error updating company:", error);
        // Handle error, if necessary
      });
  };

  //  ====================== search==============================//
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = Array.isArray(company?.data)
    ? company.data.filter((companyData) => {
        const nameLowerCase = companyData.company?.toLowerCase() || "";
        const emailLowerCase = companyData.email?.toLowerCase() || "";
        return (
          nameLowerCase.includes(searchTerm) ||
          emailLowerCase.includes(searchTerm)
        );
      })
    : [];

  const slicedData =
    filteredData?.length > 0
      ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMenuClick = (event, companyData) => {
    setAnchorEl(event.currentTarget);
    setSelectedCompany(companyData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCompany(null);
  };

  const cellStyle = {
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#2d388e",
    color: "white",
  };
  const tableStyle = { fontSize: "13px", padding: "4px" };
  const modalText = { fontSize: "10px" };
  const handleMenuItemClick = (action) => {
    handleClose();
    if (action === "delete") {
      handleDeleteCompany(selectedCompany.id);
    } else if (action === "edit") {
      editcompany(selectedCompany);
    }
  };

  return (
    <HrThemeProvideWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",

          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            marginBottom: "20px",
            background: "linear-gradient(to right, #1b2365, #181f5e)",
          }}
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Company
        </Button>
        <InputBase
          sx={{ ml: 2, marginBottom: "20px" }}
          placeholder="Search by Name, Email"
          onChange={handleSearch}
          style={{
            color: "#000",
            paddingLeft: "10px",
            padding: "3px",
            paddingRight: "10px",
            borderRadius: "5px",
            backgroundColor: "#fff", // Background color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
            // outline: "none", // Remove outline on focus
            transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
          }}
        />
      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Company/ إضافة شركة</DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h5>Company Name</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="company"
                    id="company"
                    value={formData.company}
                    onChange={handleChange}
                    error={!!formErrors.company}
                    helperText={formErrors.company}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Company Name in Arabic</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="type"
                    id="type"
                    value={formData.type}
                    onChange={handleChange}
                    error={!!formErrors.type}
                    helperText={formErrors.type}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                <h5>Trading Name</h5>
                <TextField
                  label="Trading Name"
                  fullWidth
                  margin="normal"
                  name="treading_name"
                  id="treading_name"
                  value={formData.treading_name}
                  onChange={handleChange}
                  error={!!formErrors.treading_name}
                  helperText={formErrors.treading_name}
                />
              </Grid> */}
                <Grid item xs={6}>
                  <h5>Registration No</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="reg_number"
                    id="reg_number"
                    value={formData.reg_number}
                    onChange={handleChange}
                    error={!!formErrors.reg_number}
                    helperText={formErrors.reg_number}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Email</h5>
                  <TextField
                    id="email"
                    fullWidth
                    margin="normal"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Phone</h5>
                  <TextField
                    id="phone"
                    fullWidth
                    margin="normal"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    error={!!formErrors.phone}
                    helperText={formErrors.phone}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Website</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="website"
                    id="website"
                    value={formData.website}
                    onChange={handleChange}
                    error={!!formErrors.website}
                    helperText={formErrors.website}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Location</h5>
                  <Select
                    label="Location"
                    fullWidth
                    margin="normal"
                    value={selectedLocation || ""}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    error={!!formErrors.location}
                    helperText={formErrors.location}
                    displayEmpty
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Location
                    </MenuItem>
                    {locationData.map((location) => (
                      <MenuItem key={location.id} value={location.location}>
                        {location.location}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid> */}

                <Grid item xs={6}>
                  <h5>Tax Number</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="tax_number"
                    id="tax_number"
                    value={formData.tax_number}
                    onChange={handleChange}
                    error={!!formErrors.tax_number}
                    helperText={formErrors.tax_number}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Image Upload</h5>
                  <TextField
                    type="file"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={handleImageChange}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                  {formErrors.image && (
                    <p style={{ color: "red" }}>{formErrors.image}</p>
                  )}
                </Grid>

                {previewImageUrl && (
                  <img
                    src={previewImageUrl}
                    alt="Selected"
                    style={{ maxWidth: "25%", marginTop: "16px" }}
                  />
                )}
              </Grid>
            </DialogContent>
          </div>
        </div>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleForm}
            sx={{
              width: 100,
              background: "linear-gradient(to right, #1b2365, #181f5e)",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {/* edit model */}
      <Dialog open={editModal} onClose={handleCloseModal}>
        <DialogTitle>Update Company/ إضافة شركة</DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h5>Company</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="company"
                    value={editData.company}
                    onChange={handleChangeedit}
                    error={!!formErrors.company}
                    helperText={formErrors.company}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Company Type</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="type"
                    value={editData.type}
                    onChange={handleChangeedit}
                    error={!!formErrors.type}
                    helperText={formErrors.type}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Registration No</h5>
                  <TextField
                    label="Registration No"
                    fullWidth
                    margin="normal"
                    name="reg_number"
                    id="reg_number"
                    value={editData.reg_number}
                    onChange={handleChangeedit}
                    // error={!!formErrors.reg_number}
                    // helperText={formErrors.reg_number}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Email</h5>
                  <TextField
                    label="Enter Email"
                    id="email"
                    fullWidth
                    margin="normal"
                    name="email"
                    value={editData.email}
                    onChange={handleChangeedit}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    // error={!!formErrors.email}
                    // helperText={formErrors.email}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Phone</h5>
                  <TextField
                    id="phone"
                    label="Phone"
                    fullWidth
                    margin="normal"
                    name="phone"
                    value={editData.phone}
                    onChange={handleChangeedit}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    // error={!!formErrors.phone}
                    // helperText={formErrors.phone}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Website</h5>
                  <TextField
                    label="Website"
                    fullWidth
                    margin="normal"
                    name="website"
                    id="website"
                    value={editData.website}
                    onChange={handleChangeedit}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    // error={!!formErrors.website}
                    // helperText={formErrors.website}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Location</h5>
                  <select
                    style={{
                      width: "260px",
                      height: "50px",
                      backgroundColor: "rgba(212,216,252, 0.7)",
                      borderRadius: "3px",
                    }}
                    defaultValue={editData.location}
                    onChange={handleChange}
                  >
                    <option>{editData.location}</option>
                    {locationData.map((location) => (
                      <option key={location.id} value={location.location}>
                        {location.location}
                      </option>
                    ))}
                  </select>
                </Grid> */}

                <Grid item xs={6}>
                  <h5>Tax Number</h5>
                  <TextField
                    label="Tax Number"
                    fullWidth
                    margin="normal"
                    name="tax_number"
                    id="tax_number"
                    value={editData.tax_number}
                    onChange={handleChangeedit}
                    error={!!formErrors.tax_number}
                    helperText={formErrors.tax_number}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Image Upload</h5>
                  <TextField
                    type="file"
                    name="image"
                    id="image"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={handleEditImageChange}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {editData.image && !editpreviewImageUrl && (
                    <img
                      src={editData.image}
                      style={{ maxWidth: "100%", marginTop: "16px" }}
                    />
                  )}
                  {editpreviewImageUrl && (
                    <img
                      src={editpreviewImageUrl}
                      alt="Selected"
                      style={{ maxWidth: "25%", marginTop: "16px" }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>

        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={updatecompanydata}
            sx={{
              width: 100,
              background: "linear-gradient(to right, #1b2365, #181f5e)",
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : company && company.data && company.data.length > 0 ? (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table aria-label="customized table" id="employee_data">
              <TableHead>
                <TableRow>
                  <TableCell style={cellStyle}>Sl. No.</TableCell>
                  <TableCell style={cellStyle}>Company/شركة</TableCell>
                  <TableCell style={cellStyle}>
                    Email/البريد الإلكتروني
                  </TableCell>
                  <TableCell style={cellStyle}>Phone/الهاتف</TableCell>
                  <TableCell style={cellStyle}>Registration No</TableCell>
                  <TableCell style={cellStyle}>City/مدينة</TableCell>
                  <TableCell style={cellStyle}>Country/بلد</TableCell>
                  <TableCell style={cellStyle}>Website</TableCell>
                  <TableCell style={cellStyle}>Tax Number</TableCell>
                  <TableCell style={cellStyle}>Logo</TableCell>
                  <TableCell style={cellStyle}>Action/فعل</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {slicedData.map((companyData, index) => (
                  <TableRow key={companyData.id}>
                    <TableCell style={tableStyle}>{index + 1}</TableCell>
                    <TableCell style={tableStyle}>
                      {companyData.company}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {companyData.email}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {companyData.phone}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {companyData.reg_number}
                    </TableCell>
                    <TableCell style={tableStyle}>{companyData.city}</TableCell>
                    <TableCell style={tableStyle}>
                      {companyData.country}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {companyData.website}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {companyData.tax_number}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      <img
                        src={companyData.img}
                        alt="Company Image"
                        style={{ width: "80px" }}
                      />
                    </TableCell>

                    <TableCell style={tableStyle}>
                      <IconButton
                        onClick={(e) => handleMenuClick(e, companyData)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Typography sx={{ p: 1 }}>
                          <IconButton
                            style={{ color: "#2d388e" }}
                            onClick={() => handleMenuItemClick("edit")}
                          >
                            <EditIcon /> <h5 style={modalText}>Edit</h5>
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleMenuItemClick("delete")}
                          >
                            <DeleteIcon /> <h5 style={modalText}>Delete</h5>
                          </IconButton>
                        </Typography>
                      </Popover>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100, -1]}
            component="div"
            count={company.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
          />
        </React.Fragment>
      ) : (
        <div style={{ padding: "20px" }}>No Employee available</div>
      )}
    </HrThemeProvideWrapper>
  );
}
