import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const empFetchSalary = createAsyncThunk(
    "employees/FetchSalary",
    async (credentials) => {
        const token = getTokenFromLocalStorage();

        const res = await fetch(`${apiUrl}/api/employee/salary/fetch/salary`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
            body: JSON.stringify(credentials),
        });
        const data = await res.json();
        // if (data.status) {
        //     alert(data.message);
        // }
        return data;
    }
);

// 2
export const empfetchLoan = createAsyncThunk(
    "employees/addvanceSaalry",
    async (credentials) => {
        const token = getTokenFromLocalStorage();

        const res = await fetch(`${apiUrl}/api/employee/salary/fetch/loan`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
            // body: JSON.stringify(credentials),
        });
        const data = await res.json();
        // if (data.status) {
        //     alert(data.message);
        // }
        return data.data;
    }
);
// 3
export const empsalryreceipt = createAsyncThunk(
    "employees/empsalryreceipt",
    async (credentials) => {
        const token = getTokenFromLocalStorage();

        const res = await fetch(`${apiUrl}/api/employee/salary/receipt`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
            // body: JSON.stringify(credentials),
        });
        const data = await res.json();
        // if (data.status) {
        //     alert(data.message);
        // }
        return data;
    }
);
const EmployeeSalary = createSlice({
    name: "FetchSalary",
    initialState: {
        salary: null,
        isAuthenticated: false,
        isLoader: false,
        isError: false,
        token: null,
        status: 'pending',
    },
    extraReducers: (builder) => {
        builder.addCase(empFetchSalary.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(empFetchSalary.fulfilled, (state, action) => {
            state.isLoader = false;
            state.status = "success";
            state.isAuthenticated = true;
            state.salary = action.payload.data;
            state.token = action.payload.token;
        });
        builder.addCase(empFetchSalary.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        // 2
        builder.addCase(empfetchLoan.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(empfetchLoan.fulfilled, (state, action) => {
            state.isLoader = false;
            state.status = "success";
            state.isAuthenticated = true;
            state.salary = action.payload.data;
            state.token = action.payload.token;
        });
        builder.addCase(empfetchLoan.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
        // 3
        builder.addCase(empsalryreceipt.pending, (state) => {
            state.isLoader = true;
            state.isError = false;
        });
        builder.addCase(empsalryreceipt.fulfilled, (state, action) => {
            state.isLoader = false;
            state.status = "success";
            state.isAuthenticated = true;
            state.salary = action.payload.data;
            state.token = action.payload.token;
        });
        builder.addCase(empsalryreceipt.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
    },
});

export default EmployeeSalary.reducer;
