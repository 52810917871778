import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const updateDepartment = createAsyncThunk(
  "auth/departmentUpdate",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    console.log("Request Payload:", credentials);
    try {
      const res = await fetch(`${apiUrl}/api/admin/deparment/deparmentUpdate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });

      const data = await res.json();

      if (data.status) {
        successmsg(data.message);
      }
      else {
        handleApiError(data.errors, "failed");
      }
      return data;
    }
    catch (error) {
      handleApiError(error, "failed");
    }
  }
);

const updateDepartmentSlice = createSlice({
  name: "updateDepartment",
  initialState: {
    updateDepartment: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
    errorMessage: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateDepartmentSlice.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
      state.errorMessage = null; // Reset the error message on pending
    });
    builder.addCase(updateDepartmentSlice.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.updateDepartment = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(updateDepartmentSlice.rejected, (state, action) => {
      state.isLoader = false;
      state.isError = true;
      state.errorMessage = action.error.message; // Set the error message
    });
  },
});

export default updateDepartmentSlice.reducer;
