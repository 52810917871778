import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewEmployeeDetailsNav from "./ViewEmployeeDetailsNav";
import HrThemeProvideWrapper from "../../scenes/global/Hr/HrThemeProvideWrapper";
import TablePagination from "@mui/material/TablePagination";
import {
  fetchQualification,
  updateQualification,
  addQualification,
} from "../../Redux/Slice/Employee/EmployeeListSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const Qualification = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.employee);
  const error = useSelector((state) => state.employee.isError);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const loading = useSelector((state) => state.employee.isLoader);
  const [formData, setFormData] = useState({
    id: id,
    user_id: id,
    school: "",
    level: "",
    form: "",
    to: "",
    description: "",
    language: "",
  });
  const [editData, setEditData] = useState({
    id: "",
    user_id: "",
    school: "",
    level: "",
    form: "",
    to: "",
    description: "",
    language: "",
  });

  useEffect(() => {
    dispatch(fetchQualification(id));
  }, [status, id, dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeedit = (event) => {
    const { name, value } = event.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditModal(false);
  };

  const handleEditModel = (
    id,
    user_id,
    school,
    education_level,
    form,
    to,
    description,
    language
  ) => {
    setEditData({
      id: id,
      user_id: user_id,
      school: school,
      level: education_level,
      form: form,
      to: to,
      description: description,
      language: language,
    });
    setEditModal(true);
  };
  const handleform = () => {
    console.log(formData);
    dispatch(addQualification(formData)).then(async () => {
      dispatch(fetchQualification(id));
      setOpenModal(false);
    });
  };
  const updateform = () => {
    console.log(editData);
    dispatch(updateQualification(editData)).then(async () => {
      dispatch(fetchQualification(id));
      setEditModal(false);
    });
  };
  const cellStyle = {
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#2d388e",
    color: "white",
  };
  const tableStyle = { fontSize: "13px", padding: "4px" };

  // ==========filter==========//

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const slicedData =
    data?.length > 0
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];

  return (
    <HrThemeProvideWrapper>
      <div style={containerStyle}>
        <div style={navStyle}>
          <ViewEmployeeDetailsNav />
        </div>
        <div style={detailsStyle}>
          <Card style={detailsStyle2}>
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <CardHeader title="Qualification" />
              <div style={detailsStyle}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginBottom: "20px",
                    background: "linear-gradient(to right, #1b2365, #181f5e)",
                  }}
                  startIcon={<AddIcon />}
                  onClick={handleOpenModal}
                >
                  Add Qualification
                </Button>
                <Dialog open={openModal} onClose={handleCloseModal}>
                  <DialogTitle>Add Qualification</DialogTitle>
                  <div
                    style={{
                      background:
                        "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                    >
                      <DialogContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                              School/University
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              name="school"
                              value={formData.school}
                              onChange={handleChange}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom s>
                              Education Level
                            </Typography>
                            <Select
                              style={{ width: "100%", marginTop: "16px" }}
                              value={formData.level || "Select Education Level"}
                              name="level"
                              onChange={handleChange}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Education Level" disabled>
                                Select Education Level
                              </MenuItem>
                              <MenuItem value="degree">Degree</MenuItem>
                              <MenuItem value="certificate">
                                certificate
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Time Period(Form)
                            </Typography>
                            <TextField
                              type="date"
                              variant="outlined"
                              margin="normal"
                              name="form"
                              value={formData.form}
                              fullWidth
                              required
                              onChange={handleChange}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Time Period(To)
                            </Typography>
                            <TextField
                              type="date"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              name="to"
                              value={formData.to}
                              required
                              onChange={handleChange}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Language
                            </Typography>
                            <Select
                              style={{ width: "100%", marginTop: "16px" }}
                              value={formData.language || "Select Language"}
                              onChange={handleChange}
                              name="language"
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Language" disabled>
                                Select Language
                              </MenuItem>
                              <MenuItem value="english">English</MenuItem>
                              <MenuItem value="hindi">Hindi</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                              Description
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              name="description"
                              value={formData.description}
                              onChange={handleChange}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </div>
                  </div>

                  <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleform}
                      sx={{
                        width: 100,
                        background:
                          "linear-gradient(to right, #1b2365, #181f5e)",
                      }}
                    >
                      Add
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* edit model */}

                <Dialog open={editModal} onClose={handleCloseModal}>
                  <DialogTitle>Update Qualification</DialogTitle>
                  <div
                    style={{
                      background:
                        "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                    >
                      <DialogContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                              School/University
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              name="school"
                              value={editData.school}
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Education Level
                            </Typography>
                            <Select
                              style={{ width: "100%", marginTop: "16px" }}
                              value={editData.level}
                              name="level"
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="degree">Degree</MenuItem>
                              <MenuItem value="certificate">
                                certificate
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Time Period(Form)
                            </Typography>
                            <TextField
                              type="date"
                              variant="outlined"
                              margin="normal"
                              name="form"
                              value={editData.form}
                              fullWidth
                              required
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Time Period(To)
                            </Typography>
                            <TextField
                              type="date"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              name="to"
                              value={editData.to}
                              required
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Language
                            </Typography>
                            <Select
                              style={{ width: "100%", marginTop: "16px" }}
                              value={editData.language}
                              onChange={handleChangeedit}
                              name="language"
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="english">English</MenuItem>
                              <MenuItem value="hindi">Hindi</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                              Description
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              name="description"
                              value={editData.description}
                              onChange={handleChangeedit}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          {/* <Grid item xs={2}>
                                                    <Button type="button" variant="contained" color="primary" fullWidth onClick={handleform}>
                                                        Update
                                                    </Button>
                                                </Grid> */}
                        </Grid>
                      </DialogContent>
                    </div>
                  </div>
                  <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={updateform}
                      sx={{
                        width: 100,
                        background:
                          "linear-gradient(to right, #1b2365, #181f5e)",
                      }}
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>

                {loading ? (
                  <div>Loading...</div>
                ) : error ? (
                  <div>Error: {error.message}</div>
                ) : data && data.length > 0 ? (
                  <React.Fragment>
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={cellStyle}>Sl. No.</TableCell>
                            <TableCell style={cellStyle}>
                              School/University
                            </TableCell>
                            <TableCell style={cellStyle}>
                              Education Level
                            </TableCell>
                            <TableCell style={cellStyle}>
                              Time Period(Form)
                            </TableCell>
                            <TableCell style={cellStyle}>
                              Time Period(To)
                            </TableCell>
                            <TableCell style={cellStyle}>Language</TableCell>

                            <TableCell style={cellStyle}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {slicedData &&
                            slicedData.map((employeeData, index) => (
                              <TableRow key={employeeData.id}>
                                <TableCell style={tableStyle}>
                                  {index + 1}
                                </TableCell>
                                <TableCell style={tableStyle}>
                                  {employeeData.school}
                                </TableCell>
                                <TableCell style={tableStyle}>
                                  {employeeData.education_level}
                                </TableCell>
                                <TableCell style={tableStyle}>
                                  {employeeData.form}
                                </TableCell>
                                <TableCell style={tableStyle}>
                                  {employeeData.to}
                                </TableCell>
                                <TableCell style={tableStyle}>
                                  {employeeData.language}
                                </TableCell>
                                <TableCell style={tableStyle}>
                                  <IconButton
                                    onClick={() =>
                                      handleEditModel(
                                        employeeData.id,
                                        employeeData.user_id,
                                        employeeData.school,
                                        employeeData.education_level,
                                        employeeData.form,
                                        employeeData.to,
                                        employeeData.description,
                                        employeeData.language
                                      )
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, -1]}
                      component="div"
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage="Rows per page"
                    />
                  </React.Fragment>
                ) : (
                  <div style={{ padding: "20px" }}>
                    No Qualification available
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </HrThemeProvideWrapper>
  );
};

const containerStyle = {
  display: "flex",
  //   flexDirection: "row",
};

const navStyle = {
  flex: "0 0 17%",
};

const detailsStyle = {
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "20px",
  width: "100%",
};
const detailsStyle2 = {
  background: `url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')`,
  backgroundSize: "cover",
};

export default Qualification;
