import React from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import HrSidebar from "./HrSidebar";
import { ColorModeContext, useMode } from "../../../theme";
import HrTopbar from "./HrTopBar";

const HrThemeProvideWrapper = ({ children }) => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" style={{ position: "fixed", display: "flex" }}>
          <HrSidebar />

          <main className="content" style={{ flex: 1, overflowY: "auto" }}>
            <HrTopbar style={{ position: "fixed", marginBottom: 20 }} />
            <div
              style={{
                borderRadius: 10,
                height: "100%",
              }}
            >
              <div
                style={{
                  borderRadius: 10,
                  height: "100%",
                }}
              >
                <div
                  style={{
                    background: "linear-gradient(to right, #c0c0c0, #eeeeee)", // Grey color
                    borderRadius: 10,
                    padding: "20px",
                  }}
                >
                  {children}
                </div>
              </div>
            </div>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default HrThemeProvideWrapper;
