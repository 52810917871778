import React from "react";
import AdminThemeProviderWrapper from "../global/Admin/AdminThemeProviderWrapper";

const Dashboard = () => {
  return (
    <AdminThemeProviderWrapper>Dashboard Content</AdminThemeProviderWrapper>
  );
};

export default Dashboard;
