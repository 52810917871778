import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InputBase from "@mui/material/InputBase";
import { useState } from "react";
import {
  Table,
  Popover,
  Typography,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Checkbox,
} from "@mui/material";
import { addLocation } from "../../Redux/Slice/Loaction/AddLocationSlice";
import { fetchLocation } from "../../Redux/Slice/Loaction/LoactionListSlice";
import { DeleteLocationList } from "../../Redux/Slice/Loaction/DeleteLocationSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchEmployee } from "../../Redux/Slice/Employee/EmployeeListSlice";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TablePagination from "@mui/material/TablePagination";
import { updateLocation } from "../../Redux/Slice/Loaction/UpdateLocationSlice";
import HrThemeProvideWrapper from "../global/Hr/HrThemeProvideWrapper";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Location() {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const loading = useSelector((state) => state.location.isLoader);
  const error = useSelector((state) => state.location.isError);
  const [openModal, setOpenModal] = React.useState(false);
  const [formData, setFormData] = useState({
    location: "",
    location_head: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });
  const [employeeData, setEmployeeData] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchEmployee());
        const dataFromResponse = response.payload.data || [];
        setEmployeeData(dataFromResponse);
      } catch (error) {
        console.error("Error fetching Employee data:", error);
      }
    };

    fetchData();
  }, [dispatch]);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  React.useEffect(() => {
    dispatch(fetchLocation());
  }, [dispatch]);
  console.log("Fetched Loaction Dat:", location);

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [formErrors, setFormErrors] = useState({
    location: "",
    location_head: "null",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.location.trim()) {
      errors.location = "Location name is required";
    }

    // if (!formData.location_head.trim()) {
    //   errors.location_head = "Location Head is required";
    // }

    // if (!formData.address_1.trim()) {
    //   errors.address_1 = "Address 1 name is required";
    // }

    // if (!formData.address_2.trim()) {
    //   errors.address_2 = "Address 2 number is required";
    // }

    // if (!formData.city.trim()) {
    //   errors.city = "City is required";
    // }
    // if (!formData.state.trim()) {
    //   errors.state = "State is required";
    // }
    // if (!formData.country.trim()) {
    //   errors.country = "Country is required";
    // }
    // if (!formData.zip.trim()) {
    //   errors.zip = "Zip is required";
    // }
    return errors;
  };
  const handleForm = () => {
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      // Set errors and prevent form submission
      setFormErrors(errors);
      return;
    }

    // Add selectedLocation to the formData
    const formDataWithLocation = {
      ...formData,
      // location_head: selectedEmployee,
    };

    dispatch(addLocation(formDataWithLocation))
      .then(() => {
        // Reset form data and errors after successful addition
        setFormData({
          location: "",
          location_head: "",
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          country: "",
          zip: "",
        });
        setFormErrors({});
        setSelectedEmployee(null); // Reset selected location

        // Reload data after successful addition
        dispatch(fetchLocation());
        // toast.success("Location added successfully!");

        // Close the modal
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding Location:", error);
        toast.error("Error adding Location. Please try again.");
      });
  };

  const handleDeleteLocation = (companyId) => {
    dispatch(DeleteLocationList(companyId))
      .then(() => {
        dispatch(fetchLocation());
        // toast.success("Deleted successfully!"); // Show success notification
      })
      .catch((error) => {
        // Handle errors, if any
        console.error("Error deleting company:", error);
        toast.error("Error deleting company. Please try again."); // Show error notification
      });
  };

  // update
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [editLocationData, setEditLocationData] = React.useState({
    id: "",
    location: "",
    location_head: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });
  const handleEditModalChange = (field, value) => {
    setEditLocationData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    // Dynamically update selectedCompany and selectedDepartment based on the current data
    if (field === "location_head") {
      setSelectedEmployee(value);
    }
  };

  const handleEditDesignation = () => {
    const {
      id,
      location,
      location_head,
      address_1,
      address_2,
      city,
      state,
      country,
      zip,
    } = editLocationData;

    const credentials = {
      id,
      location,
      location_head,
      address_1,
      address_2,
      city,
      state,
      country,
      zip,
    };

    // Include default values for dropdowns in the payload
    credentials.name = selectedEmployee || "";

    dispatch(updateLocation(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchLocation());
        // toast.success("Location updated successfully!");

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Location:", error);
        toast.error("Error updating Location. Please try again.");
      });
  };

  const handleOpenEditModal = (locationData) => {
    if (locationData) {
      setSelectedLocation(locationData);
      setEditLocationData({
        id: locationData.id,
        location: locationData.location,
        location_head: locationData.location_head,
        address_1: locationData.address_1,
        address_2: locationData.address_2,
        city: locationData.city,
        state: locationData.state,
        country: locationData.country,
        zip: locationData.zip,
      });

      // Set default values for dropdowns based on existing data
      setSelectedEmployee(locationData.name);
    } else {
      setSelectedLocation(null);
      setEditLocationData({
        id: "",
        location: "",
        location_head: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      });
    }
    setOpenEditModal(true);
  };
  //  ====================== search==============================//
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = Array.isArray(location?.data)
    ? location.data.filter((locationData) => {
        const nameLowerCase = locationData.location?.toLowerCase() || "";
        const emailLowerCase = locationData.location_head?.toLowerCase() || "";
        return (
          nameLowerCase.includes(searchTerm) ||
          emailLowerCase.includes(searchTerm)
        );
      })
    : [];

  const slicedData =
    filteredData?.length > 0
      ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMenuClick = (event, locationData) => {
    setAnchorEl(event.currentTarget);
    setSelectedLocation(locationData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedLocation(null);
  };

  const cellStyle = {
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#2d388e",
    color: "white",
  };
  const tableStyle = { fontSize: "13px", padding: "4px" };
  const modalText = { fontSize: "10px" };
  return (
    <HrThemeProvideWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",

          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            marginBottom: "20px",
            background: "linear-gradient(to right, #1b2365, #181f5e)",
          }}
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Location
        </Button>
        <InputBase
          sx={{ ml: 2, marginBottom: "20px" }}
          placeholder="Search by Location, Location Head"
          onChange={handleSearch}
          style={{
            color: "#000",
            paddingLeft: "10px",
            padding: "3px",
            paddingRight: "10px",
            borderRadius: "5px",
            backgroundColor: "#fff", // Background color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
            // outline: "none", // Remove outline on focus
            transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
          }}
        />
      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Location</DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h5>Location</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="location"
                    id="location"
                    value={formData.location}
                    onChange={handleChange}
                    error={!!formErrors.location}
                    helperText={formErrors.location}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <h5 >Location Head</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="location_head"
                    value={formData.location_head}
                    onChange={handleChange}
                    error={!!formErrors.location_head}
                    helperText={formErrors.location_head}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <h5>Address 1</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="address_1"
                    id="address_1"
                    value={formData.address_1}
                    onChange={handleChange}
                    error={!!formErrors.address_1}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    helperText={formErrors.address_1}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <h5>Address 2</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="address_2"
                    id="address_2"
                    value={formData.address_2}
                    onChange={handleChange}
                    error={!!formErrors.address_2}
                    helperText={formErrors.address_2}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <h5>City</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="city"
                    id="city"
                    value={formData.city}
                    onChange={handleChange}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    error={!!formErrors.city}
                    helperText={formErrors.city}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>State</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="state"
                    id="state"
                    value={formData.state}
                    onChange={handleChange}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    error={!!formErrors.state}
                    helperText={formErrors.state}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Country</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="country"
                    id="country"
                    value={formData.country}
                    onChange={handleChange}
                    error={!!formErrors.country}
                    helperText={formErrors.country}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Zip</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="zip"
                    id="zip"
                    value={formData.zip}
                    onChange={handleChange}
                    error={!!formErrors.zip}
                    helperText={formErrors.zip}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>

        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleForm}
            sx={{
              width: 100,
              background: "linear-gradient(to right, #1b2365, #181f5e)",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="bottom-right" autoClose={3000} />
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : location && location.data && location.data.length > 0 ? (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table aria-label="customized table" id="employee_data">
              <TableHead>
                <TableRow>
                  <TableCell style={cellStyle}>Sl. No.</TableCell>
                  <TableCell style={cellStyle}>Location/مكان</TableCell>
                  <TableCell style={cellStyle}>Location Head/مكان</TableCell>
                  <TableCell style={cellStyle}>Address 1/العنوان 1</TableCell>
                  <TableCell style={cellStyle}>Action/فعل</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {slicedData.map((locationData, index) => (
                  <TableRow key={locationData.id}>
                    <TableCell style={tableStyle}>{index + 1}</TableCell>
                    <TableCell style={tableStyle}>
                      {locationData.location}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {locationData.location_head}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {locationData.address_1}
                    </TableCell>

                    <TableCell style={tableStyle}>
                      <IconButton
                        onClick={(e) => handleMenuClick(e, locationData)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Typography sx={{ p: 1 }}>
                          <IconButton
                            style={{ color: "#2d388e" }}
                            onClick={() => handleOpenEditModal(locationData)}
                          >
                            <EditIcon /> <h5 style={modalText}>Edit</h5>
                          </IconButton>
                          {/* <IconButton
                            onClick={() => handleMenuItemClick("edit")}
                          >
                            <EditIcon /> <h5 style={modalText}>Edit</h5>
                          </IconButton> */}
                          <IconButton
                            color="error"
                            onClick={() =>
                              handleDeleteLocation(locationData.id)
                            }
                          >
                            <DeleteIcon /> <h5 style={modalText}>Delete</h5>
                          </IconButton>
                        </Typography>
                      </Popover>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100, -1]}
            component="div"
            count={location.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
          />
        </React.Fragment>
      ) : (
        <div style={{ padding: "20px" }}>No Employee available</div>
      )}
      <Dialog open={openEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>Update Location/تحديث الموقع</DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <input
                  type="hidden"
                  id="eid"
                  value={selectedLocation ? selectedLocation.id : ""}
                />

                <Grid item xs={6}>
                  <h5>Location</h5>
                  <TextField
                    id="edesignation"
                    defaultValue={
                      selectedLocation ? selectedLocation.location : ""
                    }
                    onChange={(e) =>
                      handleEditModalChange("location", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>

                {/* <Grid item xs={6}>
                <h5 >Location Head</h5>
                <TextField
                  
                  // defaultValue={selectedLocation ? selectedLocation.location_head : ""}
                  value={selectedLocation ? selectedLocation.location_head:''}
                  onChange={(e) =>
                    handleEditModalChange("location_head", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                />
              </Grid> */}

                <Grid item xs={6}>
                  <h5>Address </h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="address_1"
                    id="address_1"
                    defaultValue={
                      selectedLocation ? selectedLocation.address_1 : ""
                    }
                    onChange={(e) =>
                      handleEditModalChange("address_1", e.target.value)
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                <h5>Address 2</h5>
                <TextField
                  fullWidth
                  margin="normal"
                  name="address_2"
                  id="address_2"
                  defaultValue={
                    selectedLocation ? selectedLocation.address_2 : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("address_2", e.target.value)
                  }
                />
              </Grid> */}
                <Grid item xs={6}>
                  <h5>City</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="city"
                    id="city"
                    defaultValue={selectedLocation ? selectedLocation.city : ""}
                    onChange={(e) =>
                      handleEditModalChange("city", e.target.value)
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>State</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="state"
                    id="state"
                    defaultValue={
                      selectedLocation ? selectedLocation.state : ""
                    }
                    onChange={(e) =>
                      handleEditModalChange("state", e.target.value)
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Country</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="country"
                    id="country"
                    defaultValue={
                      selectedLocation ? selectedLocation.country : ""
                    }
                    onChange={(e) =>
                      handleEditModalChange("country", e.target.value)
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Zip</h5>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="zip"
                    id="zip"
                    defaultValue={selectedLocation ? selectedLocation.zip : ""}
                    onChange={(e) =>
                      handleEditModalChange("zip", e.target.value)
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>

        <DialogActions>
          <Button onClick={handleCloseEditModal} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleEditDesignation}
            variant="contained"
            sx={{
              width: 100,
              background: "linear-gradient(to right, #1b2365, #181f5e)",
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </HrThemeProvideWrapper>
  );
}
