import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import { handleApiError } from "../../../Helpers/handleApiError";
import "react-toastify/dist/ReactToastify.css";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const updateEmployee = createAsyncThunk(
  "auth/updateEmployee",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    console.log("Request Payload:", credentials);
    const res = await fetch(`${apiUrl}/api/admin/employee/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });

    const data = await res.json();

    if (data.status) {

      toast.success("Empyee Update");
      return data;
      // throw new Error(data.message || "Add Employee failed");
    }
    else {
      handleApiError(data.errors, 'Failed to fetch data. Please try again later.');
      throw new Error(data.message || "update Employee failed");
    }
    
  }
);
// 2 

export const allCoutries = createAsyncThunk(
  "auth/allCoutries",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    console.log("Request Payload:", credentials);
    const res = await fetch(`https://restcountries.com/v3.1/all?fields=name,flags`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });

    const data = await res.json();

    if (data.status) {

      toast.success("Empyee Update");
      return data;
      // throw new Error(data.message || "Add Employee failed");
    }
    else {
      handleApiError(data.errors, 'Failed to fetch data. Please try again later.');
      throw new Error(data.message || "update Employee failed");
    }
    
  }
);


const updateEmployeeSlice = createSlice({
  name: "updateEmployee",
  initialState: {
    updateEmployee: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
    errorMessage: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateEmployee.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
      state.errorMessage = null; // Reset the error message on pending
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.updateEmployee = action.payload;
      state.token = action.payload.token;
    });
    builder.addCase(updateEmployee.rejected, (state, action) => {
      state.isLoader = false;
      state.isError = true;
      state.errorMessage = action.error.message; // Set the error message
    });

    //2 
    builder.addCase(allCoutries.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
      state.errorMessage = null; // Reset the error message on pending
    });
    builder.addCase(allCoutries.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.updateEmployee = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(allCoutries.rejected, (state, action) => {
      state.isLoader = false;
      state.isError = true;
      state.errorMessage = action.error.message; // Set the error message
    });
  },
});

export default updateEmployeeSlice.reducer;
