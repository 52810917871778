import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import HrThemeProvideWrapper from '../global/Hr/HrThemeProvideWrapper';
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { hrLeave, hr_add_Leave } from '../../Redux/Slice/HrSection/HrLeaveSlice';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const CustomCalendar = () => {
  const dispatch = useDispatch();
  const { leavedata, leavestatus } = useSelector((state) => state.leave);
  const [openModal, setOpenModal] = React.useState(false);
  const [formData, setFormData] = useState({
    type: '',
    day: '',
    date: '',
    name: '',
  });
  const [formErrors, setFormErrors] = useState({
    type: '',
    day: '',
    date: '',
    name: '',
  });
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  };
  const handleForm = async (e) => {
    e.preventDefault();

    // Reset form errors
    setFormErrors({
      type: '',
      // Reset other error fields as needed
    });

    if (formData.type === '') {
      setFormErrors((prevErrors) => ({ ...prevErrors, type: 'Type  required' }));
    }
    if (formData.date === '') {
      setFormErrors((prevErrors) => ({ ...prevErrors, date: 'Date  required' }));
    }
    if (formData.day === '') {
      setFormErrors((prevErrors) => ({ ...prevErrors, day: 'day  required' }));
    }

    if (formData.name === '') {
      setFormErrors((prevErrors) => ({ ...prevErrors, name: 'Name  required' }));
    }
    dispatch(hr_add_Leave(formData));
  };
  useEffect(() => {
    dispatch(hrLeave());
  }, [leavedata]);

  // Function to customize the appearance of specific dates
  const tileContent = ({ date, view }) => {
    const formattedDate = date.toISOString().split('T')[0];

    // Check if it's a leave day (Sunday or a specific date)
    const isLeaveDay = leavedata.some(
      (leave) => leave.date === formattedDate || leave.day === date.toLocaleDateString('en-US', { weekday: 'long' })
    );

    // Customize the appearance based on whether it's a leave day or not
    return isLeaveDay ? <div style={{ color: 'red' }}>Sunday</div> : null;
  };


return (
  <HrThemeProvideWrapper>
    <Dialog open={openModal} onClose={handleCloseModal}>
      <DialogTitle>Add Leave</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Type
            </Typography>

            <TextField
              value={formData.company}
              name="type"
              value={formData.type}
              onChange={handleChange}
              error={!!formErrors.type}
              helperText={formErrors.type}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Day
            </Typography>

            <TextField
              value={formData.day}
              name="day"
              onChange={handleChange}
              value={formData.day}
              error={!!formErrors.day}
              helperText={formErrors.day}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Name
            </Typography>

            <TextField
              value={formData.name}
              name="name"
              onChange={handleChange}
              value={formData.name}
              error={!!formErrors.name}
              helperText={formErrors.name}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Date
            </Typography>

            <TextField
              value={formData.date}
              type="date"
              name="date"
              onChange={handleChange}
              value={formData.date}
              error={!!formErrors.date}
              helperText={formErrors.date}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="secondary">
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleForm}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Attendance" />
          <CardContent >
            <Button
              type="button"
              variant="contained"
              color="primary"

              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              Add Leave
            </Button>
          </CardContent>
          {leavestatus == 'success' ? (
            <Calendar
              tileContent={tileContent}
            // You can add more props as needed
            />
          ) : ('Not found')

          }

        </Card>
      </Grid>
    </Grid>
  </HrThemeProvideWrapper>
);
};

export default CustomCalendar;
