import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const addEmployee = createAsyncThunk(
  "auth/addEmployee",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/admin/employee/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });
      const data = await res.json();

      if (data.status) {
        successmsg(data.message);
      }
      else {
        handleApiError(data.errors, 'failed');
      }
      return data;
    }
    catch (error) {
      handleApiError(error, 'failed');
    }
  }

);
// 


const EmployeeAddSlice = createSlice({
  name: "employee",
  initialState: {
    employee: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.addCase(addEmployee.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(addEmployee.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.employee = action.payload;
      // state.token = action.payload.token;
    });
    builder.addCase(addEmployee.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default EmployeeAddSlice.reducer;
