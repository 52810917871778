import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { empFetchSalary } from "../../Redux/Slice/EmployeeSection/EmployeeSalary";
import EmpThemeProviderWrapper from "../global/Employee/EmployeeThemeProviderWrapper";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from "@mui/icons-material/Add";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
// import { empFetchSalary } from "../../Redux/Slice/EmployeeSection/EmployeeSalary";
const Salary = () => {
  const dispatch = useDispatch();
  const { salary, status } = useSelector((state) => state.empsalary);
  const [openModal, setOpenModal] = React.useState(false);
  const [formData, setFormData] = useState({
    name: salary?.name,
    email: salary?.email,
    salary: salary?.salary,
    emp_code: salary?.emp_code,
    amount: '',
    date: '',
    emi: ''
  });
  useEffect(() => {
    
    dispatch(empFetchSalary());
  }, []);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [formErrors, setFormErrors] = useState({
    amount: "",
    date: "",
    emi: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };
  const handleForm = async (e) => {
    if (formData.amount == '') {
      alert("Amount Required");
    }
    else if (formData.amount == '') {
      alert("Date Required");
    }
    if (formData.amount == '') {
      alert("Emi Required");
    }
    // dispatch(addvanceSalary(formData));
  }
  return (
    <EmpThemeProviderWrapper>
      <div style={containerStyle}>

        <div style={detailsStyle}>
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>Add  Advance Salry</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Amount
                  </Typography>

                  <TextField value={formData.company}
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    error={!!formErrors.amount}
                    helperText={formErrors.amount}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Deducction Start
                  </Typography>

                  <TextField value={formData.company}
                    type="date"
                    name="date"
                    onChange={handleChange}
                    value={formData.date}
                    error={!!formErrors.date}
                    helperText={formErrors.date}
                    required
                  />
                </Grid><Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    EmI
                  </Typography>

                  <TextField value={formData.company}
                    type="number"
                    name="emi"
                    onChange={handleChange}
                    value={formData.emi}
                    error={!!formErrors.emi}
                    helperText={formErrors.emi}
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="secondary">
                Cancel
              </Button>
              <Button color="primary" variant="contained" onClick={handleForm}>
                Add
              </Button>
            </DialogActions>
          </Dialog>
          <Card>

            <CardHeader title="Salary" />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={handleOpenModal}
                >
                  Add Advance Salary
                </Button>
              </Grid>
            </Grid>
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Name
                      </Typography>
                      <TextField
                        name="name"
                        fullWidth
                        margin="normal"
                        value={formData.name}

                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Email
                      </Typography>
                      <TextField
                        fullWidth
                        margin="normal"
                        name="email"
                        value={formData.email}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Salary
                      </Typography>
                      <TextField
                        name="salary"
                        fullWidth
                        margin="normal"
                        value={formData.salary}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom>
                        Emp code
                      </Typography>
                      <TextField
                        name="emp_code"
                        fullWidth
                        margin="normal"
                        value={formData.emp_code}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Typography>
            </CardContent>
          </Card>
        </div>

      </div>
    </EmpThemeProviderWrapper>
  )

}
const containerStyle = {
  display: "flex",
  flexDirection: "row",
  height: "100vh",
};

const navStyle = {
  flex: "0 0 30%",
  marginTop: "30px",
  padding: "20px",
};

const detailsStyle = {
  flex: "1",
  padding: "20px",
};
export default Salary;