import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
} from "@mui/material";
import HrThemeProvideWrapper from "../../global/Hr/HrThemeProvideWrapper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { salarygetnarate, salarygetnaraterecipt } from "../../../Redux/Slice/HrSection/HrSalarySection";

const SalaryGenerate = () => {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const { hrsalaryData, hrsalaryStatus } = useSelector((state) => state.hrsalary);

    useEffect(() => {
        dispatch(salarygetnarate());
    }, []);

    const handleOpenModal = (id) => {
        dispatch(salarygetnaraterecipt(id));
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <HrThemeProvideWrapper>
            {/* Salary Receipt Dialog */}
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>View Salary Receipt</DialogTitle>
                <DialogContent style={{ width: "500%" }}>
                    {hrsalaryStatus === "success" && (
                        Object.keys(hrsalaryData).map((userId) => {
                            const userData = hrsalaryData[userId];

                            return (
                                <div key={userId}>
                                    <Typography variant="h6">{userData.name}</Typography>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={`Total Salary: ${userData.total_salary}`} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={`Present: ${userData.present}`} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={`Total Absent: ${userData.total_absent}`} />
                                        </ListItem>
                                        {/* <ListItem>
                                            <ListItemText primary={`Absent: ${userData.absent}`} />
                                        </ListItem> */}
                                    </List>
                                    <Divider />
                                    <Typography variant="h6">Deductions</Typography>
                                    <List>
                                        {userData.salary_deducation.deductions.map((deduction, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={`Type: ${deduction.type}, Amount: ${deduction.amount}`} />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Divider />
                                    <Typography variant="h6">Net Salary</Typography>
                                    <Typography>{`Net Salary: ${userData.salary_deducation.net_salary}`}</Typography>
                                    <Divider />
                                </div>
                            );
                        })
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Salary Table */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S.n</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Salary</TableCell>
                            <TableCell>Present</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hrsalaryStatus === "success" ? (
                            Object.keys(hrsalaryData).map((key) => {
                                const employee = hrsalaryData[key];
                                return (
                                    <TableRow key={employee.id}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>{employee.name}</TableCell>
                                        <TableCell>{employee.salarys}</TableCell>
                                        <TableCell>{employee.present}</TableCell>
                                        <TableCell>{employee.salary}</TableCell>
                                        <TableCell>
                                            <IconButton>
                                                <VisibilityIcon onClick={() => handleOpenModal(employee.id)} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No data
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </HrThemeProvideWrapper>
    );
};

export default SalaryGenerate;
