import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const hrsalary = createAsyncThunk("hr/hrsalary", async (credentials) => {
  const token = getTokenFromLocalStorage();
  try {
    const res = await fetch(`${apiUrl}/api/hr/salary`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });

    const final = await res.json();

    return final;
  }
  catch (error) {
    handleApiError(error, 'failed');
  }
});
// 2
export const salarySearchByName = createAsyncThunk(
  "hr/hrsalarysearch",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    const res = await fetch(`${apiUrl}/api/hr/salary/serach/` + credentials, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });

    const final = await res.json();


    console.log("Response data:", final);

    return final;
  }
);
//  3
export const hrAddAdvanceSalary = createAsyncThunk(
  "hr/addadvance",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/hr/salary/store_adance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });

      const final = await res.json();
      if (final.status) {
        successmsg(final.message);
      }
      else {

        handleApiError(final.errors, 'failed');
      }
      return final;
    }
    catch (error) {
      handleApiError(error, 'failed');
    }
  }
);
//4
export const addAdvanceSalary = createAsyncThunk(
  "hr/addadvance",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/admin/employee/advance/salary/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
          body: JSON.stringify(credentials),
        }
      );

      const final = await res.json();

      // Log the response data in the console
      console.log("Response data:", final);
      if (final.status) {
        successmsg(final.message);
      }
      return final;
    }
    catch (error) {
      handleApiError(error, 'failed');
    }
  }

);
export const hrDownloadSalary = createAsyncThunk(
  "Employee/hrdownloadinvoice",
  async (credentials) => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/api/hr/salary/download`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/pdf", // Specify that you want to accept a PDF
      },
    });

    // Check if the request was successful (status code 2xx)
    if (!res.ok) {
      throw new Error(`Error: ${res.status} - ${res.statusText}`);
    }

    // Extract the Blob from the response
    const blob = await res.blob();

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "filename.pdf"; // Update the filename to have a .pdf extension

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Return a success message or any other relevant information
    return { success: true, message: "File downloaded successfully" };
  }
);

//  5

export const hrloan = createAsyncThunk(
  "hr/hrloan",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    const res = await fetch(`${apiUrl}/api/hr/salary/loan`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });

    const final = await res.json();

    return final;
  });

// 6
export const hrAddAdLoan = createAsyncThunk(
  "hr/addloan",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/hr/salary/loan/store`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });

      const final = await res.json();

      if (final.status) {
        successmsg(final.message);
      }
      else {
        handleApiError(final.errors, 'failed');
      }
      return final;
    }
    catch (error) {
      handleApiError(error, 'failed');
    }
  }

);
//7
export const fetchhrsalary = createAsyncThunk(
  "hr/fetchhrsalary",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {

      const res = await fetch(`${apiUrl}/api/hr/salary/salary/count`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });

      const final = await res.json();
      if (final.status) {
        // successmsg(final.message);
      }
      return final;
    } catch (error) {
      handleApiError(error, 'failed');
    }
  }
);
//  8
export const salarygetnarate = createAsyncThunk(
  "hr/salarygetnarate",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {

      const res = await fetch(`${apiUrl}/api/hr/salary/salary/incoice`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        // body: JSON.stringify(credentials),
      });

      const final = await res.json();
      return final;
    } catch (error) {

    }
  }
);
// 9
export const salarygetnaraterecipt = createAsyncThunk(
  "hr/salarygetnaraterecipt",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {

      const res = await fetch(`${apiUrl}/api/hr/salary/salary/recipt/` + credentials, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        // body: JSON.stringify(credentials),
      });

      const final = await res.json();
      return final;
    } catch (error) {

    }
  }
);

const HrSalarySection = createSlice({
  name: "HrSalary",
  initialState: {
    hrsalaryData: null,
    hrsalaryByName: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
    hrsalaryStatus: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(hrsalary.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(hrsalary.fulfilled, (state, action) => {
      state.hrsalaryStatus = "success";
      state.isLoader = false;
      state.isAuthenticated = true;
      state.hrsalaryData = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(hrsalary.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 2
    builder.addCase(salarySearchByName.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(salarySearchByName.fulfilled, (state, action) => {
      state.hrsalaryStatus = "success";
      state.isLoader = false;
      state.isAuthenticated = true;
      state.hrsalaryByName = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(salarySearchByName.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 3
    builder.addCase(hrAddAdvanceSalary.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(hrAddAdvanceSalary.fulfilled, (state, action) => {
      state.hrsalaryStatus = "success";
      state.isLoader = false;
      state.isAuthenticated = true;
      state.hrsalaryData = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(hrAddAdvanceSalary.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    // 4
    builder.addCase(hrDownloadSalary.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(hrDownloadSalary.fulfilled, (state, action) => {
      state.hrsalaryStatus = "success";
      state.isLoader = false;
      state.isAuthenticated = true;
      state.hrsalaryData = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(hrDownloadSalary.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 5
    builder.addCase(hrloan.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(hrloan.fulfilled, (state, action) => {
      state.hrsalaryStatus = "success";
      state.isLoader = false;
      state.isAuthenticated = true;
      state.hrsalaryData = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(hrloan.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 6
    builder.addCase(hrAddAdLoan.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(hrAddAdLoan.fulfilled, (state, action) => {
      state.hrsalaryStatus = "success";
      state.isLoader = false;
      state.isAuthenticated = true;
      state.hrsalaryData = action.payload;
      // state.token = action.payload.token;
    });
    builder.addCase(hrAddAdLoan.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 7
    builder.addCase(fetchhrsalary.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(fetchhrsalary.fulfilled, (state, action) => {
      state.hrsalaryStatus = "success";
      state.isLoader = false;
      state.isAuthenticated = true;
      state.hrsalaryData = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(fetchhrsalary.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 8
    builder.addCase(salarygetnarate.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(salarygetnarate.fulfilled, (state, action) => {
      state.hrsalaryStatus = "success";
      state.isLoader = false;
      state.isAuthenticated = true;
      state.hrsalaryData = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(salarygetnarate.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 9
    builder.addCase(salarygetnaraterecipt.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(salarygetnaraterecipt.fulfilled, (state, action) => {
      state.hrsalaryStatus = "success";
      state.isLoader = false;
      state.isAuthenticated = true;
      state.hrsalaryData = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(salarygetnaraterecipt.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default HrSalarySection.reducer;
