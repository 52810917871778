import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const handleApiError = (error, customErrorMessage = 'An error occurred.') => {
  var message;
  if (error) {
    message = error;
  }
  else if (error.response) {
    message = error.response.data.message ? error.response.data.message : error.response.status;
  }

  else if (error.error) {
    message = error.error;
  }
  else if (error.message) {
    message = error.message;
  }
  else if (error.response.data) {
    message = error.response.data;
  }
  else if (error.request) {
    // The request was made but no response was received
    // console.error('API Error Request:', error.request);
    message = error.request;
    // Handle network-related errors here (e.g., no internet connection)
  }
  else if (error.errors) {
    message = error.errors;
  }
  else {
    // Something happened in setting up the request
    // console.error('API Error Message:', error.message);
    message = error.message;
    // Handle other errors (e.g., request configuration issues)
  }
  // You can display a user-friendly error message or trigger some action here
  if (message) {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000, // Time in milliseconds. Set to 0 to disable auto-close
      hideProgressBar: false, // Set to true to hide the progress bar
      closeOnClick: true, // Close the toast on click
      pauseOnHover: true, // Pause auto-close on hover
      draggable: true, // Allow to drag the toast
    });
  }
  else if (customErrorMessage) {
    toast.error(customErrorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000, // Time in milliseconds. Set to 0 to disable auto-close
      hideProgressBar: false, // Set to true to hide the progress bar
      closeOnClick: true, // Close the toast on click
      pauseOnHover: true, // Pause auto-close on hover
      draggable: true, // Allow to drag the toast
    });
  }

};