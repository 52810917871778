import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import Dashboard from "./scenes/dashboard";

import Login from "./scenes/Login/login";
import Promotion from "./scenes/HrCore/Promotion";
import Award from "./scenes/HrCore/Award";
import { Provider } from "react-redux";
import store from "./Redux/store";
import EmployeeLists from "./scenes/EmployeeLists/EmployeeLists";
import Details from "./scenes/EmployeeLists/Details";
import UserList from "./scenes/User/UserList";
import Company from "./scenes/Company/Company";
import Department from "./scenes/Department/Departmant";
import Location from "./scenes/Location/Location";
import Designation from "./scenes/Designation/Designation";
import RoleList from "./scenes/Role/RoleList";
import AssignRoleList from "./scenes/Role/AssignRoleList";
import BasicDetails from "./components/Employee/BasicDetails";
import EmergencyDetails from "./components/Employee/EmergencyContact";
import Attandances from "./scenes/Employee/Attandance";
import SocialNetworking from "./components/Employee/SocialNetworking";
import Profile from "./components/Employee/Profile";
import Qualification from "./components/Employee/Qualification";
import WorkExperience from "./components/Employee/WorkExperience";
import BankAccount from "./components/Employee/BankAccount";
import Financials from "./components/Employee/Financials";
import Immigration from "./components/Employee/Immigration";
import AttandanceList from "./scenes/Attandance/AttandanceList";
import Document from "./components/Employee/Document";
import Salary from "./scenes/Employee/Salary";
import SalaryAdavance from "./scenes/Salary/SalaryAdavance";
import HrSalaryAdvance from "./scenes/Hr/HrSalaryAdvance";
import AccountAdvanceSalaryRequest from "./scenes/Accounting/AccountAdvanceSalaryRequest";
import HrAddLoan from "./scenes/Hr/HrAddLoan";
import AdminLoan from "./scenes/Loan/AdminLoan";
import AccountLoanRequest from "./scenes/Accounting/AccountLoanRequest";
import FoodAllowance from "./scenes/Hr/Allowance/FoodAllowance";
import MobileAllowance from "./scenes/Hr/Allowance/MobileAllowance";
import TransportAllowance from "./scenes/Hr/Allowance/TransportAllowance";
import EmpLoan from "./scenes/Employee/EmpLoan";
import EmpSalaryAdvance from "./scenes/Employee/EmpSalaryAdvance";
import Hrattandance from "./scenes/Hr/Hrattandance";
import HrSalaryRecipt from "./scenes/Hr/HrSalaryRecipt";
import TraficDeduction from "./scenes/Hr/Deduction/TraficDeduction";
import MobileDeducation from "./scenes/Hr/Deduction/MobileDeducation";
import LoanDeduction from "./scenes/Hr/Deduction/LoanDeduction";
import ResidencyDeducation from "./scenes/Hr/Deduction/ResidencyDeducation";
import SocailSecurityDeduction from "./scenes/Hr/Deduction/SocailSecurityDeduction";
import Penalty from "./scenes/Hr/Deduction/Penalty";
import Employee from "./scenes/Hr/Employee";
import CustomCalendar from "./scenes/Hr/CustomCalendar";
import SalaryGenerate from "./scenes/Hr/Deduction/SalaryGenerate";
import EmpViewSalary from "./scenes/Employee/EmpViewSalary";
import EmpProvison from "./scenes/Employee/EmpProvison";
import HrPrevison from "./scenes/Hr/HrPrevison";
import EmpAttandance from "./scenes/Employee/EmpAttandance";
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  return (
    <Provider store={store}>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin/details" element={<Details />} />
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/admin/employeelist" element={<EmployeeLists />} />

          <Route path="/admin/company" element={<Company />} />

          <Route path="/admin/promotion" element={<Promotion />} />
          <Route path="/admin/award" element={<Award />} />
          <Route path="/admin/user" element={<UserList />} />
          <Route path="/admin/role" element={<RoleList />} />
          <Route path="/admin/assign_role" element={<AssignRoleList />} />

          <Route path="/admin/attandanceList" element={<AttandanceList />} />
          <Route path="/admin/salary-adavance" element={<SalaryAdavance />} />
          <Route path="/admin/loan" element={<AdminLoan />} />

          {/* employee */}
          <Route path="/employee/attandance" element={<Attandances />} />
          <Route path="/employee/salary" element={<Salary />} />
          <Route path="/employee/loan" element={<EmpLoan />} />
          <Route
            path="/employee/salary-advance"
            element={<EmpSalaryAdvance />}
          />
          <Route path="/employee/view-salary" element={<EmpViewSalary />} />
          <Route path="/employee/EmpProvison" element={<EmpProvison />} />
          <Route path="/employee/total-attance" element={<EmpAttandance />} />

          <Route path="/hr/salary" element={<HrSalaryAdvance />} />
          <Route path="/hr/add-loan" element={<HrAddLoan />} />
          <Route path="/hr/food-allowance" element={<FoodAllowance />} />
          <Route path="/hr/mobile-allowance" element={<MobileAllowance />} />
          <Route
            path="/hr/transport-allowance"
            element={<TransportAllowance />}
          />
          <Route path="/hr/attendance" element={<Hrattandance />} />
          <Route path="/hr/salary-recept" element={<HrSalaryRecipt />} />
          <Route path="/hr/trafic-deduction" element={<TraficDeduction />} />
          <Route path="/hr/mobile-deduction" element={<MobileDeducation />} />
          <Route path="/hr/loan-deduction" element={<LoanDeduction />} />
          <Route
            path="/hr/residency-deduction"
            element={<ResidencyDeducation />}
          />
          <Route
            path="/hr/social-deduction"
            element={<SocailSecurityDeduction />}
          />
          <Route path="/hr/penelty" element={<Penalty />} />
          <Route path="/hr/employee-details" element={<Employee />} />
          <Route path="/hr/leave" element={<CustomCalendar />} />
          <Route path="/hr/generate-salary" element={<SalaryGenerate />} />
          <Route path="/hr/company" element={<Company />} />
          <Route path="/hr/department" element={<Department />} />
          <Route path="/hr/location" element={<Location />} />
          <Route path="/hr/designation" element={<Designation />} />
          <Route path="/hr/provison" element={<HrPrevison />} />
          <Route path="/hr/basic-details/:id" element={<BasicDetails />} />
          <Route path="/hr/document/:id" element={<Document />} />
          <Route
            path="/hr/emergency-contact/:id"
            element={<EmergencyDetails />}
          />
          <Route path="/hr/profile/:id" element={<Profile />} />
          <Route path="/hr/social/:id" element={<SocialNetworking />} />

          <Route path="/hr/qualification/:id" element={<Qualification />} />
          <Route path="/hr/experience/:id" element={<WorkExperience />} />
          <Route path="/hr/bank-account/:id" element={<BankAccount />} />
          <Route path="/hr/financials/:id" element={<Financials />} />
          <Route path="/hr/immigration/:id" element={<Immigration />} />
          {/* Account */}
          <Route
            path="/account/salary/request"
            element={<AccountAdvanceSalaryRequest />}
          />

          <Route
            path="/account/loan/request"
            element={<AccountLoanRequest />}
          />
        </Routes>
      </div>
    </Provider>
  );
}

export default App;
