import React, { useState, useEffect } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InputBase from "@mui/material/InputBase";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Typography,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { addDepartment } from "../../Redux/Slice/Department/AddDepartmentSlice";
import { fetchDepartment } from "../../Redux/Slice/Department/DepartmentListSlice";
import { DeleteDepartmentList } from "../../Redux/Slice/Department/DeleteDepartmentSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchEmployee } from "../../Redux/Slice/Employee/EmployeeListSlice";
import { fetchCompany } from "../../Redux/Slice/Company/CompanyListSlice";
import { updateDepartment } from "../../Redux/Slice/Department/UpdateDepartmentSlice";
import HrThemeProvideWrapper from "../global/Hr/HrThemeProvideWrapper";

export default function Department() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const { department_data, depart_status } = useSelector(
    (state) => state.departmentlist
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchTerm, setSearchTerm] = React.useState("");
  const { data, status } = useSelector((state) => state.employee);
  const loading = useSelector((state) => state.employee.isLoader);
  const error = useSelector((state) => state.employee.isError);

  const [formData, setFormData] = useState({
    name: "",
    department_head: "",
    company: "",
  });
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [employeeData, setEmployeeData] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchEmployee());
        const dataFromResponse = response.payload.data || [];
        setEmployeeData(dataFromResponse);
      } catch (error) {
        console.error("Error fetching Employee data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDepartment);
    dispatch(fetchEmployee);
  }, []);

  const [companyData, setCompanyData] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCompany());
        const dataFromResponse = response.payload.data || [];
        setCompanyData(dataFromResponse);
      } catch (error) {
        console.error("Error fetching Company data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [formErrors, setFormErrors] = useState({
    name: "",
    department_head: "",
    company: "",
  });
  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Department name is required";
    }

    if (!formData.department_head) {
      errors.department_head = "Department Head is required";
    }
    if (!formData.selectedCompany) {
      errors.company = "Company is required";
    }

    return errors;
  };

  const handleForm = () => {
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      // Set errors and prevent form submission
      setFormErrors(errors);
      return;
    }

    // Add selectedLocation to the formData
    const formDataWithLocation = {
      ...formData,
      // department_head: selectedEmployee,
      company: selectedCompany,
    };

    dispatch(addDepartment(formDataWithLocation))
      .then(() => {
        // Reset form data and errors after successful addition
        setFormData({
          name: "",
          department_head: "",
          company: "",
        });
        setFormErrors({});
        setSelectedEmployee(null);
        setSelectedCompany(null);

        // Reload data after successful addition
        dispatch(fetchDepartment());
        // toast.success("Department added successfully!");

        // Close the modal
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding Department:", error);
        toast.error("Error adding Department. Please try again.");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDeleteDepartment = (companyId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this Department?"
    );
    if (isConfirmed) {
      dispatch(DeleteDepartmentList(companyId))
        .then((result) => {
          // Handle success, if necessary
          console.log("Department deleted successfully:", result);
          dispatch(fetchDepartment());
        })
        .catch((error) => {
          // Handle error, if necessary
          console.error("Error deleting Department:", error);
        });
    }
  };

  React.useEffect(() => {
    dispatch(fetchDepartment()); // Refetch department data on component mount
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(fetchDepartment()); // Refetch department data when formData or page/rowsPerPage changes
  }, [formData, page, rowsPerPage, dispatch]);
  // update

  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [editDepartmentData, setEditDepartmentData] = React.useState({
    id: "",
    name: "",
    department_head: "",
    company: "",
  });

  const handleEditModalChange = (field, value) => {
    setEditDepartmentData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    // Dynamically update selectedCompany and selectedDepartment based on the current data
    if (field === "company") {
      setSelectedCompany(value);
    } else if (field === "department_head") {
      setSelectedEmployee(value);
    }
  };

  const handleEditDepartment = () => {
    const { id, name, department_head, company } = editDepartmentData;

    const credentials = {
      id,
      name,
      department_head,
      company,
    };

    // Include default values for dropdowns in the payload
    credentials.department = selectedDepartment || "";
    credentials.company = selectedCompany || "";

    dispatch(updateDepartment(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchDepartment());
        // toast.success("Department updated successfully!");

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Department:", error);
        toast.error("Error updating Department. Please try again.");
      });
  };

  const handleOpenEditModal = (departmentData) => {
    if (departmentData) {
      setSelectedDepartment(departmentData);
      setEditDepartmentData({
        id: departmentData.id,
        name: departmentData.name,
        department_head: departmentData.department_head,
        company: departmentData.company,
      });

      // Set default values for dropdowns based on existing data
      setSelectedCompany(departmentData.company);
      // setSelectedDepartment(departmentData.department);
    } else {
      setSelectedDepartment(null);
      setEditDepartmentData({
        id: "",
        name: "",
        department_head: "",
        company: "",
      });
    }
    setOpenEditModal(true);
  };
  //  ====================== search==============================//
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = Array.isArray(department_data)
    ? department_data.filter((departmentData) => {
        const nameLowerCase = departmentData.name?.toLowerCase() || "";
        const emailLowerCase =
          departmentData.department_head?.toLowerCase() || "";
        return (
          nameLowerCase.includes(searchTerm) ||
          emailLowerCase.includes(searchTerm)
        );
      })
    : [];

  const slicedData =
    filteredData?.length > 0
      ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMenuClick = (event, departmentData) => {
    setAnchorEl(event.currentTarget);
    setSelectedDepartment(departmentData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedDepartment(null);
  };
  const handleMenuItemClick = (action) => {
    handleClose();
    if (action === "delete") {
      handleDeleteDepartment(selectedDepartment.id);
    } else if (action === "edit") {
      handleOpenEditModal(selectedDepartment);
    }
  };
  const cellStyle = {
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#2d388e",
    color: "white",
  };
  const tableStyle = { fontSize: "13px", padding: "4px" };
  const modalText = { fontSize: "10px" };
  return (
    <HrThemeProvideWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",

          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            marginBottom: "20px",
            background: "linear-gradient(to right, #1b2365, #181f5e)",
          }}
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Department
        </Button>
        <InputBase
          sx={{ ml: 2, marginBottom: "20px", width: "250px" }}
          placeholder="Search by Department, Department Head"
          onChange={handleSearch}
          style={{
            color: "#000",
            paddingLeft: "10px",
            padding: "3px",
            paddingRight: "10px",
            borderRadius: "5px",
            backgroundColor: "#fff", // Background color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
            // outline: "none", // Remove outline on focus
            transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
          }}
        />
      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add Department</DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h5>Department</h5>

                  <TextField
                    fullWidth
                    margin="normal"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Company</h5>
                  <Select
                    fullWidth
                    margin="normal"
                    value={selectedCompany || ""}
                    onChange={(e) => setSelectedCompany(e.target.value)}
                    error={!!formErrors.company}
                    helperText={formErrors.company}
                    displayEmpty
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Company
                    </MenuItem>
                    {companyData.map((company) => (
                      <MenuItem key={company.id} value={company.company}>
                        {company.company}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.company && (
                    <p style={{ color: "red", fontSize: "10px" }}>
                      {formErrors.company}
                    </p>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Department Head</h5>

                  <Select
                    fullWidth
                    margin="normal"
                    name="department_head"
                    id="department_head"
                    value={formData.department_head || "Select Department Head"}
                    onChange={handleChange}
                    error={!!formErrors.department_head}
                    helperText={formErrors.department_head}
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="Select Department Head" disabled>
                      Select Department Head
                    </MenuItem>
                    {status == "success" ? (
                      data.map((company) => (
                        <MenuItem key={company.id} value={company.name}>
                          {company.name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                  {formErrors.department_head && (
                    <p style={{ color: "red", fontSize: "10px" }}>
                      {formErrors.department_head}
                    </p>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>

        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleForm}
            sx={{
              width: 100,
              background: "linear-gradient(to right, #1b2365, #181f5e)",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="bottom-right" autoClose={3000} />
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : department_data && department_data.length > 0 ? (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table aria-label="customized table" id="employee_data">
              <TableHead>
                <TableRow>
                  <TableCell style={cellStyle}>Sl. No.</TableCell>
                  <TableCell style={cellStyle}>Department/قسم</TableCell>
                  <TableCell style={cellStyle}>
                    Department Head/رئيس القسم
                  </TableCell>
                  <TableCell style={cellStyle}>Company/شركة</TableCell>
                  <TableCell style={cellStyle}>Action/فعل</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {slicedData.map((departmentData, index) => (
                  <TableRow key={departmentData.id}>
                    <TableCell style={tableStyle}>{index + 1}</TableCell>
                    <TableCell style={tableStyle}>
                      {departmentData.name}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {departmentData.department_head}
                    </TableCell>
                    <TableCell style={tableStyle}>
                      {departmentData.company}
                    </TableCell>

                    <TableCell style={tableStyle}>
                      <IconButton
                        onClick={(e) => handleMenuClick(e, departmentData)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Typography sx={{ p: 1 }}>
                          <IconButton
                            style={{ color: "#2d388e" }}
                            onClick={() => handleMenuItemClick("edit")}
                          >
                            <EditIcon /> <h5 style={modalText}>Edit</h5>
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleMenuItemClick("delete")}
                          >
                            <DeleteIcon /> <h5 style={modalText}>Delete</h5>
                          </IconButton>
                        </Typography>
                      </Popover>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100, -1]}
            component="div"
            count={department_data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
          />
        </React.Fragment>
      ) : (
        <div style={{ padding: "20px" }}>No Employee available</div>
      )}
      <Dialog open={openEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>Update Department/قسم التحديث</DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <input
                  type="hidden"
                  id="eid"
                  value={selectedDepartment ? selectedDepartment.id : ""}
                />
                <Grid item xs={6}>
                  <h5>Department</h5>
                  <TextField
                    id="edepartment"
                    name="name"
                    value={editDepartmentData.name}
                    onChange={(e) =>
                      handleEditModalChange("name", e.target.value)
                    }
                    fullWidth
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Company</h5>
                  <Select
                    label="Company"
                    fullWidth
                    margin="normal"
                    value={editDepartmentData.company || ""}
                    onChange={(e) =>
                      handleEditModalChange("company", e.target.value)
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Company
                    </MenuItem>
                    {companyData.map((company) => (
                      <MenuItem key={company.id} value={company.company}>
                        {company.company}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Department Head</h5>
                  {/* <TextField
                  id="department_head"
                  name="department_head"
                  value={editDepartmentData.department_head}
                  onChange={(e) =>
                    handleEditModalChange("department_head", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                  required
                /> */}
                  <Select
                    fullWidth
                    id="department_head"
                    name="department_head"
                    value={editDepartmentData.department_head}
                    onChange={(e) =>
                      handleEditModalChange("department_head", e.target.value)
                    }
                    sx={{
                      backgroundColor: "rgba(212,216,252, 0.7)",
                    }}
                  >
                    <MenuItem value="Select Company" disabled>
                      Select User
                    </MenuItem>
                    {status == "success" ? (
                      data.map((company) => (
                        <MenuItem key={company.id} value={company.name}>
                          {company.name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>

        <DialogActions>
          <Button onClick={handleCloseEditModal} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleEditDepartment}
            variant="contained"
            sx={{
              width: 100,
              background: "linear-gradient(to right, #1b2365, #181f5e)",
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </HrThemeProvideWrapper>
  );
}
