import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { DataGrid } from "@mui/x-data-grid";
const Details = () => {
  const columns = [{ field: "firstName" }];

  const rows = [
    { id: 1, lastName: "Doe", firstName: "John" },
    { id: 2, lastName: "Smith", firstName: "Jane" },
    // Additional rows go here
  ];

  return (
    <div style={{ height: 400, width: "30%" }}>
      <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
    </div>
  );
};
export default Details;
