import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    Paper,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import EmpThemeProviderWrapper from "../global/Employee/EmployeeThemeProviderWrapper";
import { useDispatch, useSelector } from "react-redux";
import { empFetchSalary } from "../../Redux/Slice/EmployeeSection/EmployeeSalary";

const EmpSalaryAdvance = () => {
    const dispatch = useDispatch();
    const { salary, status } = useSelector((state) => state.empsalary);

    useEffect(() => {
        dispatch(empFetchSalary());
    }, []);

    return (
        <EmpThemeProviderWrapper>
            <Card>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>amount</TableCell>
                                <TableCell> Date</TableCell>
                                <TableCell> Status</TableCell>
                                <TableCell> Salary</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {status === "success" && Array.isArray(salary) && salary.length > 0 ? (
                                salary.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.loan_amount}</TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.salary}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">No data</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </EmpThemeProviderWrapper>
    );
}

export default EmpSalaryAdvance;
