import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
export const DeleteDepartmentList = createAsyncThunk(
  "department/delete",
  async (credentials, { getState }) => {
    const authToken = getState().auth.token;
    try {
      const res = await fetch(`${apiUrl}/api/admin/deparment/delete/` + credentials, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await res.json();

      if (data.status) {
        successmsg(data.message);
        return data;
      }
      else {
        handleApiError(data.errors, "failed");
      }
    }
    catch (error) {
      handleApiError(error, "failed");
    }
    // throw new Error(data.message || "Login failed");
  }
);
const DeleteDepartmentSlice = createSlice({
  name: "deleteDepartment",
  initialState: {
    deleteDepartment: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null, // Add a token field to the state
  },
  extraReducers: (builder) => {
    builder.addCase(DeleteDepartmentList.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(DeleteDepartmentList.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.deleteDepartment = action.payload;
      state.token = action.payload.token;
    });
    builder.addCase(DeleteDepartmentList.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});
export default DeleteDepartmentSlice.reducer;
