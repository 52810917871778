import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const fetchProfile = createAsyncThunk(
    "Employee/fetchProfile",
    async () => {
        const token = getTokenFromLocalStorage();
        const res = await fetch(`${apiUrl}/api/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
        });

        const final = await res.json();
        // console.log(final);
        return final.data;
    }
);

const ProfileSlice = createSlice({
    name: "profile",
    initialState: {
        profileData: null,
        isLoader: false,
        isError: false,
        profilestatus: 'idel',
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfile.pending, (state) => {
            state.isLoader = true;
            state.profilestatus = "pending";
        });
        builder.addCase(fetchProfile.fulfilled, (state, action) => {
            state.isLoader = false;
            state.profilestatus = "success";
            state.profileData = action.payload;
        });
        builder.addCase(fetchProfile.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
            state.profileData =null;
            state.profilestatus = "rejected";
        });
    },
});

export default ProfileSlice.reducer;
