import EmpThemeProviderWrapper from "../global/Employee/EmployeeThemeProviderWrapper";
import {
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { getprevision } from "../../Redux/Slice/EmployeeSection/AddAttandance";
import Card from "@mui/material/Card";
import { useEffect, state } from "react";
import { useDispatch, useSelector } from "react-redux";
const EmpProvison = () => {
    const dispatch = useDispatch();
    const { attandances, attandanceStatus } = useSelector((state) => state.addAttandance);
    useEffect(() => {
        dispatch(getprevision());
    }, []);
    console.log(attandances);
    return (
        <EmpThemeProviderWrapper>
            <Card>
                <CardHeader>
                    Provision
                </CardHeader>
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Total Previson</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attandanceStatus == 'success'  && attandances? (

                                <TableRow>
                                    <TableCell>{attandances.id}</TableCell>
                                    <TableCell>{attandances.name}</TableCell>
                                    <TableCell>{attandances.privison}</TableCell>
                                </TableRow>
                            ) : ("No data found")}

                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </EmpThemeProviderWrapper>
    )

}
export default EmpProvison;