import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import PhotoIcon from "@mui/icons-material/Photo";
import EmergencyIcon from "@mui/icons-material/Warning";
import DocumentIcon from "@mui/icons-material/Description";
import QualificationIcon from "@mui/icons-material/School";
import ExperienceIcon from "@mui/icons-material/Work";
import BankAccountIcon from "@mui/icons-material/AccountBalance";
import FinancialsIcon from "@mui/icons-material/Money";

const ViewEmployeeDetailsNav = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const menuStyle = {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ccc",
    borderRadius: "10px", // Border for the entire menu
  };

  const menuItemStyle = {
    padding: "10px",
    borderBottom: "1px solid #ccc", // Border between menu items
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    marginRight: "8px", // Adjust the margin as needed
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const handleOpenEmpDetails = () => {
    navigate(`/hr/basic-details/${id}`);
  };

  return (
    <div
      style={{
        background: "linear-gradient(to right, #1b2365, #2d388e)",
        color: "white",
        borderRadius: "5px",
      }}
    >
      <div style={menuStyle}>
        <div style={menuItemStyle} onClick={handleOpenEmpDetails}>
          <PersonIcon style={iconStyle} /> Basic Information
        </div>
        <div
          style={menuItemStyle}
          onClick={() => handleMenuClick(`/hr/profile/${id}`)}
        >
          <PhotoIcon style={iconStyle} /> Profile Picture
        </div>
        <div
          style={menuItemStyle}
          onClick={() => handleMenuClick(`/hr/emergency-contact/${id}`)}
        >
          <EmergencyIcon style={iconStyle} /> Emergency Contacts
        </div>
        <div
          style={menuItemStyle}
          onClick={() => handleMenuClick(`/hr/document/${id}`)}
        >
          <DocumentIcon style={iconStyle} /> Document
        </div>
        <div
          style={menuItemStyle}
          onClick={() => handleMenuClick(`/hr/qualification/${id}`)}
        >
          <QualificationIcon style={iconStyle} /> Qualification
        </div>
        <div
          style={menuItemStyle}
          onClick={() => handleMenuClick(`/hr/experience/${id}`)}
        >
          <ExperienceIcon style={iconStyle} /> Work Experience
        </div>
        <div
          style={menuItemStyle}
          onClick={() => handleMenuClick(`/hr/bank-account/${id}`)}
        >
          <BankAccountIcon style={iconStyle} /> Bank Account
        </div>
        <div
          style={menuItemStyle}
          onClick={() => handleMenuClick(`/hr/financials/${id}`)}
        >
          <FinancialsIcon style={iconStyle} /> Financials
        </div>
      </div>
    </div>
  );
};

export default ViewEmployeeDetailsNav;
