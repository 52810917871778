import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import axios from "axios";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
export const AddDesignation = createAsyncThunk(
    "employees/addEmployee",
    async (employeeData, { getState }) => {
        const authToken = getState().auth.token;
        // console.log("TokenAddEmp:", authToken);

        try {
            const res = await axios.post(`${apiUrl}/api/admin/designation/add`, employeeData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            });

            const data = await res.data;
            if (data.status) {
                successmsg(data.message);
            }
            else {
                handleApiError(data.errors, "failed");
            }
            return data;
        } catch (error) {
            handleApiError(error, "failed");
        }
    }
);

const AddDesignationSlice = createSlice({
    name: "addEmployee",
    initialState: {
        isAdding: false,
        isError: false,
        employee: null,
    },
    reducers: {
        resetState: (state) => {
            state.isAdding = false;
            state.isError = false;
            state.employee = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(AddDesignation.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(AddDesignation.fulfilled, (state, action) => {
            state.isAdding = false;
            state.employee = action.payload.data;
        });
        builder.addCase(AddDesignation.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
    },
});

export default AddDesignationSlice.reducer;
