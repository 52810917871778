import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { successmsg } from "../../../Helpers/successmsg";
import { handleApiError } from "../../../Helpers/handleApiError";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchEmployee = createAsyncThunk(
  "Employee/fetchEmployee",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/api/admin/employee`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();

    return final;
  }
);
// 2
export const employeeDetails = createAsyncThunk(
  "Employee/employeeDetails",
  async (credentials) => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(
      `${apiUrl}/api/admin/employee/edit/` + credentials,
      {
        method: "Get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const final = await res.json();

    return final;
  }
);

// 3
export const EmergencyContact = createAsyncThunk(
  "Employee/emergencyContact",
  async (credentials) => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(
      `${apiUrl}/api/admin/employee/emergencycontact/` + credentials,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const final = await res.json();

    return final;
  }
);

//  4
export const FinancialsAdd = createAsyncThunk(
  "Employee/financialAdd",
  async (credentials) => {
    console.log("call");
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/admin/employee/financials/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });

      const final = await res.json();

      if (final.status) {
        successmsg(final.message);
        return final;
      } else {
        handleApiError(final.errors, "failed");
      }
      return final;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);
//  5
export const Financial = createAsyncThunk(
  "Employee/fetchFinancial",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(
        `${apiUrl}/api/admin/employee/financials/` + credentials,
        {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
          // body: JSON.stringify(credentials),
        }
      );

      const final = await res.json();
      return final.data;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);
//  6
export const fetchDocument = createAsyncThunk(
  "Employee/fetchDocument",
  async (credentials) => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(
      `${apiUrl}/api/admin/employee/document/` + credentials,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        // body: JSON.stringify(credentials),
      }
    );

    const final = await res.json();

    return final;
  }
);
// 7
export const fetchQualification = createAsyncThunk(
  "Employee/fetchQualification",
  async (credentials) => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(
      `${apiUrl}/api/admin/employee/qualification/` + credentials,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      }
    );

    const final = await res.json();

    return final;
  }
);

//  8
export const adminSearchBySalary = createAsyncThunk(
  "Employee/adminSearchBySalary",
  async (credentials) => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(
      `${apiUrl}/api/admin/employee/search/` + credentials,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        // body: JSON.stringify(credentials),
      }
    );

    const final = await res.json();

    return final;
  }
);

// 9
export const downloadExcel = createAsyncThunk(
  "Employee/downloadExcel",
  async (credentials) => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/api/admin/employee/Download`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });

    // Check if the request was successful (status code 2xx)
    if (!res.ok) {
      throw new Error(`Error: ${res.status} - ${res.statusText}`);
    }

    // Extract the Blob from the response
    const blob = await res.blob();

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "filename.xlsx"; // Provide a default filename for the downloaded file

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Return a success message or any other relevant information
    return { success: true, message: "File downloaded successfully" };
  }
);
//10
export const fetch_work_experience = createAsyncThunk(
  "Employee/work_experience",
  async (credentials) => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(
      `${apiUrl}/api/admin/employee/experience/` + credentials,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        // body: JSON.stringify(credentials),
      }
    );

    const final = await res.json();

    return final.data;
  }
);

// 11
export const add_document = createAsyncThunk(
  "Employee/add_document",
  async (credentials) => {
    console.log("data", credentials);
    const token = getTokenFromLocalStorage();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("id", credentials.data.id);

      // formDataToSend.append('type', credentials.type);

      if (credentials.form_type == "dl") {
        formDataToSend.append("license", credentials.data.license);
        formDataToSend.append("lincense_no", credentials.data.lincense_no);
        formDataToSend.append(
          "lincense_valid",
          credentials.data.lincense_valid
        );
        formDataToSend.append("vechile_type", credentials.data.vechile_type);
        formDataToSend.append("type", credentials.form_type);
      }
      if (credentials.form_type == "passport") {
        formDataToSend.append("passport_no", credentials.data.passport_no);
        formDataToSend.append(
          "passport_valid",
          credentials.data.passport_valid
        );
        formDataToSend.append("passport_image", credentials.data.passport);
        formDataToSend.append("type", credentials.form_type);
      }
      if (credentials.form_type == "civil") {
        formDataToSend.append("civil_id", credentials.data.civil_id);
        formDataToSend.append("civil", credentials.data.civil);
        formDataToSend.append(
          "civil_id_valid",
          credentials.data.civil_id_valid
        );
        formDataToSend.append("civil_id_upto", credentials.data.civil_id_upto);
        formDataToSend.append("type", credentials.form_type);
      }
      if (credentials.form_type == "visa") {
        formDataToSend.append("visa_no", credentials.data.visa_no);
        formDataToSend.append(
          "visa_issue_date",
          credentials.data.visa_issue_date
        );
        formDataToSend.append("visa_valid", credentials.data.visa_valid);
        formDataToSend.append("visa", credentials.data.visa);
        formDataToSend.append("type", credentials.form_type);
      }
      if (credentials.form_type == "immigration") {
        formDataToSend.append(
          "immigration_issue_country",
          credentials.data.immigration_issue_country
        );
        formDataToSend.append(
          "immigration_issue_date",
          credentials.data.immigration_issue_date
        );
        formDataToSend.append(
          "immigration_valid",
          credentials.data.immigration_valid
        );
        formDataToSend.append(
          "immigration_review",
          credentials.data.immigration_review
        );
        formDataToSend.append("immigration", credentials.data.immigration);
        formDataToSend.append("type", credentials.form_type);
      }
      const res = await fetch(`${apiUrl}/api/admin/employee/document/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend,
      });
      const final = await res.json();
      if (final.status) {
        successmsg(final.message);
      } else {
        handleApiError(final.errors, "failed");
      }

      return final;
    } catch (error) {
      handleApiError(error, "failed");
      throw error;
    }
  }
);

//12
export const EmergencyContact_update = createAsyncThunk(
  "Employee/EmergencyContact_update",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(
        `${apiUrl}/api/admin/employee/emergencycontactSave`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
          body: JSON.stringify(credentials),
        }
      );

      const final = await res.json();
      if (final.status) {
        successmsg(final.message);
      } else {
        handleApiError(final.errors, "failed");
      }
      return final;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);

// 13
export const update_work_experience = createAsyncThunk(
  "Employee/update_work_experience",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("id", credentials.id);
      formDataToSend.append("user_id", credentials.user_id);
      formDataToSend.append("name", credentials.name);
      formDataToSend.append("post", credentials.post);
      formDataToSend.append("form", credentials.form);
      formDataToSend.append("post", credentials.post);
      formDataToSend.append("to", credentials.to);
      formDataToSend.append("document", credentials.document);
      formDataToSend.append("description", credentials.description);
      const res = await fetch(
        `${apiUrl}/api/admin/employee/experience/update`,
        {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // Accept: "*/*",
          },
          body: formDataToSend,
        }
      );

      const final = await res.json();
      if (final.status) {
        successmsg(final.message);
      } else {
        handleApiError(final.errors, "failed");
      }
      return final;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);

// 14
export const updateQualification = createAsyncThunk(
  "Employee/updateQualification",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/admin/employee/education/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });

      const final = await res.json();
      if (final.status) {
        successmsg(final.message);
      } else {
        handleApiError(final.errors, "failed");
      }
      return final.data;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);

// 15

export const fetchbank = createAsyncThunk(
  "Employee/fetchbank",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(
        `${apiUrl}/api/admin/employee/bank/` + credentials,
        {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
          // body: JSON.stringify(credentials),
        }
      );

      const final = await res.json();
      if (final.status) {
        // successmsg(final.mess age);
      } else {
        handleApiError(final.errors, "failed");
      }
      return final.data;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);

// 16
export const bank_info_update = createAsyncThunk(
  "Employee/bank_info_update",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/admin/employee/bank/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });

      const final = await res.json();
      if (final.status) {
        successmsg(final.message);
      } else {
        handleApiError(final.errors, "failed");
      }
      return final.data;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);

//  17
export const employeeProfileUpdate = createAsyncThunk(
  "Employee/employeeProfileUpdate",
  async (credentials) => {
    console.log(credentials);
    const token = getTokenFromLocalStorage();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("id", credentials.id);
      formDataToSend.append("image", credentials.file);

      const res = await fetch(`${apiUrl}/api/admin/employee/profile/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend,
      });
      const final = await res.json();
      if (final.status) {
        successmsg(final.message);
      } else {
        handleApiError(final.errors, "failed");
      }

      return final;
    } catch (error) {
      handleApiError(error, "failed");
      throw error;
    }
  }
);

// 18
export const addQualification = createAsyncThunk(
  "Employee/addQualification",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/admin/employee/education/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });

      const final = await res.json();
      if (final.status) {
        successmsg(final.message);
      } else {
        handleApiError(final.errors, "failed");
      }
      return final.data;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);

//19

export const addWorkExperience = createAsyncThunk(
  "Employee/addWorkExperience",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", credentials.user_id);
      formDataToSend.append("name", credentials.name);
      formDataToSend.append("post", credentials.post);
      formDataToSend.append("form", credentials.form);
      formDataToSend.append("post", credentials.post);
      formDataToSend.append("to", credentials.to);
      formDataToSend.append("document", credentials.document);
      formDataToSend.append("description", credentials.description);
      const res = await fetch(`${apiUrl}/api/admin/employee/experience/add`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // Accept: "*/*",
        },
        body: formDataToSend,
      });

      const final = await res.json();
      if (final.status) {
        successmsg(final.message);
      } else {
        handleApiError(final.errors, "failed");
      }
      return final;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);

const EmployeeSlice = createSlice({
  name: "Employee",
  initialState: {
    data: [],
    isLoader: false,
    isError: false,
    status: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployee.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(fetchEmployee.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload.data;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(fetchEmployee.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    // 2
    builder.addCase(employeeDetails.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(employeeDetails.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload.data;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(employeeDetails.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 3
    builder.addCase(EmergencyContact.pending, (state) => {
      
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(EmergencyContact.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload.data;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(EmergencyContact.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    //  4

    builder.addCase(FinancialsAdd.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(FinancialsAdd.fulfilled, (state, action) => {
      state.isLoader = false;
      state.data = action.payload.data;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(FinancialsAdd.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    // 5

    builder.addCase(Financial.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(Financial.fulfilled, (state, action) => {
      state.isLoader = false;
      state.status = "success";
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(Financial.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 6
    builder.addCase(fetchDocument.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(fetchDocument.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload.data;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(fetchDocument.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    // 7
    builder.addCase(fetchQualification.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(fetchQualification.fulfilled, (state, action) => {
      state.isLoader = false;
      state.status = "success";
      state.data = action.payload.data;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(fetchQualification.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 8
    builder.addCase(adminSearchBySalary.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(adminSearchBySalary.fulfilled, (state, action) => {
      state.isLoader = false;
      state.data = action.payload.data;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(adminSearchBySalary.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    //  9
    builder.addCase(downloadExcel.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(downloadExcel.fulfilled, (state, action) => {
      state.isLoader = false;
      state.data = action.payload.data;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(downloadExcel.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 10
    builder.addCase(fetch_work_experience.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(fetch_work_experience.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(fetch_work_experience.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    //11
    builder.addCase(add_document.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(add_document.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(add_document.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 12
    builder.addCase(EmergencyContact_update.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(EmergencyContact_update.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(EmergencyContact_update.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    //13
    builder.addCase(update_work_experience.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(update_work_experience.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(update_work_experience.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 14
    builder.addCase(updateQualification.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(updateQualification.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(updateQualification.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 15
    builder.addCase(fetchbank.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(fetchbank.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(fetchbank.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 16
    builder.addCase(bank_info_update.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(bank_info_update.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(bank_info_update.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    // 17

    builder.addCase(employeeProfileUpdate.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(employeeProfileUpdate.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(employeeProfileUpdate.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    //18
    builder.addCase(addQualification.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(addQualification.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(addQualification.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    //19
    builder.addCase(addWorkExperience.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(addWorkExperience.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
      // console.log("Fetched Employee Dataaa:", action.payload.data);
    });
    builder.addCase(addWorkExperience.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default EmployeeSlice.reducer;
