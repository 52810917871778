import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchDesignation = createAsyncThunk(
  "Designation/fetchDesignation",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/api/admin/designation`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();

    return final;
  }
);

const DesignationSlice = createSlice({
  name: "Designation",
  initialState: {
    designation_data: [],
    isLoader: false,
    isError: false,
    designation_status: 'pending',
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDesignation.pending, (state) => {
      state.isLoader = true;
      state.isError = false; // Reset error status on pending
    });
    builder.addCase(fetchDesignation.fulfilled, (state, action) => {
      state.isLoader = false;
      state.designation_status = 'success';
      state.designation_data = action.payload.data;
      // console.log("Fetched Designation Dataaa:", action.payload.data);
    });
    builder.addCase(fetchDesignation.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default DesignationSlice.reducer;
