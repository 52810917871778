import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const fetchAttandance = createAsyncThunk(
    "Employee/attandance",
    async () => {
        const token = getTokenFromLocalStorage();
        const res = await fetch(`${apiUrl}/api/admin/attandance`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
        });

        const final = await res.json();
        // console.log(final);
        return final.data;
    }
);

const AttandanceSlice = createSlice({
    name: "Attandance",
    initialState: {
        attandanceData: null,
        isLoader: false,
        isError: false,
        status: 'pending',
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAttandance.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchAttandance.fulfilled, (state, action) => {
            state.isLoader = false;
            state.status = "success";
            state.attandanceData = action.payload;
            console.log("Attandance data:", action.payload);
        });
        builder.addCase(fetchAttandance.rejected, (state) => {
            state.isLoader = false;
            state.isError = true;
        });
    },
});

export default AttandanceSlice.reducer;
