import React, { useEffect } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import EmpThemeProviderWrapper from "../global/Employee/EmployeeThemeProviderWrapper";
import { useDispatch, useSelector } from "react-redux";
import { empfetchLoan } from "../../Redux/Slice/EmployeeSection/EmployeeSalary";

const EmpLoan = () => {
    const dispatch = useDispatch();
    const { loanData, status } = useSelector((state) => state.empsalary);

    useEffect(() => {
        dispatch(empfetchLoan());
    }, []);

    return (
        <EmpThemeProviderWrapper>
            <Card>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Salary</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {status === "success" && loanData && Array.isArray(loanData) && loanData.length > 0 ? (
                                loanData.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.loan_amount}</TableCell>
                                        <TableCell>{row.date}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.salary}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">No data</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </EmpThemeProviderWrapper>
    );
}

export default EmpLoan;
