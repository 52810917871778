import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchcountry = createAsyncThunk(
  "auth/fetchcountry",
  async (credentials) => {
    // const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`https://hrms.testingruminate.co.in/public/api/country`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        //   Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      if (res.status) {
        successmsg(res.message);
      }
      else {
        handleApiError(res.errors, 'failed');
      }
      return res.data;
    }
    catch (error) {
      handleApiError(error, 'failed');
    }
  }

);

const CountrySlice = createSlice({
  name: "country",
  initialState: {
    countryData: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
    countrySatus: 'pending',
  },
  extraReducers: (builder) => {
    builder.addCase(fetchcountry.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(fetchcountry.fulfilled, (state, action) => {
      state.countrySatus ='success';
        state.isLoader = false;
      state.isAuthenticated = true;
      state.countryData = action.payload;
    //   state.token = action.payload.token;
    console.log("coutry data",action );
    });
    builder.addCase(fetchcountry.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default CountrySlice.reducer;
