import React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ViewEmployeeDetailsNav from "./ViewEmployeeDetailsNav";
import HrThemeProvideWrapper from '../../scenes/global/Hr/HrThemeProvideWrapper';
const SocialNetworking = () => {
    return (
        <HrThemeProvideWrapper>
        <div style={containerStyle}>
            <div style={navStyle}>
                <ViewEmployeeDetailsNav />
            </div>
            <div style={detailsStyle}>
                <Card>
                    <CardHeader title="Social Networking" />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            <form >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Favebook Profile"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Twitter Profile"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label="Blogger Profile"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label="Linkedin Profile"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label="Google Plus Profile"
                                            type="email"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label="Instagram Profile"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label="Pinterest Profile"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label="Youtube Profile"
                                            type="email"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
        </HrThemeProvideWrapper>
    );
}
const containerStyle = {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
};

const navStyle = {
    flex: "0 0 30%",
    marginTop: "30px",
    padding: "20px",
};

const detailsStyle = {
    flex: "1",
    padding: "20px",
};


export default SocialNetworking;