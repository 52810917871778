import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchCompany = createAsyncThunk(
  "Company/fetchCompany",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/api/admin/company`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const CompanySlice = createSlice({
  name: "Company",
  initialState: {
    companyData: null,
    isLoader: false,
    isError: false,
    comapny_status:'pending',
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompany.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchCompany.fulfilled, (state, action) => {
      state.isLoader = false;
      state.comapny_status ='success';
      state.companyData = action.payload;
      console.log("Company data:", action.payload);
    });
    builder.addCase(fetchCompany.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default CompanySlice.reducer;
