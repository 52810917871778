import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel } from "@mui/material";
import { useParams } from "react-router-dom";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ViewEmployeeDetailsNav from "./ViewEmployeeDetailsNav";
import { addEmployee } from "../../Redux/Slice/Employee/AddEmployeeSlice";
import {
  updateEmployee,
  allCoutries,
} from "../../Redux/Slice/Employee/UpdateEmployeeSlice";
import { employeeDetails } from "../../Redux/Slice/Employee/EmployeeListSlice";
import { useDispatch, useSelector } from "react-redux";
import HrThemeProvideWrapper from "../../scenes/global/Hr/HrThemeProvideWrapper";
import { fetchDepartment } from "../../Redux/Slice/Department/DepartmentListSlice";
import { fetchDesignation } from "../../Redux/Slice/Designation/DesignationListSlice";
import { fetchCompany } from "../../Redux/Slice/Company/CompanyListSlice";
import { fetchcountry } from "../../Redux/Slice/Employee/CountrySlice";
const BasicDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.employee);
  const { countryData, countrySatus } = useSelector((state) => state.country);
  console.log("chat ", countryData);
  const { department_data, depart_status } = useSelector(
    (state) => state.departmentlist
  );
  const { designation_data, designation_status } = useSelector(
    (state) => state.designation
  );

  const { companyData, comapny_status } = useSelector((state) => state.company);

  const [formData, setFormData] = useState({
    id: data?.id,
    name: data?.name,
    middle_name: data?.middle_name,
    last_name: data?.last_name,
    staff_id: data?.staff_id,
    username: data?.username,
    email: data?.email,
    phone: data?.phone,
    dob: data?.dob,
    gender: data?.gender,
    company: data?.company,
    department: data?.department,
    designation: data?.designation,
    office_shift: data?.office_shift,
    role: data?.role,
    password: data?.password,
    dob_of_joining: data?.dob_of_joining, // Set to the appropriate initial value for DatePicker
    attendance_type: data?.attendance_type,
    emp_code: data?.emp_code,
    date_of_leaving: data?.date_of_leaving,
    citizen: data?.citizen,
    emp_type: data?.type,
    country: data?.country,
    visa_counrty: data?.visa_counrty,
    show_company: data?.show_country,
    // ... (other properties)
  });
  const [selectedValue, setSelectedValue] = useState("male");
  console.log("formData", formData);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(employeeDetails(id));
    dispatch(fetchDepartment());
    dispatch(fetchDesignation());
    dispatch(fetchCompany());
    dispatch(fetchcountry());
  }, [status]);
  useEffect(() => {
    if (status == "success") {
      setFormData({
        id: data?.id,
        name: data?.name,
        middle_name: data?.middle_name,
        last_name: data?.last_name,
        staff_id: data?.staff_id,
        username: data?.username,
        email: data?.email,
        phone: data.phone,
        dob: data?.dob,
        gender: data?.gender,
        company: data?.company,
        department: data?.department,
        designation: data?.designation,
        office_shift: data?.office_shift,
        role: data?.role,
        dob_of_joining: data?.dob_of_joining, // Set to the appropriate initial value for DatePicker
        attendance_type: data?.attendance_type,
        emp_code: data?.emp_code,
        date_of_leaving: data?.date_of_leaving,
        // gender: data?.gender,
        emp_type: data?.type,
        citizen: data?.citizen,
        country: data?.country,
        visa_counrty: data?.visa_counrty,
        show_company: data?.show_country,
      });
    }
  }, [data, status]);

  console.log(formData);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // const handleAddEmployee =() =>{
  //   alert();
  // }
  const handleAddEmployee = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    dispatch(updateEmployee(formData));
  };
  const countries = [
    { value: "afghanistan" },
    { value: "armenia" },
    { value: "azerbaijan" },
    { value: "bahrain" },
    { value: "bangladesh" },
    { value: "bhutan" },
    { value: "brunei" },
    { value: "cambodia" },
    { value: "china" },
    { value: "cyprus" },
    { value: "georgia" },
    { value: "india" },
    { value: "indonesia" },
    { value: "iran" },
    { value: "iraq" },
    { value: "israel" },
    { value: "japan" },
    { value: "jordan" },
    { value: "kazakhstan" },
    { value: "kuwait" },
    { value: "kyrgyzstan" },
    { value: "laos" },
    { value: "lebanon" },
    { value: "malaysia" },
    { value: "maldives" },
    { value: "mongolia" },
    { value: "myanmar" },
    { value: "nepal" },
    { value: "north korea" },
    { value: "oman" },
    { value: "pakistan" },
    { value: "palestine" },
    { value: "philippines" },
    { value: "qatar" },
    { value: "saudi arabia" },
    { value: "singapore" },
    { value: "south korea" },
    { value: "sri lanka" },
    { value: "syria" },
    { value: "tajikistan" },
    { value: "thailand" },
    { value: "timor-leste" },
    { value: "turkey" },
    { value: "turkmenistan" },
    { value: "united arab emirates" },
    { value: "uzbekistan" },
    { value: "vietnam" },
    { value: "yemen" },

    // Add more countries as needed
  ];

  return (
    <HrThemeProvideWrapper>
      <div style={containerStyle}>
        <div style={navStyle}>
          <ViewEmployeeDetailsNav />
        </div>
        <div style={detailsStyle}>
          <Card style={detailsStyle2}>
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <CardHeader title="Basic information" />
              {status == "success" ? (
                <>
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      <form>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              First Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              name="name"
                              value={formData.name}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Middle Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              name="middle_name"
                              value={formData.middle_name}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Last Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              name="last_name"
                              value={formData.last_name}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Employee Code
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              name="emp_code"
                              value={formData.emp_code}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid>
                          {/* <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              User Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              name="username"
                              value={formData.username}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid> */}
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Email
                            </Typography>
                            <TextField
                              type="email"
                              variant="outlined"
                              margin="normal"
                              name="email"
                              value={formData.email}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="h6"
                              style={{ marginBottom: "20px" }}
                              gutterBottom
                            >
                              Company
                            </Typography>
                            <Select
                              fullWidth
                              margin="normal"
                              name="company"
                              value={formData.company || "Select Company"}
                              onChange={(e) => handleInput(e)}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Company" disabled>
                                Select Company
                              </MenuItem>
                              {comapny_status == "success" ? (
                                companyData.data.map((company) => (
                                  <MenuItem
                                    key={company.id}
                                    value={company.company}
                                  >
                                    {company.company}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Department
                            </Typography>
                            <Select
                              fullWidth
                              name="department"
                              value={formData.department || "Select Department"}
                              onChange={(e) => handleInput(e)}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Department" disabled>
                                Select Department
                              </MenuItem>
                              {depart_status == "success" ? (
                                department_data.map((department) => (
                                  <MenuItem
                                    key={department.id}
                                    value={department.name}
                                  >
                                    {department.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Designation
                            </Typography>
                            <Select
                              fullWidth
                              name="designation"
                              value={
                                formData.designation || "Select Designation"
                              }
                              onChange={(e) => handleInput(e)}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Designation" disabled>
                                Select Designation
                              </MenuItem>
                              {designation_status == "success" ? (
                                designation_data.map((designation) => (
                                  <MenuItem
                                    key={designation.id}
                                    value={designation.designation}
                                  >
                                    {designation.designation}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </Grid>
                          {/* <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Role
                            </Typography>

                            <Select
                              fullWidth
                              name="role"
                              value={formData.role || ""}
                              onChange={(e) => handleInput(e)}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Company" disabled>
                                Select Role
                              </MenuItem>
                              <MenuItem value="1" selected={formData.role == "1"}>Admin</MenuItem>
                              <MenuItem value="2" selected={formData.role == "2"}>Hr</MenuItem>
                              <MenuItem value="3" selected={formData.role == "3"}>Account</MenuItem>
                              <MenuItem value="4" selected={formData.role == "4"}>Employee</MenuItem>
                              <MenuItem value="5" selected={formData.role == "5"}>Factory</MenuItem>
                            </Select>
                          </Grid> */}

                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Role
                            </Typography>

                            <Select
                              fullWidth
                              name="role"
                              value={formData.role || ""}
                              onChange={(e) => handleInput(e)}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value={1} selected={formData.role == 1}>
                                Admin
                              </MenuItem>
                              <MenuItem value={2} selected={formData.role == 2}>
                                Hr
                              </MenuItem>
                              <MenuItem value={3} selected={formData.role == 3}>
                                Account
                              </MenuItem>
                              <MenuItem value={4} selected={formData.role == 4}>
                                Employee
                              </MenuItem>
                              <MenuItem value={5} selected={formData.role == 5}>
                                Factory
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Date of Birth
                            </Typography>
                            <TextField
                              type="date"
                              variant="outlined"
                              name="dob"
                              value={formData.dob}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Date of Joining
                            </Typography>
                            <TextField
                              type="date"
                              variant="outlined"
                              name="dob_of_joining"
                              value={formData.dob_of_joining}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>
                              Date of Leaving
                            </Typography>
                            <TextField
                              type="date"
                              variant="outlined"
                              name="dob_of_joining"
                              value={formData.date_of_leaving}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                              Gender
                            </Typography>
                            <Select
                              fullWidth
                              margin="normal"
                              name="gender"
                              value={formData.gender || ""}
                              onChange={(e) =>
                                handleInput("gender", e.target.value)
                              }
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Gender
                              </MenuItem>
                              <MenuItem value="male">Male</MenuItem>
                              <MenuItem value="female">Female</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                              Citizen Type
                            </Typography>
                            <Select
                              fullWidth
                              margin="normal"
                              name="citizen"
                              value={formData.citizen || ""}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Citizen
                              </MenuItem>
                              <MenuItem value="kuwaiti/كويتي">Kuwaiti</MenuItem>
                              <MenuItem value="non-kuwaiti/بدون">
                                Non Kuwaiti
                              </MenuItem>
                              <MenuItem value="outside-residence/إقامة خارجية">
                                Outside residence
                              </MenuItem>
                            </Select>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                              Citizen
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={formData.citizen}
                              fullWidth
                              required
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                              Employee Type
                            </Typography>
                            <Select
                              fullWidth
                              margin="normal"
                              name="emp_type"
                              value={formData.emp_type || ""}
                              onChange={(e) => handleInput(e)}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Type
                              </MenuItem>
                              <MenuItem value="office_employee">
                                Office Employee
                              </MenuItem>
                              <MenuItem value="factory_employee">
                                Factory Employee
                              </MenuItem>
                            </Select>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                              Contact Number
                            </Typography>
                            <TextField
                              variant="outlined"
                              name="phone"
                              value={formData.phone || ""}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                              Visa in company
                            </Typography>
                            <Select
                              fullWidth
                              margin="normal"
                              name="visa_counrty"
                              value={
                                formData.visa_counrty ||
                                "Select Visa in company"
                              }
                              onChange={(e) => handleInput(e)}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Visa in company" disabled>
                                Select Visa in company
                              </MenuItem>
                              {comapny_status == "success" ? (
                                companyData.data.map((company) => (
                                  <MenuItem
                                    key={company.id}
                                    value={company.company}
                                  >
                                    {company.company}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                              To be shown in company
                            </Typography>
                            <Select
                              fullWidth
                              margin="normal"
                              name="show_company"
                              value={formData.show_company || "Select Company"}
                              onChange={(e) => handleInput(e)}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Company" disabled>
                                Select Company
                              </MenuItem>
                              {comapny_status == "success" ? (
                                companyData.data.map((company) => (
                                  <MenuItem
                                    key={company.id}
                                    value={company.company}
                                  >
                                    {company.company}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                              Country
                            </Typography>
                            <Select
                              fullWidth
                              margin="normal"
                              name="country"
                              value={formData.country || "Select Country"}
                              // defaultValue={formData.country}
                              onChange={handleInput}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Country" disabled>
                                Select Country
                              </MenuItem>
                              {countries.map((country, index) => (
                                <MenuItem key={index} value={country.value}>
                                  {country.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="h6" gutterBottom>
                              Office Shift Time
                            </Typography>

                            <Select
                              fullWidth
                              name="office_shift"
                              value={
                                formData.office_shift || "Select Office Shift"
                              }
                              onChange={(e) => handleInput(e)}
                              sx={{
                                backgroundColor: "rgba(212,216,252, 0.7)",
                              }}
                            >
                              <MenuItem value="Select Office Shift" disabled>
                                Select Office Shift
                              </MenuItem>
                              <MenuItem
                                value="08:00:00 To 16:00:00"
                                selected={
                                  formData.office_shift ===
                                  "08:00:00 To 16:00:00"
                                }
                              >
                                8.00 To 16.00
                              </MenuItem>
                              <MenuItem
                                value="08:30:00 To 16:30:00"
                                selected={
                                  formData.office_shift ===
                                  "08:30:00 To 16:30:00"
                                }
                              >
                                8.30 To 16.30
                              </MenuItem>
                              <MenuItem
                                value="09:00:00 To 17:00:00"
                                selected={
                                  formData.office_shift ===
                                  "09:00:00 To 17:00:00"
                                }
                              >
                                9.00 To 17.00
                              </MenuItem>
                              <MenuItem
                                value="09:30:00 To 17:30:00"
                                selected={
                                  formData.office_shift ===
                                  "09:30:00 To 17:30:00"
                                }
                              >
                                9.30 To 17.30
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={2}></Grid>
                          <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={handleAddEmployee}
                              sx={{
                                width: 200,
                                background:
                                  "linear-gradient(to right, #1b2365, #181f5e)",
                              }}
                            >
                              Update
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Typography>
                  </CardContent>
                </>
              ) : (
                <>
                  <div style={{ padding: "20px" }}>No Data available</div>
                </>
              )}
            </div>
          </Card>
        </div>
      </div>
    </HrThemeProvideWrapper>
  );
};
const containerStyle = {
  display: "flex",
  //   flexDirection: "row",
};

const navStyle = {
  flex: "0 0 17%",
};

const detailsStyle = {
  paddingLeft: "20px",
  width: "100%",
};
const detailsStyle2 = {
  background: `url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')`,
  backgroundSize: "cover",
};
export default BasicDetails;
