import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompany } from "../../Redux/Slice/Company/CompanyListSlice";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { fetchDepartment } from "../../Redux/Slice/Department/DepartmentListSlice";
import { addEmployee } from "../../Redux/Slice/Employee/AddEmployeeSlice";
import { fetchEmployee } from "../../Redux/Slice/Employee/EmployeeListSlice";
import dayjs from "dayjs";
import { updateEmployee } from "../../Redux/Slice/Employee/UpdateEmployeeSlice";
import AdminThemeProviderWrapper from "../global/Admin/AdminThemeProviderWrapper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function EmployeeLists() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [dobOfJoining, setDobOfJoining] = React.useState(null);
  const employee = useSelector((state) => state.employee);
  const loading = useSelector((state) => state.employee.isLoader);
  const error = useSelector((state) => state.employee.isError);
  const department = useSelector((state) => state.department.data);
  const navigate = useNavigate();
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddEmployee = () => {
    alert();

    const employeeData = {
      name: getValueById("name"),
      last_name: getValueById("last_name"),
      staff_id: getValueById("staff_id"),
      email: getValueById("email"),
      phone: getValueById("phone"),
      dob: dob,
      gender:
        document.querySelector('input[name="gender"]:checked')?.value || "",
      company: selectedCompany,
      department: selectedDepartment,
      designation: getValueById("designation"),
      office_shift: getValueById("office_shift"),
      username: getValueById("username"),
      role: getValueById("role"),
      password: getValueById("password"),
      dob_of_joining: dobOfJoining,
      attendance_type: getValueById("attendance_type"),
      // image: selectedImage,
    };
    try {
      dispatch(addEmployee(employeeData))
        .then(() => {
          handleCloseModal();
          dispatch(fetchEmployee);
        })
        .catch((error) => {
          // Handle errors, if any
          console.error("Error :", error);
        });
    } catch (error) {
      console.error("Error adding company:", error);
      // toast.error("Error adding company. Please try again.");
    }
    dispatch(addEmployee(employeeData))
      .then(() => {
        handleCloseModal();
        dispatch(fetchEmployee);
      })
      .catch((error) => {
        // Handle errors, if any
        console.error("Error :", error);
      });
  };

  const getValueById = (id) => {
    const element = document.getElementById(id);
    return element ? element.value : "";
  };

  const [companyData, setCompanyData] = React.useState([]);
  const [selectedCompany, setSelectedCompany] =
    React.useState("Select Company");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCompany());
        const dataFromResponse = response.payload.data || [];
        setCompanyData(dataFromResponse);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleChange = (property, event) => {
    if (property === "company") {
      setSelectedCompany(event.target.value);
    } else {
      // Handle other properties if needed
    }
  };

  const [departmentData, setDepartmentData] = React.useState([]);
  const [selectedDepartment, setSelectedDepartment] =
    React.useState("Select Department");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchDepartment());
        const dataFromResponse = response.payload.data || [];
        setDepartmentData(dataFromResponse);
        console.log(departmentData);
      } catch (error) {
        console.error("Error fetching Department data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleDepartmentChange = (property, event) => {
    if (property === "Department") {
      setSelectedDepartment(event.target.value);
    } else {
      // Handle other properties if needed
    }
  };
  // employee lists

  React.useEffect(() => {
    dispatch(fetchEmployee());
  }, [dispatch]);
  console.log("Fetched Employee Dat:", employee);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [editEmployeeData, setEditEmployeeData] = React.useState({
    id: "",
    name: "",
    last_name: "",
    staff_id: "",
    email: "",
    phone: "",
    dob: null, // Set to the appropriate initial value for DatePicker
    gender: "",
    company: "",
    department: "",
    designation: "",
    office_shift: "",
    username: "",
    role: "",
    password: "",
    dob_of_joining: null, // Set to the appropriate initial value for DatePicker
    attendance_type: "",
    image: null, // Set to the appropriate initial value
  });
  const handleEditModalChange = (field, value) => {
    setEditEmployeeData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleOpenEditModal = (employeeData) => {
    if (employeeData) {
      setSelectedEmployee(employeeData);
      // Set the edit modal form data based on the selected user
      setEditEmployeeData({
        id: employeeData.id,
        name: employeeData.name,
        last_name: employeeData.last_name,
        staff_id: employeeData.staff_id,
        email: employeeData.email,
        phone: employeeData.phone,
        dob: dayjs(employeeData.dob), // Convert to the appropriate format for DatePicker
        gender: employeeData.gender,
        company: employeeData.company,
        department: employeeData.department,
        designation: employeeData.designation,
        office_shift: employeeData.office_shift,
        username: employeeData.username,
        role: employeeData.role,
        password: employeeData.password,
        dob_of_joining: dayjs(employeeData.dob_of_joining), // Convert to the appropriate format for DatePicker
        attendance_type: employeeData.attendance_type,
        image: employeeData.image,
      });
    } else {
      setSelectedEmployee(null);
      setEditEmployeeData({
        id: "",
        name: "",
        last_name: "",
        staff_id: "",
        email: "",
        phone: "",
        dob: null,
        gender: "",
        company: "",
        department: "",
        designation: "",
        office_shift: "",
        username: "",
        role: "",
        password: "",
        dob_of_joining: null,
        attendance_type: "",
        image: null,
      });
    }
    setOpenEditModal(true);
  };
  const handleEditImageChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected File:", file);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        console.log("Image Data URL:", reader.result);
        handleEditModalChange("image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditEmployee = () => {
    // Extract fields from editUserData
    const {
      id,
      name,
      last_name,
      staff_id,
      email,
      phone,
      dob,
      gender,
      company,
      department,
      designation,
      office_shift,
      username,
      role,
      password,
      dob_of_joining,
      attendance_type,
      image,
    } = editEmployeeData;

    // Create the credentials object
    const credentials = {
      id,
      name,
      last_name,
      staff_id,
      email,
      phone,
      dob,
      gender,
      company,
      department,
      designation,
      office_shift,
      username,
      role,
      password, // You may want to handle the case where password is undefined
      dob_of_joining,
      attendance_type,
      image,
    };

    // Dispatch the updateUser action with the credentials
    dispatch(updateEmployee(credentials));

    // Close the edit modal
    handleCloseEditModal();
  };
  const handleOpenEmpDetails = (id) => {
    navigate(`/basic-details/${id}`);
  };
  return (
    <AdminThemeProviderWrapper>
      <div style={{ padding: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: "20px", backgroundColor: "#4d5976" }}
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Employee/إضافة موظف
        </Button>

        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Add Employee/إضافة موظف</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <h5>First Name</h5>
                <TextField
                  id="name"
                  label="First Name"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Last Name</h5>
                <TextField
                  id="last_name"
                  label="Last Name"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Staff Id</h5>
                <TextField
                  id="staff_id"
                  label="Staff Id"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Company</h5>
                <Select
                  label="Company"
                  fullWidth
                  margin="normal"
                  value={selectedCompany}
                  onChange={(event) => handleChange("company", event)}
                >
                  <MenuItem value="Select Company" disabled>
                    Select Company
                  </MenuItem>
                  {companyData.map((company) => (
                    <MenuItem key={company.id} value={company.company}>
                      {company.company}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <h5>Email/البريد الإلكتروني</h5>
                <TextField
                  id="email"
                  label="Email/البريد الإلكتروني"
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={6}>
                <h5>Phone/الهاتف</h5>
                <TextField
                  id="phone"
                  label="Phone/الهاتف"
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={6}>
                <h5 style={{ marginBottom: "35px" }}>
                  Date of Birth/تاريخ الميلاد
                </h5>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker value={dob} onChange={(date) => setDob(date)} />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <h5>Date Of Joining/تاريخ الانضمام</h5>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dobOfJoining}
                    onChange={(date) => setDobOfJoining(date)}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <h5 style={{ marginBottom: "35px" }}>Gender/جنس</h5>
                <RadioGroup row aria-label="gender" name="gender">
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={6}>
                <h5>Country</h5>
                <TextField
                  id="country"
                  label="Country"
                  fullWidth
                  margin="normal"
                />
              </Grid>

              {/* <Grid item xs={6}>
              <h5>Department</h5>
              <Select
                label="Department"
                fullWidth
                margin="normal"
                value={selectedDepartment}
                onChange={(event) =>
                  handleDepartmentChange("department", event)
                }
              >
                <MenuItem value="Select Department" disabled>
                  Select Department
                </MenuItem>
                {departmentData.map((department) => (
                  <MenuItem key={department.id} value={department.department}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid> */}
              <Grid item xs={6}>
                <h5>Department</h5>
                <TextField
                  id="Department"
                  label="Department"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Designation</h5>
                <TextField
                  id="designation"
                  label="Designation"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Office Shift</h5>
                <TextField
                  id="office_shift"
                  label="Office Shift"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>User Name</h5>
                <TextField
                  id="username"
                  label="User Name"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Role</h5>
                <TextField id="role" label="Role" fullWidth margin="normal" />
              </Grid>

              <Grid item xs={6}>
                <h5>Attendance Type</h5>
                <TextField
                  id="attendance_type"
                  label="Attendance Type"
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={6}>
                <h5>Image</h5>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="image-input"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="image-input">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ marginTop: "16px" }}
                  >
                    Upload Image
                  </Button>
                </label>
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    style={{ maxWidth: "100%", marginTop: "16px" }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleAddEmployee}
              color="primary"
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : employee &&
          Array.isArray(employee.data) &&
          employee.data.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Employee</StyledTableCell>
                  <StyledTableCell>Company</StyledTableCell>
                  <StyledTableCell>Contact</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employee.data.map((employeeData) => (
                  <StyledTableRow key={employeeData.id}>
                    {/* <StyledTableCell>{employeeData.id}</StyledTableCell> */}
                    <StyledTableCell>
                      <h3 style={{ color: "#d3fdff" }}> {employeeData.name}</h3>
                      User Name: {employeeData.staff_id} <br />
                      Gender: {employeeData.gender}
                      <br />
                      Office Shift: {employeeData.office_shift}
                    </StyledTableCell>
                    <StyledTableCell>
                      <h3 style={{ color: "#d3fdff" }}>
                        {employeeData.company}
                      </h3>
                      Department: {employeeData.department}
                      <br />
                      Desgination: {employeeData.designation}
                    </StyledTableCell>
                    <StyledTableCell>
                      <EmailIcon
                        style={{
                          marginRight: 4,
                          fontSize: 13,
                          marginBottom: "-2px",
                        }}
                      />
                      {employeeData.email}
                      <br />
                      <PhoneIcon
                        style={{
                          marginRight: 4,
                          fontSize: 13,
                          marginBottom: "-2px",
                        }}
                      />
                      {employeeData.phone}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenEmpDetails(employeeData.id)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleOpenEditModal(employeeData)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        // onClick={() => handleDeleteUser(userData.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div style={{ padding: "20px" }}>No Employee available</div>
        )}
        <Dialog open={openEditModal} onClose={handleCloseEditModal}>
          <DialogTitle>Add Employee</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <input
                type="hidden"
                id="eid"
                value={selectedEmployee ? selectedEmployee.id : ""}
              />
              <Grid item xs={6}>
                <h5>First Name</h5>
                <TextField
                  id="ename"
                  label="First Name"
                  defaultValue={selectedEmployee ? selectedEmployee.name : ""}
                  onChange={(e) =>
                    handleEditModalChange("name", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Last Name</h5>
                <TextField
                  id="elast_name"
                  label="Last Name"
                  defaultValue={
                    selectedEmployee ? selectedEmployee.last_name : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("last_name", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Staff Id</h5>
                <TextField
                  id="estaff_id"
                  label="Staff Id"
                  defaultValue={
                    selectedEmployee ? selectedEmployee.staff_id : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("staff_id", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Email</h5>
                <TextField
                  id="eemail"
                  label="Email"
                  fullWidth
                  margin="normal"
                  defaultValue={selectedEmployee ? selectedEmployee.email : ""}
                  onChange={(e) =>
                    handleEditModalChange("email", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Phone</h5>
                <TextField
                  id="ephone"
                  label="Phone"
                  fullWidth
                  margin="normal"
                  defaultValue={selectedEmployee ? selectedEmployee.phone : ""}
                  onChange={(e) =>
                    handleEditModalChange("phone", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Date Of Birth</h5>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    id="edob"
                    value={
                      selectedEmployee ? dayjs(selectedEmployee.dob) : null
                    }
                    onChange={(date) => handleEditModalChange("dob", date)}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <h5>Gender</h5>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender"
                  defaultValue={selectedEmployee ? selectedEmployee.gender : ""}
                  onChange={(e) =>
                    handleEditModalChange("gender", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={6}>
                <h5>Company</h5>
                <TextField
                  id="ecompany"
                  label="Company"
                  fullWidth
                  margin="normal"
                  defaultValue={
                    selectedEmployee ? selectedEmployee.company : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("company", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Department</h5>
                <TextField
                  id="edepartment"
                  label="Department"
                  fullWidth
                  margin="normal"
                  defaultValue={
                    selectedEmployee ? selectedEmployee.department : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("department", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Designation</h5>
                <TextField
                  id="edesignation"
                  label="Designation"
                  fullWidth
                  margin="normal"
                  defaultValue={
                    selectedEmployee ? selectedEmployee.designation : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("designation", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Office Shift</h5>
                <TextField
                  id="eoffice_shift"
                  label="Office Shift"
                  fullWidth
                  margin="normal"
                  defaultValue={
                    selectedEmployee ? selectedEmployee.office_shift : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("office_shift", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>User Name</h5>
                <TextField
                  id="eusername"
                  label="User Name"
                  fullWidth
                  margin="normal"
                  defaultValue={
                    selectedEmployee ? selectedEmployee.username : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("username", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Role</h5>
                <TextField
                  id="erole"
                  label="Role"
                  fullWidth
                  margin="normal"
                  defaultValue={selectedEmployee ? selectedEmployee.role : ""}
                  onChange={(e) =>
                    handleEditModalChange("role", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Password</h5>
                <TextField
                  id="epassword"
                  label="Password"
                  fullWidth
                  margin="normal"
                  defaultValue={
                    selectedEmployee ? selectedEmployee.password : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("password", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Date Of Joining</h5>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    id="edob_of_joining"
                    value={
                      selectedEmployee
                        ? dayjs(selectedEmployee.dob_of_joining)
                        : null
                    }
                    onChange={(date) =>
                      handleEditModalChange("dob_of_joining", date)
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <h5>Attendance Type</h5>
                <TextField
                  id="eattendance_type"
                  label="Attendance Type"
                  fullWidth
                  margin="normal"
                  defaultValue={
                    selectedEmployee ? selectedEmployee.attendance_type : ""
                  }
                  onChange={(e) =>
                    handleEditModalChange("attendance_type", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h5>Image</h5>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="eimage-input"
                  type="file"
                  onChange={handleEditImageChange}
                />
                <label htmlFor="eimage-input">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ marginTop: "16px" }}
                  >
                    Upload Image
                  </Button>
                </label>
                {editEmployeeData.image && (
                  <img
                    src={editEmployeeData.image}
                    alt="Selected"
                    style={{ maxWidth: "100%", marginTop: "16px" }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditModal} color="secondary">
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleEditEmployee}
              variant="contained"
            >
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </AdminThemeProviderWrapper>
  );
}
