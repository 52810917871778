import React from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "../../global/Admin/Sidebar";
import Topbar from "../../global/Admin/Topbar";
import { ColorModeContext, useMode } from "../../../theme";

const AdminThemeProviderWrapper = ({ children }) => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            {children}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default AdminThemeProviderWrapper;
