import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import axios from "axios";
const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
  };
export const salarySearchByName = createAsyncThunk(
    "hr/hrsalarysearch",
    async (credentials) => {
      const token = getTokenFromLocalStorage();
      const res = await fetch(`${apiUrl}/api/hr/salary/serach/` + credentials, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
  
      });
  
      const final = await res.json();
  
      // Log the response data in the console
      console.log("Response data:", final);
  
      return final;
    }
  );

const SearchNameSlice = createSlice({
    name: "addEmployee",
    initialState: {
        isAdding: false,
        isError: false,
        searchdata: null,
        searchstatus: 'pending',
    },
    reducers: {
        resetState: (state) => {
            state.isAdding = false;
            state.isError = false;
            state.searchdata = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(salarySearchByName.pending, (state) => {
            state.isAdding = true;
            state.isError = false;
        });
        builder.addCase(salarySearchByName.fulfilled, (state, action) => {
          state.searchstatus = 'success';
            state.isAdding = false;
            state.searchdata = action.payload.data;
            // console.log("search data",state.searchdata);
        });
        builder.addCase(salarySearchByName.rejected, (state) => {
            state.isAdding = false;
            state.isError = true;
        });
    },
});

export default SearchNameSlice.reducer;
