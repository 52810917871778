import React, { useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Select,
    MenuItem,
    Checkbox,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
} from '@mui/material';
import HrThemeProvideWrapper from '../global/Hr/HrThemeProvideWrapper';
import { useState } from 'react';
import { fetchhrsalary } from '../../Redux/Slice/HrSection/HrSalarySection';
import { useDispatch, useSelector } from "react-redux";

const HrSalaryRecipt = () => {
    const dispatch = useDispatch();
    const { hrsalaryData, hrsalaryStatus, } = useSelector((state) => state.hrsalary);
    const [daysOfWeekForMonth, setDaysOfWeekForMonth] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(1);
    const [months, setMonths] = useState([
        { value: 1, name: 'January' },
        { value: 2, name: 'February' },
        { value: 3, name: 'March' },
        { value: 4, name: 'April' },
        { value: 5, name: 'May' },
        { value: 6, name: 'June' },
        { value: 7, name: 'July' },
        { value: 8, name: 'August' },
        { value: 9, name: 'September' },
        { value: 10, name: 'October' },
        { value: 11, name: 'November' },
        { value: 12, name: 'December' },
    ]);

    const [startYear, setStartYear] = useState(2020);
    const [endYear, setendYear] = useState(2030);

    const yearArray = [];
    for (let year = startYear; year <= endYear; year++) {
        yearArray.push(year);
    }

    // return yearArray;
    const [days, setDays] = useState([]);

    const handleMonthChange = (event) => {
        const selectedValue = event.target.value;
        getDaysOfWeek(2024, selectedValue);
        setSelectedMonth(selectedValue);
    };

    const isSunday = (day) => {
        // Replace this logic with your actual logic to determine if the day is a Sunday
        // For example, assuming that day 1 is Sunday
        return day % 7 === 1;
    };

    function getDaysOfWeek(year, month) {
        const daysOfWeek = [];
        const daysNumber = [];
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);

        let i = 1;
        for (let day = firstDayOfMonth; day <= lastDayOfMonth; day.setDate(day.getDate() + 1)) {
            daysOfWeek.push(day.getDay()); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
            daysNumber.push(i);
            i++;
        }
        setDays(daysNumber);
        setDaysOfWeekForMonth(daysOfWeek);
    }
    useEffect(() => {
        const merge = {
            month: selectedMonth,
            year: startYear,
        }
        dispatch(fetchhrsalary(merge));
    }, [startYear, selectedMonth]);
    // console.log(hrattandanceData);
    useEffect(() => {

        getDaysOfWeek(startYear, selectedMonth);

    }, []);
    return (
        <HrThemeProvideWrapper>
            <Card>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} >
                        <CardHeader title="Salary" />
                    </Grid>
                    {/* <Grid item xs={12} md={4} >
                        <CardContent >
                            <Typography variant="h6" gutterBottom>
                                Select Month
                            </Typography>
                            <Select value={selectedMonth} onChange={handleMonthChange}>
                                {months.map((month) => (
                                    <MenuItem key={month.value} value={month.value}>
                                        {month.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </CardContent>
                    </Grid> */}
                    <Grid item xs={12} md={6} >
                        <CardContent >
                            <Typography variant="h6" gutterBottom>
                                Select Year
                            </Typography>
                            <Select value={startYear} onChange={(e) => setStartYear(e.target.value)} >
                                {yearArray.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </CardContent>
                    </Grid>
                </Grid>
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Emp Code</TableCell>
                                    <TableCell align="left">Salary</TableCell>
                                    {months.map((month) => (
                                        <TableCell key={month.value} align="center">{month.name}</TableCell>
                                    ))}
                                    <TableCell align="left">Total Present</TableCell>
                                    <TableCell align="left">Total Absent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {hrsalaryStatus === 'success'
                                    ? Object.keys(hrsalaryData).map((id) => (
                                        <TableRow key={id}>
                                            <TableCell align="left">{id}</TableCell>
                                            <TableCell align="left">{hrsalaryData[id].name}</TableCell>
                                            <TableCell align="left">{hrsalaryData[id].emp_code}</TableCell> 
                                            <TableCell align="left">{hrsalaryData[id].salarys}</TableCell> 
                                            
                                            {Object.values(hrsalaryData[id].salary).map((salary, index) => (
                                                <TableCell key={index} align="center">
                                                    {salary}
                                                </TableCell>
                                            ))}
                                            <TableCell align="left">{hrsalaryData[id].present}</TableCell>
                                            <TableCell align="left">{hrsalaryData[id].absent}</TableCell>
                                        </TableRow>
                                    ))
                                    : (
                                        <TableRow>
                                            <TableCell colSpan={14} align="center">
                                                No data
                                            </TableCell>
                                        </TableRow>
                                    )
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>

                </CardContent>
            </Card>
        </HrThemeProvideWrapper>
    );
};

export default HrSalaryRecipt;
