import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const addDepartment = createAsyncThunk(
  "auth/addDepartment",
  async (credentials) => {
    const token = getTokenFromLocalStorage();
    try {
      const res = await fetch(`${apiUrl}/api/admin/deparment/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });
      const data = await res.json();
      if (data.status) {
        successmsg(data.message);
      }
      else {
        handleApiError(data.errors, "failed")
      }
      return data;
    }
    catch (error) {
      handleApiError(error, "failed")
    }
  }
);

const departmentAddSlice = createSlice({
  name: "AddDepartment",
  initialState: {
    AddDepartment: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.addCase(addDepartment.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(addDepartment.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.AddDepartment = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(addDepartment.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default departmentAddSlice.reducer;
