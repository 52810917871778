import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ViewEmployeeDetailsNav from "./ViewEmployeeDetailsNav";
import HrThemeProvideWrapper from "../../scenes/global/Hr/HrThemeProvideWrapper";
import { useDispatch, useSelector } from "react-redux";
import { employeeDetails } from "../../Redux/Slice/Employee/EmployeeListSlice";
import { employeeProfileUpdate } from "../../Redux/Slice/Employee/EmployeeListSlice";
import { useParams } from "react-router-dom";

const Profile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.employee);
  const [formData, setFormData] = useState({
    id: id,
    file: null,
  });
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    dispatch(employeeDetails(id));
  }, [dispatch, id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      file: file,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("id", formData.id);
    formDataToSend.append("image", formData.file);
    dispatch(employeeProfileUpdate(formData)).then(async () => {
      await dispatch(employeeDetails(id));
    });
  };

  const imageZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const handleImageDoubleClick = () => {
    setIsZoomed(false); // Reset the zoom on double-click
  };

  return (
    <HrThemeProvideWrapper>
      <div style={containerStyle}>
        <div style={navStyle}>
          <ViewEmployeeDetailsNav />
        </div>
        <div style={detailsStyle}>
          <Card style={detailsStyle2}>
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <CardHeader title="Profile" />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <TextField
                          type="file"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          onChange={handleFileChange}
                          sx={{
                            backgroundColor: "rgba(212,216,252, 0.7)",
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <img
                          id="employeeImage"
                          src={data?.img}
                          alt="Employee"
                          style={{
                            maxWidth: "100%",
                            maxHeight: isZoomed ? "200%" : "200px",
                          }}
                          onClick={imageZoom}
                          onDoubleClick={handleImageDoubleClick}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={handleSubmit}
                          sx={{
                            width: 200,
                            background:
                              "linear-gradient(to right, #1b2365, #181f5e)",
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Typography>
              </CardContent>
            </div>
          </Card>
        </div>
      </div>
    </HrThemeProvideWrapper>
  );
};

const containerStyle = {
  display: "flex",
  //   flexDirection: "row",
};

const navStyle = {
  flex: "0 0 17%",
};

const detailsStyle = {
  paddingLeft: "20px",
  width: "100%",
};
const detailsStyle2 = {
  background: `url('https://img.freepik.com/free-vector/colorful-blocks-blank-white-background-vector_53876-67026.jpg?w=740&t=st=1706777529~exp=1706778129~hmac=750c5554ffaa4a42d8fdffaa9cc833b1e3268a4aa4e3311c39f22eb84580f31f')`,
  backgroundSize: "cover",
};
export default Profile;
