import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const DeleteUserList = createAsyncThunk(
  "user/deleteUser",
  async (userId) => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/api/user/admin/delete/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(data.message || "User deletion failed");
    }
    return data;
  }
);

const UserDeleteSlice = createSlice({
  name: "userDelete",
  initialState: {
    user: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.addCase(DeleteUserList.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(DeleteUserList.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.user = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(DeleteUserList.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default UserDeleteSlice.reducer;
