import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { addEmpAttandance } from "../../Redux/Slice/EmployeeSection/AddAttandance";
import { updateAttandance } from "../../Redux/Slice/EmployeeSection/AddAttandance";
import { clockOutAttandance } from "../../Redux/Slice/EmployeeSection/AddAttandance";
import { useDispatch, useSelector } from "react-redux";
import EmpThemeProviderWrapper from "../global/Employee/EmployeeThemeProviderWrapper";
import { ToastContainer, toast } from "react-toastify";
import animationData from '../../animations/loader_animation0.json';
import { fetchProfile } from "../../Redux/Slice/ProfileSlice";
import Lottie from 'lottie-react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
const Attandance = () => {
  const dispatch = useDispatch();
  const { attandances, attandanceStatus } = useSelector((state) => state.addAttandance);
  const { profileData, profilestatus } = useSelector((state) => state.profile);
  const [openModal, setOpenModal] = React.useState(false);
  const [formData, setFormData] = useState({
    reason: '',
    type: ''
  });
  const [formErrors, setFormErrors] = useState({
    reason: '',
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // dispatch(updateEmployee(formData))
  };
  useEffect(() => {
    console.log(new Date().toLocaleTimeString());
    dispatch(updateAttandance());
    dispatch(fetchProfile);
  }, []);
  const handleClockIn = () => {
    if (profilestatus == 'success') {
      if (profileData.office_shift > profileData.time) {
        setFormData({ type: 'clockIn' });
        setOpenModal(true);
      }
      else {
        dispatch(addEmpAttandance(formData));
      }
    }


  };
  const handleClockOut = () => {
    if (profilestatus == 'success') {
      if (profileData.shift_out < profileData.time) {
        dispatch(clockOutAttandance(formData));
      }
      else {
        setFormData({ type: 'clockout' });
        setOpenModal(true);
      }

    }
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleForm = () => {
    if (formData.reason.trim() === '') {
      setFormData({
        ...formData,
        reason: 'Field required',
      });
    } else {
      if (formData.type == 'clockIn') {
        dispatch(addEmpAttandance(formData));
        setOpenModal(false);
      }
      else {
        dispatch(clockOutAttandance(formData));
        setOpenModal(false);
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  };
  return (
    <EmpThemeProviderWrapper>
      <ToastContainer position="bottom-right" autoClose={3000} />
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add  Reason</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                Reason
              </Typography>

              <TextField
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                error={!!formErrors.reason}
                helperText={formErrors.reason}
                style={{ width: '200px' }}
              />

            </Grid>


          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleForm}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/*  model */}
      <Card>
        <CardHeader title="Attandance" />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            <form>
              <Grid container spacing={2}>
                {
                  profilestatus === 'success' ? (
                    profileData.office_shift < profileData.time ? (
                      attandanceStatus === 'success' && attandances ? (
                        attandances.clock_in === '' ? (
                          <Grid item xs={2}>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={handleClockIn}
                            >
                              Clock IN
                            </Button>
                          </Grid>
                        ) : (
                          attandances.clock_out == null ? (
                            <Grid item xs={2}>
                              " Your punch-OUT time is not available({profileData.shift_out}) "
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleClockOut}
                              >
                                Clock Out
                              </Button>
                            </Grid>
                          ) : (
                            "Already punch out"
                          )
                        )
                      ) : (
                        <Grid item xs={2}>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleClockIn}
                          >
                            Clock IN(Morning)
                          </Button>
                        </Grid>
                      )
                    ) : (
                      profileData.clock_in == "" ? (
                        <Grid container spacing={2}>
                          < Grid item xs={12}>
                            "Your punch-in time is not available({profileData.office_shift})"
                          </Grid>
                          < Grid item xs={2}>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={handleClockIn}
                            >
                              Clock IN(Morning)
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        attandanceStatus == 'success' && attandances ? (
                          attandances.clock_out == null ? (
                            <Grid item xs={2}>
                              "Your punch-OUT time is not available({profileData.shift_out})"
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleClockOut}
                              >
                                Clock Out
                              </Button>
                            </Grid>
                          ) : (
                            "You punch out"
                          )
                        ) : (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              "Your punch-in time is not available({profileData.office_shift})"

                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleClockIn}
                              >
                                Clock IN(Morning)
                              </Button>
                            </Grid>
                          </Grid>
                        )
                      )
                    )
                  ) : (
                    <>
                      <Lottie
                        animationData={animationData}
                        loop={true}
                        autoplay={true}
                        style={{ height: '100px', width: '100px', marginLeft: '500px' }}
                      />
                    </>
                  )
                }


              </Grid>
            </form>
          </Typography>
        </CardContent>
      </Card >
    </EmpThemeProviderWrapper >
  );
};
export default Attandance;
