import React, { useState, useEffect } from "react";
import { loginUser } from "../../Redux/Slice/LoginSlice";
import { useNavigate } from "react-router-dom";
import { fetchProfile } from "../../Redux/Slice/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profileData, profilestatus } = useSelector((state) => state.profile);
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    "url('./assets/Images/Slider/slider4.jpg')",
    "url('./assets/Images/Slider/slider1.jpg')",
    "url('./assets/Images/Slider/slider3.jpg')",
    "url('./assets/Images/Slider/slider2.jpg')",
    "url('./assets/Images/Slider/slider5.jpg')",
  ];
  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(sliderInterval);
  }, []);
  const handleLogin = async () => {
    try {
      const action = await dispatch(loginUser({ email, password }));

      dispatch(fetchProfile());
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("Incorrect User Id Or Password");
    }
  };

  useEffect(() => {
    const getStoredToken = () => {
      const storedToken = localStorage.getItem("token");
    };

    getStoredToken();
  }, []);

  useEffect(() => {
    if (profilestatus == "success") {
      if (profileData.role_id == 1) {
        navigate("/admin");
      } else if (profileData.role_id == 2) {
        navigate("/hr/employee-details");
      } else if (profileData.role_id == 3) {
        navigate("/account/salary/request");
      } else if (profileData.role_id == 5) {
        navigate("/employee/attandance");
      } else {
        navigate("/");
      }
    } else if (profilestatus == "rejected") {
      navigate("/");
    }
  }, [profilestatus]);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    width: "100%",
    backgroundImage: images[currentImage],
    backgroundSize: "cover",
    margin: "-10px",
    transition: "background-image 1s ease-in-out",
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    width: "90%", // Adjusted width for better responsiveness
    maxWidth: "400px", // Added maximum width
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: "20px",
    borderRadius: "8px",
    alignItems: "center",
    color: "white",
  };

  const inputStyle = {
    margin: "10px",
    padding: "12px",
    fontSize: "16px",
    width: "70%",
  };

  const buttonStyle = {
    margin: "10px",
    padding: "12px",
    fontSize: "18px",
    background: "linear-gradient(to right, #1b2365, #181f5e)",
    color: "white",
    cursor: "pointer",
    width: "77%", // Set width to 100%
  };

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflowX = "hidden";

    // Re-enable scrolling on unmount
    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  return (
    <div style={containerStyle}>
      <form style={formStyle}>
        <img
          src="https://alfalah.co/wp-content/uploads/2022/05/new-logo-transpernt.png" // Set the actual path to your image
          alt="Image"
          style={{
            width: "80px", // Set the width as per your requirement
          }}
        />
        <h2
          style={{
            color: "#5472d2",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: 35,
          }}
        >
          Hi, Welcome Back
        </h2>
        <h3
          style={{
            color: "#8e8e8e",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 400,
          }}
        >
          Enter your credentials to continue
        </h3>
        <h1
          style={{
            color: "#5472d2",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            marginBottom: "25px",
            fontSize: 30,
          }}
        >
          Login
        </h1>

        <input
          type="text"
          placeholder="User ID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        <button type="button" onClick={handleLogin} style={buttonStyle}>
          Login
        </button>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </form>
    </div>
  );
};

export default Login;
