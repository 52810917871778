import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { successmsg } from "../../../Helpers/successmsg";
export const DeleteLocationList = createAsyncThunk(
  "company/delete",
  async (credentials, { getState }) => {
    const authToken = getState().auth.token;
    try {
      const res = await fetch(`${apiUrl}/api/admin/location/delete/` + credentials, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await res.json();
      if (data.status) {
        successmsg("location delete successfully");
      }
      else {
        handleApiError(data.errors, "failed");
      }

      return data;
    } catch (error) {
      handleApiError(error, "failed");
    }
  }
);
const DeleteLocationSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null, // Add a token field to the state
  },
  extraReducers: (builder) => {
    builder.addCase(DeleteLocationList.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(DeleteLocationList.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.user = action.payload.data;
      state.token = action.payload.token;
    });
    builder.addCase(DeleteLocationList.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});
export default DeleteLocationSlice.reducer;
